.contact-page {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
 // margin: -10% 0 0;
  @media @tablet, @mobile {
    margin: 0;
  }
  @media @tablet-portrait, @mobile {
    flex-direction: column;
    align-items: center;
  }
  .contact {
    .padding(98,0,0,0);
    @media @tablet-portrait, @mobile {
      padding: 4rem 0 3rem;
    }
    h2 {
      .font-size(30);
      .line-height(36);
      .margin(0,0,25,0);
    }
    dl {
      .margin(0,0,10,0);
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      .line-height(25);
      dt {
        color: @secondary;
        .width(33);
        .font-size(14);
      }
      dd {
        margin: 0;
        color:@text;
        .font-size(17);
        a {
          color:@text;
        }
      }
    }
  }
  .form-holder {
    background: @white;
    box-shadow: 0 0.563rem 1.313rem rgba(0,0,0,0.11);
    .width(800);
    flex-shrink: 0;
    position: relative;
    margin-top:-4.125rem;
    .padding(60,150,70,150);
    @media @tablet{
      margin-top: 0;
    }
    @media @mobile {
      width:90%;
      margin-top:0;
    }
    @media @mobile-xs {
      width:100%;
      margin-top:0;
      padding: 2rem 2rem;
    }
    .ttl {
      .text-center;
      .font-size(17);
      .line-height(30);
      .margin(0,0,35,0);
      h3 {
        color: @primary;
        .font-size(30);
        .line-height(36);
        .margin(0,0,25,0);
        font-weight: 700;
        .margin(0,0,13,0);
      }
    }
    .city{
      padding-left: 1.6rem;
     // .font-size(15);
      .Lato;
    //  .line-height(25);
      margin:0;
      color: @text;
      font-weight: 400;
      position: relative;
    &::before{
      content:"\f3c5";
      .pos-centerY;
      left: 0;
      color: @secondary;
      width: 0.8rem;
      height: auto;
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
    }
    }
    .date{
      padding-left: 1.6rem;
    //  .font-size(15);
      .Lato;
  //    .line-height(25);
      margin:0;
      color: @text;
      font-weight: 400;
      position: relative;
      &::before{
        content:"\f073";
        .pos-centerY;
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        left: 0;
        color: @secondary;
      }
    }
  
  }
}