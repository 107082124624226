/* **************** fontawesome ****************** */
.fa,
.fas,
.far,
.fal,
.fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
/* makes the font 33% larger relative to the icon container */
.fa-lg {
  font-size: 1.33333333em;
  line-height: 0.75em;
  vertical-align: -0.0667em;
}
.fa-xs {
  font-size: 0.75em;
}
.fa-sm {
  font-size: 0.875em;
}
.fa-1x {
  font-size: 1em;
}
.fa-2x {
  font-size: 2em;
}
.fa-3x {
  font-size: 3em;
}
.fa-4x {
  font-size: 4em;
}
.fa-5x {
  font-size: 5em;
}
.fa-6x {
  font-size: 6em;
}
.fa-7x {
  font-size: 7em;
}
.fa-8x {
  font-size: 8em;
}
.fa-9x {
  font-size: 9em;
}
.fa-10x {
  font-size: 10em;
}
.fa-fw {
  text-align: center;
  width: 1.25em;
}
.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0;
}
.fa-ul > li {
  position: relative;
}
.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit;
}
.fa-border {
  border-radius: 0.1em;
  border: solid 0.08em #eee;
  padding: 0.2em 0.25em 0.15em;
}
.fa-pull-left {
  float: left;
}
.fa-pull-right {
  float: right;
}
.fa.fa-pull-left,
.fas.fa-pull-left,
.far.fa-pull-left,
.fal.fa-pull-left,
.fab.fa-pull-left {
  margin-right: 0.3em;
}
.fa.fa-pull-right,
.fas.fa-pull-right,
.far.fa-pull-right,
.fal.fa-pull-right,
.fab.fa-pull-right {
  margin-left: 0.3em;
}
.fa-spin {
  animation: fa-spin 2s infinite linear;
}
.fa-pulse {
  animation: fa-spin 1s infinite steps(8);
}
@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  transform: rotate(90deg);
}
.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  transform: rotate(180deg);
}
.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  transform: rotate(270deg);
}
.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  transform: scale(-1, 1);
}
.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(1, -1);
}
.fa-flip-both,
.fa-flip-horizontal.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(-1, -1);
}
:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical,
:root .fa-flip-both {
  filter: none;
}
.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2em;
}
.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}
.fa-stack-1x {
  line-height: inherit;
}
.fa-stack-2x {
  font-size: 2em;
}
.fa-inverse {
  color: #fff;
}
/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
   readers do not read off random characters that represent icons */
.fa-500px:before {
  content: "\f26e";
}
.fa-accessible-icon:before {
  content: "\f368";
}
.fa-accusoft:before {
  content: "\f369";
}
.fa-acquisitions-incorporated:before {
  content: "\f6af";
}
.fa-ad:before {
  content: "\f641";
}
.fa-address-book:before {
  content: "\f2b9";
}
.fa-address-card:before {
  content: "\f2bb";
}
.fa-adjust:before {
  content: "\f042";
}
.fa-adn:before {
  content: "\f170";
}
.fa-adobe:before {
  content: "\f778";
}
.fa-adversal:before {
  content: "\f36a";
}
.fa-affiliatetheme:before {
  content: "\f36b";
}
.fa-air-freshener:before {
  content: "\f5d0";
}
.fa-algolia:before {
  content: "\f36c";
}
.fa-align-center:before {
  content: "\f037";
}
.fa-align-justify:before {
  content: "\f039";
}
.fa-align-left:before {
  content: "\f036";
}
.fa-align-right:before {
  content: "\f038";
}
.fa-alipay:before {
  content: "\f642";
}
.fa-allergies:before {
  content: "\f461";
}
.fa-amazon:before {
  content: "\f270";
}
.fa-amazon-pay:before {
  content: "\f42c";
}
.fa-ambulance:before {
  content: "\f0f9";
}
.fa-american-sign-language-interpreting:before {
  content: "\f2a3";
}
.fa-amilia:before {
  content: "\f36d";
}
.fa-anchor:before {
  content: "\f13d";
}
.fa-android:before {
  content: "\f17b";
}
.fa-angellist:before {
  content: "\f209";
}
.fa-angle-double-down:before {
  content: "\f103";
}
.fa-angle-double-left:before {
  content: "\f100";
}
.fa-angle-double-right:before {
  content: "\f101";
}
.fa-angle-double-up:before {
  content: "\f102";
}
.fa-angle-down:before {
  content: "\f107";
}
.fa-angle-left:before {
  content: "\f104";
}
.fa-angle-right:before {
  content: "\f105";
}
.fa-angle-up:before {
  content: "\f106";
}
.fa-angry:before {
  content: "\f556";
}
.fa-angrycreative:before {
  content: "\f36e";
}
.fa-angular:before {
  content: "\f420";
}
.fa-ankh:before {
  content: "\f644";
}
.fa-app-store:before {
  content: "\f36f";
}
.fa-app-store-ios:before {
  content: "\f370";
}
.fa-apper:before {
  content: "\f371";
}
.fa-apple:before {
  content: "\f179";
}
.fa-apple-alt:before {
  content: "\f5d1";
}
.fa-apple-pay:before {
  content: "\f415";
}
.fa-archive:before {
  content: "\f187";
}
.fa-archway:before {
  content: "\f557";
}
.fa-arrow-alt-circle-down:before {
  content: "\f358";
}
.fa-arrow-alt-circle-left:before {
  content: "\f359";
}
.fa-arrow-alt-circle-right:before {
  content: "\f35a";
}
.fa-arrow-alt-circle-up:before {
  content: "\f35b";
}
.fa-arrow-circle-down:before {
  content: "\f0ab";
}
.fa-arrow-circle-left:before {
  content: "\f0a8";
}
.fa-arrow-circle-right:before {
  content: "\f0a9";
}
.fa-arrow-circle-up:before {
  content: "\f0aa";
}
.fa-arrow-down:before {
  content: "\f063";
}
.fa-arrow-left:before {
  content: "\f060";
}
.fa-arrow-right:before {
  content: "\f061";
}
.fa-arrow-up:before {
  content: "\f062";
}
.fa-arrows-alt:before {
  content: "\f0b2";
}
.fa-arrows-alt-h:before {
  content: "\f337";
}
.fa-arrows-alt-v:before {
  content: "\f338";
}
.fa-artstation:before {
  content: "\f77a";
}
.fa-assistive-listening-systems:before {
  content: "\f2a2";
}
.fa-asterisk:before {
  content: "\f069";
}
.fa-asymmetrik:before {
  content: "\f372";
}
.fa-at:before {
  content: "\f1fa";
}
.fa-atlas:before {
  content: "\f558";
}
.fa-atlassian:before {
  content: "\f77b";
}
.fa-atom:before {
  content: "\f5d2";
}
.fa-audible:before {
  content: "\f373";
}
.fa-audio-description:before {
  content: "\f29e";
}
.fa-autoprefixer:before {
  content: "\f41c";
}
.fa-avianex:before {
  content: "\f374";
}
.fa-aviato:before {
  content: "\f421";
}
.fa-award:before {
  content: "\f559";
}
.fa-aws:before {
  content: "\f375";
}
.fa-baby:before {
  content: "\f77c";
}
.fa-baby-carriage:before {
  content: "\f77d";
}
.fa-backspace:before {
  content: "\f55a";
}
.fa-backward:before {
  content: "\f04a";
}
.fa-bacon:before {
  content: "\f7e5";
}
.fa-balance-scale:before {
  content: "\f24e";
}
.fa-ban:before {
  content: "\f05e";
}
.fa-band-aid:before {
  content: "\f462";
}
.fa-bandcamp:before {
  content: "\f2d5";
}
.fa-barcode:before {
  content: "\f02a";
}
.fa-bars:before {
  content: "\f0c9";
}
.fa-baseball-ball:before {
  content: "\f433";
}
.fa-basketball-ball:before {
  content: "\f434";
}
.fa-bath:before {
  content: "\f2cd";
}
.fa-battery-empty:before {
  content: "\f244";
}
.fa-battery-full:before {
  content: "\f240";
}
.fa-battery-half:before {
  content: "\f242";
}
.fa-battery-quarter:before {
  content: "\f243";
}
.fa-battery-three-quarters:before {
  content: "\f241";
}
.fa-bed:before {
  content: "\f236";
}
.fa-beer:before {
  content: "\f0fc";
}
.fa-behance:before {
  content: "\f1b4";
}
.fa-behance-square:before {
  content: "\f1b5";
}
.fa-bell:before {
  content: "\f0f3";
}
.fa-bell-slash:before {
  content: "\f1f6";
}
.fa-bezier-curve:before {
  content: "\f55b";
}
.fa-bible:before {
  content: "\f647";
}
.fa-bicycle:before {
  content: "\f206";
}
.fa-bimobject:before {
  content: "\f378";
}
.fa-binoculars:before {
  content: "\f1e5";
}
.fa-biohazard:before {
  content: "\f780";
}
.fa-birthday-cake:before {
  content: "\f1fd";
}
.fa-bitbucket:before {
  content: "\f171";
}
.fa-bitcoin:before {
  content: "\f379";
}
.fa-bity:before {
  content: "\f37a";
}
.fa-black-tie:before {
  content: "\f27e";
}
.fa-blackberry:before {
  content: "\f37b";
}
.fa-blender:before {
  content: "\f517";
}
.fa-blender-phone:before {
  content: "\f6b6";
}
.fa-blind:before {
  content: "\f29d";
}
.fa-blog:before {
  content: "\f781";
}
.fa-blogger:before {
  content: "\f37c";
}
.fa-blogger-b:before {
  content: "\f37d";
}
.fa-bluetooth:before {
  content: "\f293";
}
.fa-bluetooth-b:before {
  content: "\f294";
}
.fa-bold:before {
  content: "\f032";
}
.fa-bolt:before {
  content: "\f0e7";
}
.fa-bomb:before {
  content: "\f1e2";
}
.fa-bone:before {
  content: "\f5d7";
}
.fa-bong:before {
  content: "\f55c";
}
.fa-book:before {
  content: "\f02d";
}
.fa-book-dead:before {
  content: "\f6b7";
}
.fa-book-medical:before {
  content: "\f7e6";
}
.fa-book-open:before {
  content: "\f518";
}
.fa-book-reader:before {
  content: "\f5da";
}
.fa-bookmark:before {
  content: "\f02e";
}
.fa-bowling-ball:before {
  content: "\f436";
}
.fa-box:before {
  content: "\f466";
}
.fa-box-open:before {
  content: "\f49e";
}
.fa-boxes:before {
  content: "\f468";
}
.fa-braille:before {
  content: "\f2a1";
}
.fa-brain:before {
  content: "\f5dc";
}
.fa-bread-slice:before {
  content: "\f7ec";
}
.fa-briefcase:before {
  content: "\f0b1";
}
.fa-briefcase-medical:before {
  content: "\f469";
}
.fa-broadcast-tower:before {
  content: "\f519";
}
.fa-broom:before {
  content: "\f51a";
}
.fa-brush:before {
  content: "\f55d";
}
.fa-btc:before {
  content: "\f15a";
}
.fa-bug:before {
  content: "\f188";
}
.fa-building:before {
  content: "\f1ad";
}
.fa-bullhorn:before {
  content: "\f0a1";
}
.fa-bullseye:before {
  content: "\f140";
}
.fa-burn:before {
  content: "\f46a";
}
.fa-buromobelexperte:before {
  content: "\f37f";
}
.fa-bus:before {
  content: "\f207";
}
.fa-bus-alt:before {
  content: "\f55e";
}
.fa-business-time:before {
  content: "\f64a";
}
.fa-buysellads:before {
  content: "\f20d";
}
.fa-calculator:before {
  content: "\f1ec";
}
.fa-calendar:before {
  content: "\f133";
}
.fa-calendar-alt:before {
  content: "\f073";
}
.fa-calendar-check:before {
  content: "\f274";
}
.fa-calendar-day:before {
  content: "\f783";
}
.fa-calendar-minus:before {
  content: "\f272";
}
.fa-calendar-plus:before {
  content: "\f271";
}
.fa-calendar-times:before {
  content: "\f273";
}
.fa-calendar-week:before {
  content: "\f784";
}
.fa-camera:before {
  content: "\f030";
}
.fa-camera-retro:before {
  content: "\f083";
}
.fa-campground:before {
  content: "\f6bb";
}
.fa-canadian-maple-leaf:before {
  content: "\f785";
}
.fa-candy-cane:before {
  content: "\f786";
}
.fa-cannabis:before {
  content: "\f55f";
}
.fa-capsules:before {
  content: "\f46b";
}
.fa-car:before {
  content: "\f1b9";
}
.fa-car-alt:before {
  content: "\f5de";
}
.fa-car-battery:before {
  content: "\f5df";
}
.fa-car-crash:before {
  content: "\f5e1";
}
.fa-car-side:before {
  content: "\f5e4";
}
.fa-caret-down:before {
  content: "\f0d7";
}
.fa-caret-left:before {
  content: "\f0d9";
}
.fa-caret-right:before {
  content: "\f0da";
}
.fa-caret-square-down:before {
  content: "\f150";
}
.fa-caret-square-left:before {
  content: "\f191";
}
.fa-caret-square-right:before {
  content: "\f152";
}
.fa-caret-square-up:before {
  content: "\f151";
}
.fa-caret-up:before {
  content: "\f0d8";
}
.fa-carrot:before {
  content: "\f787";
}
.fa-cart-arrow-down:before {
  content: "\f218";
}
.fa-cart-plus:before {
  content: "\f217";
}
.fa-cash-register:before {
  content: "\f788";
}
.fa-cat:before {
  content: "\f6be";
}
.fa-cc-amazon-pay:before {
  content: "\f42d";
}
.fa-cc-amex:before {
  content: "\f1f3";
}
.fa-cc-apple-pay:before {
  content: "\f416";
}
.fa-cc-diners-club:before {
  content: "\f24c";
}
.fa-cc-discover:before {
  content: "\f1f2";
}
.fa-cc-jcb:before {
  content: "\f24b";
}
.fa-cc-mastercard:before {
  content: "\f1f1";
}
.fa-cc-paypal:before {
  content: "\f1f4";
}
.fa-cc-stripe:before {
  content: "\f1f5";
}
.fa-cc-visa:before {
  content: "\f1f0";
}
.fa-centercode:before {
  content: "\f380";
}
.fa-centos:before {
  content: "\f789";
}
.fa-certificate:before {
  content: "\f0a3";
}
.fa-chair:before {
  content: "\f6c0";
}
.fa-chalkboard:before {
  content: "\f51b";
}
.fa-chalkboard-teacher:before {
  content: "\f51c";
}
.fa-charging-station:before {
  content: "\f5e7";
}
.fa-chart-area:before {
  content: "\f1fe";
}
.fa-chart-bar:before {
  content: "\f080";
}
.fa-chart-line:before {
  content: "\f201";
}
.fa-chart-pie:before {
  content: "\f200";
}
.fa-check:before {
  content: "\f00c";
}
.fa-check-circle:before {
  content: "\f058";
}
.fa-check-double:before {
  content: "\f560";
}
.fa-check-square:before {
  content: "\f14a";
}
.fa-cheese:before {
  content: "\f7ef";
}
.fa-chess:before {
  content: "\f439";
}
.fa-chess-bishop:before {
  content: "\f43a";
}
.fa-chess-board:before {
  content: "\f43c";
}
.fa-chess-king:before {
  content: "\f43f";
}
.fa-chess-knight:before {
  content: "\f441";
}
.fa-chess-pawn:before {
  content: "\f443";
}
.fa-chess-queen:before {
  content: "\f445";
}
.fa-chess-rook:before {
  content: "\f447";
}
.fa-chevron-circle-down:before {
  content: "\f13a";
}
.fa-chevron-circle-left:before {
  content: "\f137";
}
.fa-chevron-circle-right:before {
  content: "\f138";
}
.fa-chevron-circle-up:before {
  content: "\f139";
}
.fa-chevron-down:before {
  content: "\f078";
}
.fa-chevron-left:before {
  content: "\f053";
}
.fa-chevron-right:before {
  content: "\f054";
}
.fa-chevron-up:before {
  content: "\f077";
}
.fa-child:before {
  content: "\f1ae";
}
.fa-chrome:before {
  content: "\f268";
}
.fa-church:before {
  content: "\f51d";
}
.fa-circle:before {
  content: "\f111";
}
.fa-circle-notch:before {
  content: "\f1ce";
}
.fa-city:before {
  content: "\f64f";
}
.fa-clinic-medical:before {
  content: "\f7f2";
}
.fa-clipboard:before {
  content: "\f328";
}
.fa-clipboard-check:before {
  content: "\f46c";
}
.fa-clipboard-list:before {
  content: "\f46d";
}
.fa-clock:before {
  content: "\f017";
}
.fa-clone:before {
  content: "\f24d";
}
.fa-closed-captioning:before {
  content: "\f20a";
}
.fa-cloud:before {
  content: "\f0c2";
}
.fa-cloud-download-alt:before {
  content: "\f381";
}
.fa-cloud-meatball:before {
  content: "\f73b";
}
.fa-cloud-moon:before {
  content: "\f6c3";
}
.fa-cloud-moon-rain:before {
  content: "\f73c";
}
.fa-cloud-rain:before {
  content: "\f73d";
}
.fa-cloud-showers-heavy:before {
  content: "\f740";
}
.fa-cloud-sun:before {
  content: "\f6c4";
}
.fa-cloud-sun-rain:before {
  content: "\f743";
}
.fa-cloud-upload-alt:before {
  content: "\f382";
}
.fa-cloudscale:before {
  content: "\f383";
}
.fa-cloudsmith:before {
  content: "\f384";
}
.fa-cloudversify:before {
  content: "\f385";
}
.fa-cocktail:before {
  content: "\f561";
}
.fa-code:before {
  content: "\f121";
}
.fa-code-branch:before {
  content: "\f126";
}
.fa-codepen:before {
  content: "\f1cb";
}
.fa-codiepie:before {
  content: "\f284";
}
.fa-coffee:before {
  content: "\f0f4";
}
.fa-cog:before {
  content: "\f013";
}
.fa-cogs:before {
  content: "\f085";
}
.fa-coins:before {
  content: "\f51e";
}
.fa-columns:before {
  content: "\f0db";
}
.fa-comment:before {
  content: "\f075";
}
.fa-comment-alt:before {
  content: "\f27a";
}
.fa-comment-dollar:before {
  content: "\f651";
}
.fa-comment-dots:before {
  content: "\f4ad";
}
.fa-comment-medical:before {
  content: "\f7f5";
}
.fa-comment-slash:before {
  content: "\f4b3";
}
.fa-comments:before {
  content: "\f086";
}
.fa-comments-dollar:before {
  content: "\f653";
}
.fa-compact-disc:before {
  content: "\f51f";
}
.fa-compass:before {
  content: "\f14e";
}
.fa-compress:before {
  content: "\f066";
}
.fa-compress-arrows-alt:before {
  content: "\f78c";
}
.fa-concierge-bell:before {
  content: "\f562";
}
.fa-confluence:before {
  content: "\f78d";
}
.fa-connectdevelop:before {
  content: "\f20e";
}
.fa-contao:before {
  content: "\f26d";
}
.fa-cookie:before {
  content: "\f563";
}
.fa-cookie-bite:before {
  content: "\f564";
}
.fa-copy:before {
  content: "\f0c5";
}
.fa-copyright:before {
  content: "\f1f9";
}
.fa-couch:before {
  content: "\f4b8";
}
.fa-cpanel:before {
  content: "\f388";
}
.fa-creative-commons:before {
  content: "\f25e";
}
.fa-creative-commons-by:before {
  content: "\f4e7";
}
.fa-creative-commons-nc:before {
  content: "\f4e8";
}
.fa-creative-commons-nc-eu:before {
  content: "\f4e9";
}
.fa-creative-commons-nc-jp:before {
  content: "\f4ea";
}
.fa-creative-commons-nd:before {
  content: "\f4eb";
}
.fa-creative-commons-pd:before {
  content: "\f4ec";
}
.fa-creative-commons-pd-alt:before {
  content: "\f4ed";
}
.fa-creative-commons-remix:before {
  content: "\f4ee";
}
.fa-creative-commons-sa:before {
  content: "\f4ef";
}
.fa-creative-commons-sampling:before {
  content: "\f4f0";
}
.fa-creative-commons-sampling-plus:before {
  content: "\f4f1";
}
.fa-creative-commons-share:before {
  content: "\f4f2";
}
.fa-creative-commons-zero:before {
  content: "\f4f3";
}
.fa-credit-card:before {
  content: "\f09d";
}
.fa-critical-role:before {
  content: "\f6c9";
}
.fa-crop:before {
  content: "\f125";
}
.fa-crop-alt:before {
  content: "\f565";
}
.fa-cross:before {
  content: "\f654";
}
.fa-crosshairs:before {
  content: "\f05b";
}
.fa-crow:before {
  content: "\f520";
}
.fa-crown:before {
  content: "\f521";
}
.fa-crutch:before {
  content: "\f7f7";
}
.fa-css3:before {
  content: "\f13c";
}
.fa-css3-alt:before {
  content: "\f38b";
}
.fa-cube:before {
  content: "\f1b2";
}
.fa-cubes:before {
  content: "\f1b3";
}
.fa-cut:before {
  content: "\f0c4";
}
.fa-cuttlefish:before {
  content: "\f38c";
}
.fa-d-and-d:before {
  content: "\f38d";
}
.fa-d-and-d-beyond:before {
  content: "\f6ca";
}
.fa-dashcube:before {
  content: "\f210";
}
.fa-database:before {
  content: "\f1c0";
}
.fa-deaf:before {
  content: "\f2a4";
}
.fa-delicious:before {
  content: "\f1a5";
}
.fa-democrat:before {
  content: "\f747";
}
.fa-deploydog:before {
  content: "\f38e";
}
.fa-deskpro:before {
  content: "\f38f";
}
.fa-desktop:before {
  content: "\f108";
}
.fa-dev:before {
  content: "\f6cc";
}
.fa-deviantart:before {
  content: "\f1bd";
}
.fa-dharmachakra:before {
  content: "\f655";
}
.fa-dhl:before {
  content: "\f790";
}
.fa-diagnoses:before {
  content: "\f470";
}
.fa-diaspora:before {
  content: "\f791";
}
.fa-dice:before {
  content: "\f522";
}
.fa-dice-d20:before {
  content: "\f6cf";
}
.fa-dice-d6:before {
  content: "\f6d1";
}
.fa-dice-five:before {
  content: "\f523";
}
.fa-dice-four:before {
  content: "\f524";
}
.fa-dice-one:before {
  content: "\f525";
}
.fa-dice-six:before {
  content: "\f526";
}
.fa-dice-three:before {
  content: "\f527";
}
.fa-dice-two:before {
  content: "\f528";
}
.fa-digg:before {
  content: "\f1a6";
}
.fa-digital-ocean:before {
  content: "\f391";
}
.fa-digital-tachograph:before {
  content: "\f566";
}
.fa-directions:before {
  content: "\f5eb";
}
.fa-discord:before {
  content: "\f392";
}
.fa-discourse:before {
  content: "\f393";
}
.fa-divide:before {
  content: "\f529";
}
.fa-dizzy:before {
  content: "\f567";
}
.fa-dna:before {
  content: "\f471";
}
.fa-dochub:before {
  content: "\f394";
}
.fa-docker:before {
  content: "\f395";
}
.fa-dog:before {
  content: "\f6d3";
}
.fa-dollar-sign:before {
  content: "\f155";
}
.fa-dolly:before {
  content: "\f472";
}
.fa-dolly-flatbed:before {
  content: "\f474";
}
.fa-donate:before {
  content: "\f4b9";
}
.fa-door-closed:before {
  content: "\f52a";
}
.fa-door-open:before {
  content: "\f52b";
}
.fa-dot-circle:before {
  content: "\f192";
}
.fa-dove:before {
  content: "\f4ba";
}
.fa-download:before {
  content: "\f019";
}
.fa-draft2digital:before {
  content: "\f396";
}
.fa-drafting-compass:before {
  content: "\f568";
}
.fa-dragon:before {
  content: "\f6d5";
}
.fa-draw-polygon:before {
  content: "\f5ee";
}
.fa-dribbble:before {
  content: "\f17d";
}
.fa-dribbble-square:before {
  content: "\f397";
}
.fa-dropbox:before {
  content: "\f16b";
}
.fa-drum:before {
  content: "\f569";
}
.fa-drum-steelpan:before {
  content: "\f56a";
}
.fa-drumstick-bite:before {
  content: "\f6d7";
}
.fa-drupal:before {
  content: "\f1a9";
}
.fa-dumbbell:before {
  content: "\f44b";
}
.fa-dumpster:before {
  content: "\f793";
}
.fa-dumpster-fire:before {
  content: "\f794";
}
.fa-dungeon:before {
  content: "\f6d9";
}
.fa-dyalog:before {
  content: "\f399";
}
.fa-earlybirds:before {
  content: "\f39a";
}
.fa-ebay:before {
  content: "\f4f4";
}
.fa-edge:before {
  content: "\f282";
}
.fa-edit:before {
  content: "\f044";
}
.fa-egg:before {
  content: "\f7fb";
}
.fa-eject:before {
  content: "\f052";
}
.fa-elementor:before {
  content: "\f430";
}
.fa-ellipsis-h:before {
  content: "\f141";
}
.fa-ellipsis-v:before {
  content: "\f142";
}
.fa-ello:before {
  content: "\f5f1";
}
.fa-ember:before {
  content: "\f423";
}
.fa-empire:before {
  content: "\f1d1";
}
.fa-envelope:before {
  content: "\f0e0";
}
.fa-envelope-open:before {
  content: "\f2b6";
}
.fa-envelope-open-text:before {
  content: "\f658";
}
.fa-envelope-square:before {
  content: "\f199";
}
.fa-envira:before {
  content: "\f299";
}
.fa-equals:before {
  content: "\f52c";
}
.fa-eraser:before {
  content: "\f12d";
}
.fa-erlang:before {
  content: "\f39d";
}
.fa-ethereum:before {
  content: "\f42e";
}
.fa-ethernet:before {
  content: "\f796";
}
.fa-etsy:before {
  content: "\f2d7";
}
.fa-euro-sign:before {
  content: "\f153";
}
.fa-exchange-alt:before {
  content: "\f362";
}
.fa-exclamation:before {
  content: "\f12a";
}
.fa-exclamation-circle:before {
  content: "\f06a";
}
.fa-exclamation-triangle:before {
  content: "\f071";
}
.fa-expand:before {
  content: "\f065";
}
.fa-expand-arrows-alt:before {
  content: "\f31e";
}
.fa-expeditedssl:before {
  content: "\f23e";
}
.fa-external-link-alt:before {
  content: "\f35d";
}
.fa-external-link-square-alt:before {
  content: "\f360";
}
.fa-eye:before {
  content: "\f06e";
}
.fa-eye-dropper:before {
  content: "\f1fb";
}
.fa-eye-slash:before {
  content: "\f070";
}
.fa-facebook:before {
  content: "\f09a";
}
.fa-facebook-f:before {
  content: "\f39e";
}
.fa-facebook-messenger:before {
  content: "\f39f";
}
.fa-facebook-square:before {
  content: "\f082";
}
.fa-fantasy-flight-games:before {
  content: "\f6dc";
}
.fa-fast-backward:before {
  content: "\f049";
}
.fa-fast-forward:before {
  content: "\f050";
}
.fa-fax:before {
  content: "\f1ac";
}
.fa-feather:before {
  content: "\f52d";
}
.fa-feather-alt:before {
  content: "\f56b";
}
.fa-fedex:before {
  content: "\f797";
}
.fa-fedora:before {
  content: "\f798";
}
.fa-female:before {
  content: "\f182";
}
.fa-fighter-jet:before {
  content: "\f0fb";
}
.fa-figma:before {
  content: "\f799";
}
.fa-file:before {
  content: "\f15b";
}
.fa-file-alt:before {
  content: "\f15c";
}
.fa-file-archive:before {
  content: "\f1c6";
}
.fa-file-audio:before {
  content: "\f1c7";
}
.fa-file-code:before {
  content: "\f1c9";
}
.fa-file-contract:before {
  content: "\f56c";
}
.fa-file-csv:before {
  content: "\f6dd";
}
.fa-file-download:before {
  content: "\f56d";
}
.fa-file-excel:before {
  content: "\f1c3";
}
.fa-file-export:before {
  content: "\f56e";
}
.fa-file-image:before {
  content: "\f1c5";
}
.fa-file-import:before {
  content: "\f56f";
}
.fa-file-invoice:before {
  content: "\f570";
}
.fa-file-invoice-dollar:before {
  content: "\f571";
}
.fa-file-medical:before {
  content: "\f477";
}
.fa-file-medical-alt:before {
  content: "\f478";
}
.fa-file-pdf:before {
  content: "\f1c1";
}
.fa-file-powerpoint:before {
  content: "\f1c4";
}
.fa-file-prescription:before {
  content: "\f572";
}
.fa-file-signature:before {
  content: "\f573";
}
.fa-file-upload:before {
  content: "\f574";
}
.fa-file-video:before {
  content: "\f1c8";
}
.fa-file-word:before {
  content: "\f1c2";
}
.fa-fill:before {
  content: "\f575";
}
.fa-fill-drip:before {
  content: "\f576";
}
.fa-film:before {
  content: "\f008";
}
.fa-filter:before {
  content: "\f0b0";
}
.fa-fingerprint:before {
  content: "\f577";
}
.fa-fire:before {
  content: "\f06d";
}
.fa-fire-alt:before {
  content: "\f7e4";
}
.fa-fire-extinguisher:before {
  content: "\f134";
}
.fa-firefox:before {
  content: "\f269";
}
.fa-first-aid:before {
  content: "\f479";
}
.fa-first-order:before {
  content: "\f2b0";
}
.fa-first-order-alt:before {
  content: "\f50a";
}
.fa-firstdraft:before {
  content: "\f3a1";
}
.fa-fish:before {
  content: "\f578";
}
.fa-fist-raised:before {
  content: "\f6de";
}
.fa-flag:before {
  content: "\f024";
}
.fa-flag-checkered:before {
  content: "\f11e";
}
.fa-flag-usa:before {
  content: "\f74d";
}
.fa-flask:before {
  content: "\f0c3";
}
.fa-flickr:before {
  content: "\f16e";
}
.fa-flipboard:before {
  content: "\f44d";
}
.fa-flushed:before {
  content: "\f579";
}
.fa-fly:before {
  content: "\f417";
}
.fa-folder:before {
  content: "\f07b";
}
.fa-folder-minus:before {
  content: "\f65d";
}
.fa-folder-open:before {
  content: "\f07c";
}
.fa-folder-plus:before {
  content: "\f65e";
}
.fa-font:before {
  content: "\f031";
}
.fa-font-awesome:before {
  content: "\f2b4";
}
.fa-font-awesome-alt:before {
  content: "\f35c";
}
.fa-font-awesome-flag:before {
  content: "\f425";
}
.fa-font-awesome-logo-full:before {
  content: "\f4e6";
}
.fa-fonticons:before {
  content: "\f280";
}
.fa-fonticons-fi:before {
  content: "\f3a2";
}
.fa-football-ball:before {
  content: "\f44e";
}
.fa-fort-awesome:before {
  content: "\f286";
}
.fa-fort-awesome-alt:before {
  content: "\f3a3";
}
.fa-forumbee:before {
  content: "\f211";
}
.fa-forward:before {
  content: "\f04e";
}
.fa-foursquare:before {
  content: "\f180";
}
.fa-free-code-camp:before {
  content: "\f2c5";
}
.fa-freebsd:before {
  content: "\f3a4";
}
.fa-frog:before {
  content: "\f52e";
}
.fa-frown:before {
  content: "\f119";
}
.fa-frown-open:before {
  content: "\f57a";
}
.fa-fulcrum:before {
  content: "\f50b";
}
.fa-funnel-dollar:before {
  content: "\f662";
}
.fa-futbol:before {
  content: "\f1e3";
}
.fa-galactic-republic:before {
  content: "\f50c";
}
.fa-galactic-senate:before {
  content: "\f50d";
}
.fa-gamepad:before {
  content: "\f11b";
}
.fa-gas-pump:before {
  content: "\f52f";
}
.fa-gavel:before {
  content: "\f0e3";
}
.fa-gem:before {
  content: "\f3a5";
}
.fa-genderless:before {
  content: "\f22d";
}
.fa-get-pocket:before {
  content: "\f265";
}
.fa-gg:before {
  content: "\f260";
}
.fa-gg-circle:before {
  content: "\f261";
}
.fa-ghost:before {
  content: "\f6e2";
}
.fa-gift:before {
  content: "\f06b";
}
.fa-gifts:before {
  content: "\f79c";
}
.fa-git:before {
  content: "\f1d3";
}
.fa-git-square:before {
  content: "\f1d2";
}
.fa-github:before {
  content: "\f09b";
}
.fa-github-alt:before {
  content: "\f113";
}
.fa-github-square:before {
  content: "\f092";
}
.fa-gitkraken:before {
  content: "\f3a6";
}
.fa-gitlab:before {
  content: "\f296";
}
.fa-gitter:before {
  content: "\f426";
}
.fa-glass-cheers:before {
  content: "\f79f";
}
.fa-glass-martini:before {
  content: "\f000";
}
.fa-glass-martini-alt:before {
  content: "\f57b";
}
.fa-glass-whiskey:before {
  content: "\f7a0";
}
.fa-glasses:before {
  content: "\f530";
}
.fa-glide:before {
  content: "\f2a5";
}
.fa-glide-g:before {
  content: "\f2a6";
}
.fa-globe:before {
  content: "\f0ac";
}
.fa-globe-africa:before {
  content: "\f57c";
}
.fa-globe-americas:before {
  content: "\f57d";
}
.fa-globe-asia:before {
  content: "\f57e";
}
.fa-globe-europe:before {
  content: "\f7a2";
}
.fa-gofore:before {
  content: "\f3a7";
}
.fa-golf-ball:before {
  content: "\f450";
}
.fa-goodreads:before {
  content: "\f3a8";
}
.fa-goodreads-g:before {
  content: "\f3a9";
}
.fa-google:before {
  content: "\f1a0";
}
.fa-google-drive:before {
  content: "\f3aa";
}
.fa-google-play:before {
  content: "\f3ab";
}
.fa-google-plus:before {
  content: "\f2b3";
}
.fa-google-plus-g:before {
  content: "\f0d5";
}
.fa-google-plus-square:before {
  content: "\f0d4";
}
.fa-google-wallet:before {
  content: "\f1ee";
}
.fa-gopuram:before {
  content: "\f664";
}
.fa-graduation-cap:before {
  content: "\f19d";
}
.fa-gratipay:before {
  content: "\f184";
}
.fa-grav:before {
  content: "\f2d6";
}
.fa-greater-than:before {
  content: "\f531";
}
.fa-greater-than-equal:before {
  content: "\f532";
}
.fa-grimace:before {
  content: "\f57f";
}
.fa-grin:before {
  content: "\f580";
}
.fa-grin-alt:before {
  content: "\f581";
}
.fa-grin-beam:before {
  content: "\f582";
}
.fa-grin-beam-sweat:before {
  content: "\f583";
}
.fa-grin-hearts:before {
  content: "\f584";
}
.fa-grin-squint:before {
  content: "\f585";
}
.fa-grin-squint-tears:before {
  content: "\f586";
}
.fa-grin-stars:before {
  content: "\f587";
}
.fa-grin-tears:before {
  content: "\f588";
}
.fa-grin-tongue:before {
  content: "\f589";
}
.fa-grin-tongue-squint:before {
  content: "\f58a";
}
.fa-grin-tongue-wink:before {
  content: "\f58b";
}
.fa-grin-wink:before {
  content: "\f58c";
}
.fa-grip-horizontal:before {
  content: "\f58d";
}
.fa-grip-lines:before {
  content: "\f7a4";
}
.fa-grip-lines-vertical:before {
  content: "\f7a5";
}
.fa-grip-vertical:before {
  content: "\f58e";
}
.fa-gripfire:before {
  content: "\f3ac";
}
.fa-grunt:before {
  content: "\f3ad";
}
.fa-guitar:before {
  content: "\f7a6";
}
.fa-gulp:before {
  content: "\f3ae";
}
.fa-h-square:before {
  content: "\f0fd";
}
.fa-hacker-news:before {
  content: "\f1d4";
}
.fa-hacker-news-square:before {
  content: "\f3af";
}
.fa-hackerrank:before {
  content: "\f5f7";
}
.fa-hamburger:before {
  content: "\f805";
}
.fa-hammer:before {
  content: "\f6e3";
}
.fa-hamsa:before {
  content: "\f665";
}
.fa-hand-holding:before {
  content: "\f4bd";
}
.fa-hand-holding-heart:before {
  content: "\f4be";
}
.fa-hand-holding-usd:before {
  content: "\f4c0";
}
.fa-hand-lizard:before {
  content: "\f258";
}
.fa-hand-middle-finger:before {
  content: "\f806";
}
.fa-hand-paper:before {
  content: "\f256";
}
.fa-hand-peace:before {
  content: "\f25b";
}
.fa-hand-point-down:before {
  content: "\f0a7";
}
.fa-hand-point-left:before {
  content: "\f0a5";
}
.fa-hand-point-right:before {
  content: "\f0a4";
}
.fa-hand-point-up:before {
  content: "\f0a6";
}
.fa-hand-pointer:before {
  content: "\f25a";
}
.fa-hand-rock:before {
  content: "\f255";
}
.fa-hand-scissors:before {
  content: "\f257";
}
.fa-hand-spock:before {
  content: "\f259";
}
.fa-hands:before {
  content: "\f4c2";
}
.fa-hands-helping:before {
  content: "\f4c4";
}
.fa-handshake:before {
  content: "\f2b5";
}
.fa-hanukiah:before {
  content: "\f6e6";
}
.fa-hard-hat:before {
  content: "\f807";
}
.fa-hashtag:before {
  content: "\f292";
}
.fa-hat-wizard:before {
  content: "\f6e8";
}
.fa-haykal:before {
  content: "\f666";
}
.fa-hdd:before {
  content: "\f0a0";
}
.fa-heading:before {
  content: "\f1dc";
}
.fa-headphones:before {
  content: "\f025";
}
.fa-headphones-alt:before {
  content: "\f58f";
}
.fa-headset:before {
  content: "\f590";
}
.fa-heart:before {
  content: "\f004";
}
.fa-heart-broken:before {
  content: "\f7a9";
}
.fa-heartbeat:before {
  content: "\f21e";
}
.fa-helicopter:before {
  content: "\f533";
}
.fa-highlighter:before {
  content: "\f591";
}
.fa-hiking:before {
  content: "\f6ec";
}
.fa-hippo:before {
  content: "\f6ed";
}
.fa-hips:before {
  content: "\f452";
}
.fa-hire-a-helper:before {
  content: "\f3b0";
}
.fa-history:before {
  content: "\f1da";
}
.fa-hockey-puck:before {
  content: "\f453";
}
.fa-holly-berry:before {
  content: "\f7aa";
}
.fa-home:before {
  content: "\f015";
}
.fa-hooli:before {
  content: "\f427";
}
.fa-hornbill:before {
  content: "\f592";
}
.fa-horse:before {
  content: "\f6f0";
}
.fa-horse-head:before {
  content: "\f7ab";
}
.fa-hospital:before {
  content: "\f0f8";
}
.fa-hospital-alt:before {
  content: "\f47d";
}
.fa-hospital-symbol:before {
  content: "\f47e";
}
.fa-hot-tub:before {
  content: "\f593";
}
.fa-hotdog:before {
  content: "\f80f";
}
.fa-hotel:before {
  content: "\f594";
}
.fa-hotjar:before {
  content: "\f3b1";
}
.fa-hourglass:before {
  content: "\f254";
}
.fa-hourglass-end:before {
  content: "\f253";
}
.fa-hourglass-half:before {
  content: "\f252";
}
.fa-hourglass-start:before {
  content: "\f251";
}
.fa-house-damage:before {
  content: "\f6f1";
}
.fa-houzz:before {
  content: "\f27c";
}
.fa-hryvnia:before {
  content: "\f6f2";
}
.fa-html5:before {
  content: "\f13b";
}
.fa-hubspot:before {
  content: "\f3b2";
}
.fa-i-cursor:before {
  content: "\f246";
}
.fa-ice-cream:before {
  content: "\f810";
}
.fa-icicles:before {
  content: "\f7ad";
}
.fa-id-badge:before {
  content: "\f2c1";
}
.fa-id-card:before {
  content: "\f2c2";
}
.fa-id-card-alt:before {
  content: "\f47f";
}
.fa-igloo:before {
  content: "\f7ae";
}
.fa-image:before {
  content: "\f03e";
}
.fa-images:before {
  content: "\f302";
}
.fa-imdb:before {
  content: "\f2d8";
}
.fa-inbox:before {
  content: "\f01c";
}
.fa-indent:before {
  content: "\f03c";
}
.fa-industry:before {
  content: "\f275";
}
.fa-infinity:before {
  content: "\f534";
}
.fa-info:before {
  content: "\f129";
}
.fa-info-circle:before {
  content: "\f05a";
}
.fa-instagram:before {
  content: "\f16d";
}
.fa-intercom:before {
  content: "\f7af";
}
.fa-internet-explorer:before {
  content: "\f26b";
}
.fa-invision:before {
  content: "\f7b0";
}
.fa-ioxhost:before {
  content: "\f208";
}
.fa-italic:before {
  content: "\f033";
}
.fa-itunes:before {
  content: "\f3b4";
}
.fa-itunes-note:before {
  content: "\f3b5";
}
.fa-java:before {
  content: "\f4e4";
}
.fa-jedi:before {
  content: "\f669";
}
.fa-jedi-order:before {
  content: "\f50e";
}
.fa-jenkins:before {
  content: "\f3b6";
}
.fa-jira:before {
  content: "\f7b1";
}
.fa-joget:before {
  content: "\f3b7";
}
.fa-joint:before {
  content: "\f595";
}
.fa-joomla:before {
  content: "\f1aa";
}
.fa-journal-whills:before {
  content: "\f66a";
}
.fa-js:before {
  content: "\f3b8";
}
.fa-js-square:before {
  content: "\f3b9";
}
.fa-jsfiddle:before {
  content: "\f1cc";
}
.fa-kaaba:before {
  content: "\f66b";
}
.fa-kaggle:before {
  content: "\f5fa";
}
.fa-key:before {
  content: "\f084";
}
.fa-keybase:before {
  content: "\f4f5";
}
.fa-keyboard:before {
  content: "\f11c";
}
.fa-keycdn:before {
  content: "\f3ba";
}
.fa-khanda:before {
  content: "\f66d";
}
.fa-kickstarter:before {
  content: "\f3bb";
}
.fa-kickstarter-k:before {
  content: "\f3bc";
}
.fa-kiss:before {
  content: "\f596";
}
.fa-kiss-beam:before {
  content: "\f597";
}
.fa-kiss-wink-heart:before {
  content: "\f598";
}
.fa-kiwi-bird:before {
  content: "\f535";
}
.fa-korvue:before {
  content: "\f42f";
}
.fa-landmark:before {
  content: "\f66f";
}
.fa-language:before {
  content: "\f1ab";
}
.fa-laptop:before {
  content: "\f109";
}
.fa-laptop-code:before {
  content: "\f5fc";
}
.fa-laptop-medical:before {
  content: "\f812";
}
.fa-laravel:before {
  content: "\f3bd";
}
.fa-lastfm:before {
  content: "\f202";
}
.fa-lastfm-square:before {
  content: "\f203";
}
.fa-laugh:before {
  content: "\f599";
}
.fa-laugh-beam:before {
  content: "\f59a";
}
.fa-laugh-squint:before {
  content: "\f59b";
}
.fa-laugh-wink:before {
  content: "\f59c";
}
.fa-layer-group:before {
  content: "\f5fd";
}
.fa-leaf:before {
  content: "\f06c";
}
.fa-leanpub:before {
  content: "\f212";
}
.fa-lemon:before {
  content: "\f094";
}
.fa-less:before {
  content: "\f41d";
}
.fa-less-than:before {
  content: "\f536";
}
.fa-less-than-equal:before {
  content: "\f537";
}
.fa-level-down-alt:before {
  content: "\f3be";
}
.fa-level-up-alt:before {
  content: "\f3bf";
}
.fa-life-ring:before {
  content: "\f1cd";
}
.fa-lightbulb:before {
  content: "\f0eb";
}
.fa-line:before {
  content: "\f3c0";
}
.fa-link:before {
  content: "\f0c1";
}
.fa-linkedin:before {
  content: "\f08c";
}
.fa-linkedin-in:before {
  content: "\f0e1";
}
.fa-linode:before {
  content: "\f2b8";
}
.fa-linux:before {
  content: "\f17c";
}
.fa-lira-sign:before {
  content: "\f195";
}
.fa-list:before {
  content: "\f03a";
}
.fa-list-alt:before {
  content: "\f022";
}
.fa-list-ol:before {
  content: "\f0cb";
}
.fa-list-ul:before {
  content: "\f0ca";
}
.fa-location-arrow:before {
  content: "\f124";
}
.fa-lock:before {
  content: "\f023";
}
.fa-lock-open:before {
  content: "\f3c1";
}
.fa-long-arrow-alt-down:before {
  content: "\f309";
}
.fa-long-arrow-alt-left:before {
  content: "\f30a";
}
.fa-long-arrow-alt-right:before {
  content: "\f30b";
}
.fa-long-arrow-alt-up:before {
  content: "\f30c";
}
.fa-low-vision:before {
  content: "\f2a8";
}
.fa-luggage-cart:before {
  content: "\f59d";
}
.fa-lyft:before {
  content: "\f3c3";
}
.fa-magento:before {
  content: "\f3c4";
}
.fa-magic:before {
  content: "\f0d0";
}
.fa-magnet:before {
  content: "\f076";
}
.fa-mail-bulk:before {
  content: "\f674";
}
.fa-mailchimp:before {
  content: "\f59e";
}
.fa-male:before {
  content: "\f183";
}
.fa-mandalorian:before {
  content: "\f50f";
}
.fa-map:before {
  content: "\f279";
}
.fa-map-marked:before {
  content: "\f59f";
}
.fa-map-marked-alt:before {
  content: "\f5a0";
}
.fa-map-marker:before {
  content: "\f041";
}
.fa-map-marker-alt:before {
  content: "\f3c5";
}
.fa-map-pin:before {
  content: "\f276";
}
.fa-map-signs:before {
  content: "\f277";
}
.fa-markdown:before {
  content: "\f60f";
}
.fa-marker:before {
  content: "\f5a1";
}
.fa-mars:before {
  content: "\f222";
}
.fa-mars-double:before {
  content: "\f227";
}
.fa-mars-stroke:before {
  content: "\f229";
}
.fa-mars-stroke-h:before {
  content: "\f22b";
}
.fa-mars-stroke-v:before {
  content: "\f22a";
}
.fa-mask:before {
  content: "\f6fa";
}
.fa-mastodon:before {
  content: "\f4f6";
}
.fa-maxcdn:before {
  content: "\f136";
}
.fa-medal:before {
  content: "\f5a2";
}
.fa-medapps:before {
  content: "\f3c6";
}
.fa-medium:before {
  content: "\f23a";
}
.fa-medium-m:before {
  content: "\f3c7";
}
.fa-medkit:before {
  content: "\f0fa";
}
.fa-medrt:before {
  content: "\f3c8";
}
.fa-meetup:before {
  content: "\f2e0";
}
.fa-megaport:before {
  content: "\f5a3";
}
.fa-meh:before {
  content: "\f11a";
}
.fa-meh-blank:before {
  content: "\f5a4";
}
.fa-meh-rolling-eyes:before {
  content: "\f5a5";
}
.fa-memory:before {
  content: "\f538";
}
.fa-mendeley:before {
  content: "\f7b3";
}
.fa-menorah:before {
  content: "\f676";
}
.fa-mercury:before {
  content: "\f223";
}
.fa-meteor:before {
  content: "\f753";
}
.fa-microchip:before {
  content: "\f2db";
}
.fa-microphone:before {
  content: "\f130";
}
.fa-microphone-alt:before {
  content: "\f3c9";
}
.fa-microphone-alt-slash:before {
  content: "\f539";
}
.fa-microphone-slash:before {
  content: "\f131";
}
.fa-microscope:before {
  content: "\f610";
}
.fa-microsoft:before {
  content: "\f3ca";
}
.fa-minus:before {
  content: "\f068";
}
.fa-minus-circle:before {
  content: "\f056";
}
.fa-minus-square:before {
  content: "\f146";
}
.fa-mitten:before {
  content: "\f7b5";
}
.fa-mix:before {
  content: "\f3cb";
}
.fa-mixcloud:before {
  content: "\f289";
}
.fa-mizuni:before {
  content: "\f3cc";
}
.fa-mobile:before {
  content: "\f10b";
}
.fa-mobile-alt:before {
  content: "\f3cd";
}
.fa-modx:before {
  content: "\f285";
}
.fa-monero:before {
  content: "\f3d0";
}
.fa-money-bill:before {
  content: "\f0d6";
}
.fa-money-bill-alt:before {
  content: "\f3d1";
}
.fa-money-bill-wave:before {
  content: "\f53a";
}
.fa-money-bill-wave-alt:before {
  content: "\f53b";
}
.fa-money-check:before {
  content: "\f53c";
}
.fa-money-check-alt:before {
  content: "\f53d";
}
.fa-monument:before {
  content: "\f5a6";
}
.fa-moon:before {
  content: "\f186";
}
.fa-mortar-pestle:before {
  content: "\f5a7";
}
.fa-mosque:before {
  content: "\f678";
}
.fa-motorcycle:before {
  content: "\f21c";
}
.fa-mountain:before {
  content: "\f6fc";
}
.fa-mouse-pointer:before {
  content: "\f245";
}
.fa-mug-hot:before {
  content: "\f7b6";
}
.fa-music:before {
  content: "\f001";
}
.fa-napster:before {
  content: "\f3d2";
}
.fa-neos:before {
  content: "\f612";
}
.fa-network-wired:before {
  content: "\f6ff";
}
.fa-neuter:before {
  content: "\f22c";
}
.fa-newspaper:before {
  content: "\f1ea";
}
.fa-nimblr:before {
  content: "\f5a8";
}
.fa-nintendo-switch:before {
  content: "\f418";
}
.fa-node:before {
  content: "\f419";
}
.fa-node-js:before {
  content: "\f3d3";
}
.fa-not-equal:before {
  content: "\f53e";
}
.fa-notes-medical:before {
  content: "\f481";
}
.fa-npm:before {
  content: "\f3d4";
}
.fa-ns8:before {
  content: "\f3d5";
}
.fa-nutritionix:before {
  content: "\f3d6";
}
.fa-object-group:before {
  content: "\f247";
}
.fa-object-ungroup:before {
  content: "\f248";
}
.fa-odnoklassniki:before {
  content: "\f263";
}
.fa-odnoklassniki-square:before {
  content: "\f264";
}
.fa-oil-can:before {
  content: "\f613";
}
.fa-old-republic:before {
  content: "\f510";
}
.fa-om:before {
  content: "\f679";
}
.fa-opencart:before {
  content: "\f23d";
}
.fa-openid:before {
  content: "\f19b";
}
.fa-opera:before {
  content: "\f26a";
}
.fa-optin-monster:before {
  content: "\f23c";
}
.fa-osi:before {
  content: "\f41a";
}
.fa-otter:before {
  content: "\f700";
}
.fa-outdent:before {
  content: "\f03b";
}
.fa-page4:before {
  content: "\f3d7";
}
.fa-pagelines:before {
  content: "\f18c";
}
.fa-pager:before {
  content: "\f815";
}
.fa-paint-brush:before {
  content: "\f1fc";
}
.fa-paint-roller:before {
  content: "\f5aa";
}
.fa-palette:before {
  content: "\f53f";
}
.fa-palfed:before {
  content: "\f3d8";
}
.fa-pallet:before {
  content: "\f482";
}
.fa-paper-plane:before {
  content: "\f1d8";
}
.fa-paperclip:before {
  content: "\f0c6";
}
.fa-parachute-box:before {
  content: "\f4cd";
}
.fa-paragraph:before {
  content: "\f1dd";
}
.fa-parking:before {
  content: "\f540";
}
.fa-passport:before {
  content: "\f5ab";
}
.fa-pastafarianism:before {
  content: "\f67b";
}
.fa-paste:before {
  content: "\f0ea";
}
.fa-patreon:before {
  content: "\f3d9";
}
.fa-pause:before {
  content: "\f04c";
}
.fa-pause-circle:before {
  content: "\f28b";
}
.fa-paw:before {
  content: "\f1b0";
}
.fa-paypal:before {
  content: "\f1ed";
}
.fa-peace:before {
  content: "\f67c";
}
.fa-pen:before {
  content: "\f304";
}
.fa-pen-alt:before {
  content: "\f305";
}
.fa-pen-fancy:before {
  content: "\f5ac";
}
.fa-pen-nib:before {
  content: "\f5ad";
}
.fa-pen-square:before {
  content: "\f14b";
}
.fa-pencil-alt:before {
  content: "\f303";
}
.fa-pencil-ruler:before {
  content: "\f5ae";
}
.fa-penny-arcade:before {
  content: "\f704";
}
.fa-people-carry:before {
  content: "\f4ce";
}
.fa-pepper-hot:before {
  content: "\f816";
}
.fa-percent:before {
  content: "\f295";
}
.fa-percentage:before {
  content: "\f541";
}
.fa-periscope:before {
  content: "\f3da";
}
.fa-person-booth:before {
  content: "\f756";
}
.fa-phabricator:before {
  content: "\f3db";
}
.fa-phoenix-framework:before {
  content: "\f3dc";
}
.fa-phoenix-squadron:before {
  content: "\f511";
}
.fa-phone:before {
  content: "\f095";
}
.fa-phone-slash:before {
  content: "\f3dd";
}
.fa-phone-square:before {
  content: "\f098";
}
.fa-phone-volume:before {
  content: "\f2a0";
}
.fa-php:before {
  content: "\f457";
}
.fa-pied-piper:before {
  content: "\f2ae";
}
.fa-pied-piper-alt:before {
  content: "\f1a8";
}
.fa-pied-piper-hat:before {
  content: "\f4e5";
}
.fa-pied-piper-pp:before {
  content: "\f1a7";
}
.fa-piggy-bank:before {
  content: "\f4d3";
}
.fa-pills:before {
  content: "\f484";
}
.fa-pinterest:before {
  content: "\f0d2";
}
.fa-pinterest-p:before {
  content: "\f231";
}
.fa-pinterest-square:before {
  content: "\f0d3";
}
.fa-pizza-slice:before {
  content: "\f818";
}
.fa-place-of-worship:before {
  content: "\f67f";
}
.fa-plane:before {
  content: "\f072";
}
.fa-plane-arrival:before {
  content: "\f5af";
}
.fa-plane-departure:before {
  content: "\f5b0";
}
.fa-play:before {
  content: "\f04b";
}
.fa-play-circle:before {
  content: "\f144";
}
.fa-playstation:before {
  content: "\f3df";
}
.fa-plug:before {
  content: "\f1e6";
}
.fa-plus:before {
  content: "\f067";
}
.fa-plus-circle:before {
  content: "\f055";
}
.fa-plus-square:before {
  content: "\f0fe";
}
.fa-podcast:before {
  content: "\f2ce";
}
.fa-poll:before {
  content: "\f681";
}
.fa-poll-h:before {
  content: "\f682";
}
.fa-poo:before {
  content: "\f2fe";
}
.fa-poo-storm:before {
  content: "\f75a";
}
.fa-poop:before {
  content: "\f619";
}
.fa-portrait:before {
  content: "\f3e0";
}
.fa-pound-sign:before {
  content: "\f154";
}
.fa-power-off:before {
  content: "\f011";
}
.fa-pray:before {
  content: "\f683";
}
.fa-praying-hands:before {
  content: "\f684";
}
.fa-prescription:before {
  content: "\f5b1";
}
.fa-prescription-bottle:before {
  content: "\f485";
}
.fa-prescription-bottle-alt:before {
  content: "\f486";
}
.fa-print:before {
  content: "\f02f";
}
.fa-procedures:before {
  content: "\f487";
}
.fa-product-hunt:before {
  content: "\f288";
}
.fa-project-diagram:before {
  content: "\f542";
}
.fa-pushed:before {
  content: "\f3e1";
}
.fa-puzzle-piece:before {
  content: "\f12e";
}
.fa-python:before {
  content: "\f3e2";
}
.fa-qq:before {
  content: "\f1d6";
}
.fa-qrcode:before {
  content: "\f029";
}
.fa-question:before {
  content: "\f128";
}
.fa-question-circle:before {
  content: "\f059";
}
.fa-quidditch:before {
  content: "\f458";
}
.fa-quinscape:before {
  content: "\f459";
}
.fa-quora:before {
  content: "\f2c4";
}
.fa-quote-left:before {
  content: "\f10d";
}
.fa-quote-right:before {
  content: "\f10e";
}
.fa-quran:before {
  content: "\f687";
}
.fa-r-project:before {
  content: "\f4f7";
}
.fa-radiation:before {
  content: "\f7b9";
}
.fa-radiation-alt:before {
  content: "\f7ba";
}
.fa-rainbow:before {
  content: "\f75b";
}
.fa-random:before {
  content: "\f074";
}
.fa-raspberry-pi:before {
  content: "\f7bb";
}
.fa-ravelry:before {
  content: "\f2d9";
}
.fa-react:before {
  content: "\f41b";
}
.fa-reacteurope:before {
  content: "\f75d";
}
.fa-readme:before {
  content: "\f4d5";
}
.fa-rebel:before {
  content: "\f1d0";
}
.fa-receipt:before {
  content: "\f543";
}
.fa-recycle:before {
  content: "\f1b8";
}
.fa-red-river:before {
  content: "\f3e3";
}
.fa-reddit:before {
  content: "\f1a1";
}
.fa-reddit-alien:before {
  content: "\f281";
}
.fa-reddit-square:before {
  content: "\f1a2";
}
.fa-redhat:before {
  content: "\f7bc";
}
.fa-redo:before {
  content: "\f01e";
}
.fa-redo-alt:before {
  content: "\f2f9";
}
.fa-registered:before {
  content: "\f25d";
}
.fa-renren:before {
  content: "\f18b";
}
.fa-reply:before {
  content: "\f3e5";
}
.fa-reply-all:before {
  content: "\f122";
}
.fa-replyd:before {
  content: "\f3e6";
}
.fa-republican:before {
  content: "\f75e";
}
.fa-researchgate:before {
  content: "\f4f8";
}
.fa-resolving:before {
  content: "\f3e7";
}
.fa-restroom:before {
  content: "\f7bd";
}
.fa-retweet:before {
  content: "\f079";
}
.fa-rev:before {
  content: "\f5b2";
}
.fa-ribbon:before {
  content: "\f4d6";
}
.fa-ring:before {
  content: "\f70b";
}
.fa-road:before {
  content: "\f018";
}
.fa-robot:before {
  content: "\f544";
}
.fa-rocket:before {
  content: "\f135";
}
.fa-rocketchat:before {
  content: "\f3e8";
}
.fa-rockrms:before {
  content: "\f3e9";
}
.fa-route:before {
  content: "\f4d7";
}
.fa-rss:before {
  content: "\f09e";
}
.fa-rss-square:before {
  content: "\f143";
}
.fa-ruble-sign:before {
  content: "\f158";
}
.fa-ruler:before {
  content: "\f545";
}
.fa-ruler-combined:before {
  content: "\f546";
}
.fa-ruler-horizontal:before {
  content: "\f547";
}
.fa-ruler-vertical:before {
  content: "\f548";
}
.fa-running:before {
  content: "\f70c";
}
.fa-rupee-sign:before {
  content: "\f156";
}
.fa-sad-cry:before {
  content: "\f5b3";
}
.fa-sad-tear:before {
  content: "\f5b4";
}
.fa-safari:before {
  content: "\f267";
}
.fa-sass:before {
  content: "\f41e";
}
.fa-satellite:before {
  content: "\f7bf";
}
.fa-satellite-dish:before {
  content: "\f7c0";
}
.fa-save:before {
  content: "\f0c7";
}
.fa-schlix:before {
  content: "\f3ea";
}
.fa-school:before {
  content: "\f549";
}
.fa-screwdriver:before {
  content: "\f54a";
}
.fa-scribd:before {
  content: "\f28a";
}
.fa-scroll:before {
  content: "\f70e";
}
.fa-sd-card:before {
  content: "\f7c2";
}
.fa-search:before {
  content: "\f002";
}
.fa-search-dollar:before {
  content: "\f688";
}
.fa-search-location:before {
  content: "\f689";
}
.fa-search-minus:before {
  content: "\f010";
}
.fa-search-plus:before {
  content: "\f00e";
}
.fa-searchengin:before {
  content: "\f3eb";
}
.fa-seedling:before {
  content: "\f4d8";
}
.fa-sellcast:before {
  content: "\f2da";
}
.fa-sellsy:before {
  content: "\f213";
}
.fa-server:before {
  content: "\f233";
}
.fa-servicestack:before {
  content: "\f3ec";
}
.fa-shapes:before {
  content: "\f61f";
}
.fa-share:before {
  content: "\f064";
}
.fa-share-alt:before {
  content: "\f1e0";
}
.fa-share-alt-square:before {
  content: "\f1e1";
}
.fa-share-square:before {
  content: "\f14d";
}
.fa-shekel-sign:before {
  content: "\f20b";
}
.fa-shield-alt:before {
  content: "\f3ed";
}
.fa-ship:before {
  content: "\f21a";
}
.fa-shipping-fast:before {
  content: "\f48b";
}
.fa-shirtsinbulk:before {
  content: "\f214";
}
.fa-shoe-prints:before {
  content: "\f54b";
}
.fa-shopping-bag:before {
  content: "\f290";
}
.fa-shopping-basket:before {
  content: "\f291";
}
.fa-shopping-cart:before {
  content: "\f07a";
}
.fa-shopware:before {
  content: "\f5b5";
}
.fa-shower:before {
  content: "\f2cc";
}
.fa-shuttle-van:before {
  content: "\f5b6";
}
.fa-sign:before {
  content: "\f4d9";
}
.fa-sign-in-alt:before {
  content: "\f2f6";
}
.fa-sign-language:before {
  content: "\f2a7";
}
.fa-sign-out-alt:before {
  content: "\f2f5";
}
.fa-signal:before {
  content: "\f012";
}
.fa-signature:before {
  content: "\f5b7";
}
.fa-sim-card:before {
  content: "\f7c4";
}
.fa-simplybuilt:before {
  content: "\f215";
}
.fa-sistrix:before {
  content: "\f3ee";
}
.fa-sitemap:before {
  content: "\f0e8";
}
.fa-sith:before {
  content: "\f512";
}
.fa-skating:before {
  content: "\f7c5";
}
.fa-sketch:before {
  content: "\f7c6";
}
.fa-skiing:before {
  content: "\f7c9";
}
.fa-skiing-nordic:before {
  content: "\f7ca";
}
.fa-skull:before {
  content: "\f54c";
}
.fa-skull-crossbones:before {
  content: "\f714";
}
.fa-skyatlas:before {
  content: "\f216";
}
.fa-skype:before {
  content: "\f17e";
}
.fa-slack:before {
  content: "\f198";
}
.fa-slack-hash:before {
  content: "\f3ef";
}
.fa-slash:before {
  content: "\f715";
}
.fa-sleigh:before {
  content: "\f7cc";
}
.fa-sliders-h:before {
  content: "\f1de";
}
.fa-slideshare:before {
  content: "\f1e7";
}
.fa-smile:before {
  content: "\f118";
}
.fa-smile-beam:before {
  content: "\f5b8";
}
.fa-smile-wink:before {
  content: "\f4da";
}
.fa-smog:before {
  content: "\f75f";
}
.fa-smoking:before {
  content: "\f48d";
}
.fa-smoking-ban:before {
  content: "\f54d";
}
.fa-sms:before {
  content: "\f7cd";
}
.fa-snapchat:before {
  content: "\f2ab";
}
.fa-snapchat-ghost:before {
  content: "\f2ac";
}
.fa-snapchat-square:before {
  content: "\f2ad";
}
.fa-snowboarding:before {
  content: "\f7ce";
}
.fa-snowflake:before {
  content: "\f2dc";
}
.fa-snowman:before {
  content: "\f7d0";
}
.fa-snowplow:before {
  content: "\f7d2";
}
.fa-socks:before {
  content: "\f696";
}
.fa-solar-panel:before {
  content: "\f5ba";
}
.fa-sort:before {
  content: "\f0dc";
}
.fa-sort-alpha-down:before {
  content: "\f15d";
}
.fa-sort-alpha-up:before {
  content: "\f15e";
}
.fa-sort-amount-down:before {
  content: "\f160";
}
.fa-sort-amount-up:before {
  content: "\f161";
}
.fa-sort-down:before {
  content: "\f0dd";
}
.fa-sort-numeric-down:before {
  content: "\f162";
}
.fa-sort-numeric-up:before {
  content: "\f163";
}
.fa-sort-up:before {
  content: "\f0de";
}
.fa-soundcloud:before {
  content: "\f1be";
}
.fa-sourcetree:before {
  content: "\f7d3";
}
.fa-spa:before {
  content: "\f5bb";
}
.fa-space-shuttle:before {
  content: "\f197";
}
.fa-speakap:before {
  content: "\f3f3";
}
.fa-spider:before {
  content: "\f717";
}
.fa-spinner:before {
  content: "\f110";
}
.fa-splotch:before {
  content: "\f5bc";
}
.fa-spotify:before {
  content: "\f1bc";
}
.fa-spray-can:before {
  content: "\f5bd";
}
.fa-square:before {
  content: "\f0c8";
}
.fa-square-full:before {
  content: "\f45c";
}
.fa-square-root-alt:before {
  content: "\f698";
}
.fa-squarespace:before {
  content: "\f5be";
}
.fa-stack-exchange:before {
  content: "\f18d";
}
.fa-stack-overflow:before {
  content: "\f16c";
}
.fa-stamp:before {
  content: "\f5bf";
}
.fa-star:before {
  content: "\f005";
}
.fa-star-and-crescent:before {
  content: "\f699";
}
.fa-star-half:before {
  content: "\f089";
}
.fa-star-half-alt:before {
  content: "\f5c0";
}
.fa-star-of-david:before {
  content: "\f69a";
}
.fa-star-of-life:before {
  content: "\f621";
}
.fa-staylinked:before {
  content: "\f3f5";
}
.fa-steam:before {
  content: "\f1b6";
}
.fa-steam-square:before {
  content: "\f1b7";
}
.fa-steam-symbol:before {
  content: "\f3f6";
}
.fa-step-backward:before {
  content: "\f048";
}
.fa-step-forward:before {
  content: "\f051";
}
.fa-stethoscope:before {
  content: "\f0f1";
}
.fa-sticker-mule:before {
  content: "\f3f7";
}
.fa-sticky-note:before {
  content: "\f249";
}
.fa-stop:before {
  content: "\f04d";
}
.fa-stop-circle:before {
  content: "\f28d";
}
.fa-stopwatch:before {
  content: "\f2f2";
}
.fa-store:before {
  content: "\f54e";
}
.fa-store-alt:before {
  content: "\f54f";
}
.fa-strava:before {
  content: "\f428";
}
.fa-stream:before {
  content: "\f550";
}
.fa-street-view:before {
  content: "\f21d";
}
.fa-strikethrough:before {
  content: "\f0cc";
}
.fa-stripe:before {
  content: "\f429";
}
.fa-stripe-s:before {
  content: "\f42a";
}
.fa-stroopwafel:before {
  content: "\f551";
}
.fa-studiovinari:before {
  content: "\f3f8";
}
.fa-stumbleupon:before {
  content: "\f1a4";
}
.fa-stumbleupon-circle:before {
  content: "\f1a3";
}
.fa-subscript:before {
  content: "\f12c";
}
.fa-subway:before {
  content: "\f239";
}
.fa-suitcase:before {
  content: "\f0f2";
}
.fa-suitcase-rolling:before {
  content: "\f5c1";
}
.fa-sun:before {
  content: "\f185";
}
.fa-superpowers:before {
  content: "\f2dd";
}
.fa-superscript:before {
  content: "\f12b";
}
.fa-supple:before {
  content: "\f3f9";
}
.fa-surprise:before {
  content: "\f5c2";
}
.fa-suse:before {
  content: "\f7d6";
}
.fa-swatchbook:before {
  content: "\f5c3";
}
.fa-swimmer:before {
  content: "\f5c4";
}
.fa-swimming-pool:before {
  content: "\f5c5";
}
.fa-synagogue:before {
  content: "\f69b";
}
.fa-sync:before {
  content: "\f021";
}
.fa-sync-alt:before {
  content: "\f2f1";
}
.fa-syringe:before {
  content: "\f48e";
}
.fa-table:before {
  content: "\f0ce";
}
.fa-table-tennis:before {
  content: "\f45d";
}
.fa-tablet:before {
  content: "\f10a";
}
.fa-tablet-alt:before {
  content: "\f3fa";
}
.fa-tablets:before {
  content: "\f490";
}
.fa-tachometer-alt:before {
  content: "\f3fd";
}
.fa-tag:before {
  content: "\f02b";
}
.fa-tags:before {
  content: "\f02c";
}
.fa-tape:before {
  content: "\f4db";
}
.fa-tasks:before {
  content: "\f0ae";
}
.fa-taxi:before {
  content: "\f1ba";
}
.fa-teamspeak:before {
  content: "\f4f9";
}
.fa-teeth:before {
  content: "\f62e";
}
.fa-teeth-open:before {
  content: "\f62f";
}
.fa-telegram:before {
  content: "\f2c6";
}
.fa-telegram-plane:before {
  content: "\f3fe";
}
.fa-temperature-high:before {
  content: "\f769";
}
.fa-temperature-low:before {
  content: "\f76b";
}
.fa-tencent-weibo:before {
  content: "\f1d5";
}
.fa-tenge:before {
  content: "\f7d7";
}
.fa-terminal:before {
  content: "\f120";
}
.fa-text-height:before {
  content: "\f034";
}
.fa-text-width:before {
  content: "\f035";
}
.fa-th:before {
  content: "\f00a";
}
.fa-th-large:before {
  content: "\f009";
}
.fa-th-list:before {
  content: "\f00b";
}
.fa-the-red-yeti:before {
  content: "\f69d";
}
.fa-theater-masks:before {
  content: "\f630";
}
.fa-themeco:before {
  content: "\f5c6";
}
.fa-themeisle:before {
  content: "\f2b2";
}
.fa-thermometer:before {
  content: "\f491";
}
.fa-thermometer-empty:before {
  content: "\f2cb";
}
.fa-thermometer-full:before {
  content: "\f2c7";
}
.fa-thermometer-half:before {
  content: "\f2c9";
}
.fa-thermometer-quarter:before {
  content: "\f2ca";
}
.fa-thermometer-three-quarters:before {
  content: "\f2c8";
}
.fa-think-peaks:before {
  content: "\f731";
}
.fa-thumbs-down:before {
  content: "\f165";
}
.fa-thumbs-up:before {
  content: "\f164";
}
.fa-thumbtack:before {
  content: "\f08d";
}
.fa-ticket-alt:before {
  content: "\f3ff";
}
.fa-times:before {
  content: "\f00d";
}
.fa-times-circle:before {
  content: "\f057";
}
.fa-tint:before {
  content: "\f043";
}
.fa-tint-slash:before {
  content: "\f5c7";
}
.fa-tired:before {
  content: "\f5c8";
}
.fa-toggle-off:before {
  content: "\f204";
}
.fa-toggle-on:before {
  content: "\f205";
}
.fa-toilet:before {
  content: "\f7d8";
}
.fa-toilet-paper:before {
  content: "\f71e";
}
.fa-toolbox:before {
  content: "\f552";
}
.fa-tools:before {
  content: "\f7d9";
}
.fa-tooth:before {
  content: "\f5c9";
}
.fa-torah:before {
  content: "\f6a0";
}
.fa-torii-gate:before {
  content: "\f6a1";
}
.fa-tractor:before {
  content: "\f722";
}
.fa-trade-federation:before {
  content: "\f513";
}
.fa-trademark:before {
  content: "\f25c";
}
.fa-traffic-light:before {
  content: "\f637";
}
.fa-train:before {
  content: "\f238";
}
.fa-tram:before {
  content: "\f7da";
}
.fa-transgender:before {
  content: "\f224";
}
.fa-transgender-alt:before {
  content: "\f225";
}
.fa-trash:before {
  content: "\f1f8";
}
.fa-trash-alt:before {
  content: "\f2ed";
}
.fa-trash-restore:before {
  content: "\f829";
}
.fa-trash-restore-alt:before {
  content: "\f82a";
}
.fa-tree:before {
  content: "\f1bb";
}
.fa-trello:before {
  content: "\f181";
}
.fa-tripadvisor:before {
  content: "\f262";
}
.fa-trophy:before {
  content: "\f091";
}
.fa-truck:before {
  content: "\f0d1";
}
.fa-truck-loading:before {
  content: "\f4de";
}
.fa-truck-monster:before {
  content: "\f63b";
}
.fa-truck-moving:before {
  content: "\f4df";
}
.fa-truck-pickup:before {
  content: "\f63c";
}
.fa-tshirt:before {
  content: "\f553";
}
.fa-tty:before {
  content: "\f1e4";
}
.fa-tumblr:before {
  content: "\f173";
}
.fa-tumblr-square:before {
  content: "\f174";
}
.fa-tv:before {
  content: "\f26c";
}
.fa-twitch:before {
  content: "\f1e8";
}
.fa-twitter:before {
  content: "\f099";
}
.fa-twitter-square:before {
  content: "\f081";
}
.fa-typo3:before {
  content: "\f42b";
}
.fa-uber:before {
  content: "\f402";
}
.fa-ubuntu:before {
  content: "\f7df";
}
.fa-uikit:before {
  content: "\f403";
}
.fa-umbrella:before {
  content: "\f0e9";
}
.fa-umbrella-beach:before {
  content: "\f5ca";
}
.fa-underline:before {
  content: "\f0cd";
}
.fa-undo:before {
  content: "\f0e2";
}
.fa-undo-alt:before {
  content: "\f2ea";
}
.fa-uniregistry:before {
  content: "\f404";
}
.fa-universal-access:before {
  content: "\f29a";
}
.fa-university:before {
  content: "\f19c";
}
.fa-unlink:before {
  content: "\f127";
}
.fa-unlock:before {
  content: "\f09c";
}
.fa-unlock-alt:before {
  content: "\f13e";
}
.fa-untappd:before {
  content: "\f405";
}
.fa-upload:before {
  content: "\f093";
}
.fa-ups:before {
  content: "\f7e0";
}
.fa-usb:before {
  content: "\f287";
}
.fa-user:before {
  content: "\f007";
}
.fa-user-alt:before {
  content: "\f406";
}
.fa-user-alt-slash:before {
  content: "\f4fa";
}
.fa-user-astronaut:before {
  content: "\f4fb";
}
.fa-user-check:before {
  content: "\f4fc";
}
.fa-user-circle:before {
  content: "\f2bd";
}
.fa-user-clock:before {
  content: "\f4fd";
}
.fa-user-cog:before {
  content: "\f4fe";
}
.fa-user-edit:before {
  content: "\f4ff";
}
.fa-user-friends:before {
  content: "\f500";
}
.fa-user-graduate:before {
  content: "\f501";
}
.fa-user-injured:before {
  content: "\f728";
}
.fa-user-lock:before {
  content: "\f502";
}
.fa-user-md:before {
  content: "\f0f0";
}
.fa-user-minus:before {
  content: "\f503";
}
.fa-user-ninja:before {
  content: "\f504";
}
.fa-user-nurse:before {
  content: "\f82f";
}
.fa-user-plus:before {
  content: "\f234";
}
.fa-user-secret:before {
  content: "\f21b";
}
.fa-user-shield:before {
  content: "\f505";
}
.fa-user-slash:before {
  content: "\f506";
}
.fa-user-tag:before {
  content: "\f507";
}
.fa-user-tie:before {
  content: "\f508";
}
.fa-user-times:before {
  content: "\f235";
}
.fa-users:before {
  content: "\f0c0";
}
.fa-users-cog:before {
  content: "\f509";
}
.fa-usps:before {
  content: "\f7e1";
}
.fa-ussunnah:before {
  content: "\f407";
}
.fa-utensil-spoon:before {
  content: "\f2e5";
}
.fa-utensils:before {
  content: "\f2e7";
}
.fa-vaadin:before {
  content: "\f408";
}
.fa-vector-square:before {
  content: "\f5cb";
}
.fa-venus:before {
  content: "\f221";
}
.fa-venus-double:before {
  content: "\f226";
}
.fa-venus-mars:before {
  content: "\f228";
}
.fa-viacoin:before {
  content: "\f237";
}
.fa-viadeo:before {
  content: "\f2a9";
}
.fa-viadeo-square:before {
  content: "\f2aa";
}
.fa-vial:before {
  content: "\f492";
}
.fa-vials:before {
  content: "\f493";
}
.fa-viber:before {
  content: "\f409";
}
.fa-video:before {
  content: "\f03d";
}
.fa-video-slash:before {
  content: "\f4e2";
}
.fa-vihara:before {
  content: "\f6a7";
}
.fa-vimeo:before {
  content: "\f40a";
}
.fa-vimeo-square:before {
  content: "\f194";
}
.fa-vimeo-v:before {
  content: "\f27d";
}
.fa-vine:before {
  content: "\f1ca";
}
.fa-vk:before {
  content: "\f189";
}
.fa-vnv:before {
  content: "\f40b";
}
.fa-volleyball-ball:before {
  content: "\f45f";
}
.fa-volume-down:before {
  content: "\f027";
}
.fa-volume-mute:before {
  content: "\f6a9";
}
.fa-volume-off:before {
  content: "\f026";
}
.fa-volume-up:before {
  content: "\f028";
}
.fa-vote-yea:before {
  content: "\f772";
}
.fa-vr-cardboard:before {
  content: "\f729";
}
.fa-vuejs:before {
  content: "\f41f";
}
.fa-walking:before {
  content: "\f554";
}
.fa-wallet:before {
  content: "\f555";
}
.fa-warehouse:before {
  content: "\f494";
}
.fa-water:before {
  content: "\f773";
}
.fa-weebly:before {
  content: "\f5cc";
}
.fa-weibo:before {
  content: "\f18a";
}
.fa-weight:before {
  content: "\f496";
}
.fa-weight-hanging:before {
  content: "\f5cd";
}
.fa-weixin:before {
  content: "\f1d7";
}
.fa-whatsapp:before {
  content: "\f232";
}
.fa-whatsapp-square:before {
  content: "\f40c";
}
.fa-wheelchair:before {
  content: "\f193";
}
.fa-whmcs:before {
  content: "\f40d";
}
.fa-wifi:before {
  content: "\f1eb";
}
.fa-wikipedia-w:before {
  content: "\f266";
}
.fa-wind:before {
  content: "\f72e";
}
.fa-window-close:before {
  content: "\f410";
}
.fa-window-maximize:before {
  content: "\f2d0";
}
.fa-window-minimize:before {
  content: "\f2d1";
}
.fa-window-restore:before {
  content: "\f2d2";
}
.fa-windows:before {
  content: "\f17a";
}
.fa-wine-bottle:before {
  content: "\f72f";
}
.fa-wine-glass:before {
  content: "\f4e3";
}
.fa-wine-glass-alt:before {
  content: "\f5ce";
}
.fa-wix:before {
  content: "\f5cf";
}
.fa-wizards-of-the-coast:before {
  content: "\f730";
}
.fa-wolf-pack-battalion:before {
  content: "\f514";
}
.fa-won-sign:before {
  content: "\f159";
}
.fa-wordpress:before {
  content: "\f19a";
}
.fa-wordpress-simple:before {
  content: "\f411";
}
.fa-wpbeginner:before {
  content: "\f297";
}
.fa-wpexplorer:before {
  content: "\f2de";
}
.fa-wpforms:before {
  content: "\f298";
}
.fa-wpressr:before {
  content: "\f3e4";
}
.fa-wrench:before {
  content: "\f0ad";
}
.fa-x-ray:before {
  content: "\f497";
}
.fa-xbox:before {
  content: "\f412";
}
.fa-xing:before {
  content: "\f168";
}
.fa-xing-square:before {
  content: "\f169";
}
.fa-y-combinator:before {
  content: "\f23b";
}
.fa-yahoo:before {
  content: "\f19e";
}
.fa-yandex:before {
  content: "\f413";
}
.fa-yandex-international:before {
  content: "\f414";
}
.fa-yarn:before {
  content: "\f7e3";
}
.fa-yelp:before {
  content: "\f1e9";
}
.fa-yen-sign:before {
  content: "\f157";
}
.fa-yin-yang:before {
  content: "\f6ad";
}
.fa-yoast:before {
  content: "\f2b1";
}
.fa-youtube:before {
  content: "\f167";
}
.fa-youtube-square:before {
  content: "\f431";
}
.fa-zhihu:before {
  content: "\f63f";
}
.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}
@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url('../fonts/fa-brands-400.eot');
  src: url('../fonts/fa-brands-400.eot?#iefix') format('embedded-opentype'), url('../fonts/fa-brands-400.woff2') format('woff2'), url('../fonts/fa-brands-400.woff') format('woff'), url('../fonts/fa-brands-400.ttf') format('truetype'), url('../fonts/fa-brands-400.svg#fontawesome') format('svg');
}
.fab {
  font-family: 'Font Awesome 5 Brands';
}
@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url('../fonts/fa-regular-400.eot');
  src: url('../fonts/fa-regular-400.eot?#iefix') format('embedded-opentype'), url('../fonts/fa-regular-400.woff2') format('woff2'), url('../fonts/fa-regular-400.woff') format('woff'), url('../fonts/fa-regular-400.ttf') format('truetype'), url('../fonts/fa-regular-400.svg#fontawesome') format('svg');
}
.far {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400;
}
@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url('../fonts/fa-solid-900.eot');
  src: url('../fonts/fa-solid-900.eot?#iefix') format('embedded-opentype'), url('../fonts/fa-solid-900.woff2') format('woff2'), url('../fonts/fa-solid-900.woff') format('woff'), url('../fonts/fa-solid-900.ttf') format('truetype'), url('../fonts/fa-solid-900.svg#fontawesome') format('svg');
}
.fa,
.fas {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
}
/* **************** INCLUDES ****************** */
/* loader start */
@-webkit-keyframes ball-grid-pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0.7;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes ball-grid-pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0.7;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
/* loader end */
/* **************** VARIABLES ****************** */
.Lato {
  font-family: 'Lato', sans-serif;
}
.FuturaM {
  font-family: 'FuturaBT-Medium', sans-serif;
  font-weight: normal;
}
.color-primary {
  color: #44286c;
}
.hidden {
  opacity: 0;
}
.visible {
  opacity: 1;
}
.full {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.text-underline {
  text-decoration: underline;
}
.clear {
  clear: both;
  *zoom: 1;
  height: 0;
  visibility: hidden;
  display: block;
  content: "";
}
.inline-b {
  display: inline-block;
  vertical-align: top;
}
.vam {
  vertical-align: middle;
}
.vat {
  vertical-align: top;
}
.vab {
  vertical-align: bottom;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}
/* For modern browsers */
.clearfix:before,
.clearfix:after,
.container:before,
.container:after,
.row:before,
.row:after {
  content: "";
  display: table;
}
.clearfix:after,
.container:after,
.row:after {
  clear: both;
}
/* for IE6-7 */
.clearfix,
.container,
.row {
  zoom: 1;
  position: relative;
}
.after,
.before {
  content: '';
  display: block;
}
.fll {
  float: left;
}
.flr {
  float: right;
}
.fln {
  float: none;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.ttu {
  text-transform: uppercase;
}
.bd100 {
  border-radius: 100%;
}
.w100p {
  width: 100%;
}
.w50p {
  width: 50%;
}
.w250 {
  width: 15.625rem;
}
.pos-center {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.pos-centerY {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.pos-centerX {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}
.pos-center-none {
  position: static;
  top: auto;
  left: auto;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}
.row:before,
.row:after {
  display: none !important;
}
* {
  margin: 0;
  padding: 0;
}
html,
body {
  color: #0e0e0e;
  min-height: 100vh;
  background: #ffffff;
  font-family: 'Lato', sans-serif;
  line-height: 1.1;
}
@media (min-width: 1441px) {
  html,
  body {
    font-size: 16px;
  }
}
@media (max-width: 1440px) {
  html,
  body {
    font-size: 14px;
  }
}
@media (max-width: 1280px) {
  html,
  body {
    font-size: 12px;
  }
}
@media (max-width: 1024px) {
  html,
  body {
    font-size: 13px;
  }
}
img {
  vertical-align: top;
}
a {
  color: #44286c;
  text-decoration: none;
}
a:hover {
  color: #44286c;
  text-decoration: underline;
}
.overlay {
  padding-top: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 10;
  display: none;
}
.overlay:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}
.overlay.show {
  display: block;
}
@media only screen and (max-width: 767.98px) {
  .no-scroll {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}
.container {
  max-width: 75rem;
  margin: 0 auto;
  padding: 0;
}
@media only screen and (min-width: 767.99px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .container {
    max-width: 90%;
  }
}
.container-sm {
  max-width: 63.125rem;
  margin: 0 auto;
}
@media only screen and (min-width: 767.99px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .container-sm {
    max-width: 90%;
  }
}
.container-xs {
  max-width: 59rem;
  margin: 0 auto;
}
@media only screen and (min-width: 767.99px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .container-xs {
    max-width: 90%;
  }
}
.container-big {
  max-width: 91rem;
  margin: 0 auto;
}
@media only screen and (min-width: 1199.99px) and (max-width: 1366px), only screen and (min-width: 767.99px) and (max-width: 1199.98px), only screen and (max-width: 767.98px) {
  .container-big {
    max-width: 90%;
  }
}
.btn {
  display: inline-block;
  vertical-align: top;
  line-height: 3rem;
  text-transform: uppercase;
  background: #44286c;
  color: #ffffff;
  font-size: 1rem;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  border: 1px solid #44286c;
  cursor: pointer;
  border-radius: 3px;
  padding: 0rem 1.875rem 0rem 1.875rem;
  -webkit-transition: all 0.1s ease;
  -moz-transition: all 0.1s ease;
  -ms-transition: all 0.1s ease;
  -o-transition: all 0.1s ease;
  transition: all 0.1s ease;
  outline: 0;
  white-space: nowrap;
}
.btn:hover {
  border-color: #44286c;
  background: rgba(68, 40, 108, 0.9);
  color: #ffffff;
  text-decoration: none;
}
.btn:focus {
  box-shadow: none;
  color: #ffffff;
}
.btn.btn-secondary {
  background: #36af50;
  color: #ffffff;
  border-color: #36af50;
}
.btn.btn-secondary:hover {
  background: rgba(54, 175, 80, 0.9);
}
.btn.btn-secondary:focus {
  background: rgba(54, 175, 80, 0.9);
}
.btn.btn-white {
  background: #ffffff;
  color: #44286c;
  border-color: #ffffff;
}
.btn.btn-white:hover {
  background: rgba(255, 255, 255, 0.9);
}
.btn.btn-white:focus,
.btn.btn-white:active {
  background: rgba(255, 255, 255, 0.9);
}
.btn.btn-white {
  background: #ffffff;
  color: #44286c;
  border-color: #ffffff;
}
.btn.btn-white:hover {
  background: rgba(255, 255, 255, 0.9);
}
.btn.btn-white:focus,
.btn.btn-white:active {
  background: rgba(255, 255, 255, 0.9);
}
.btn.btn-transparent {
  background: transparent;
  color: #0e0e0e;
  border-color: transparent;
  padding: 0rem 0rem 0rem 0.625rem;
  line-height: 1rem;
}
.btn.btn-transparent:hover {
  color: #44286c;
}
.btn.btn-transparent:focus,
.btn.btn-transparent:active {
  color: #44286c;
}
.btn.btn-outline-primary {
  background: none;
  color: #44286c;
  border-color: #44286c;
}
.btn.btn-outline-primary:hover {
  background: rgba(68, 40, 108, 0.1);
}
.btn.btn-outline-primary:focus,
.btn.btn-outline-primary:active {
  background: rgba(68, 40, 108, 0.1);
}
.btn.btn-outline-secondary {
  background: none;
  color: #36af50;
  border-color: #36af50;
}
.btn.btn-outline-secondary:hover {
  background: rgba(54, 175, 80, 0.1);
}
.btn.btn-outline-secondary:focus,
.btn.btn-outline-secondary:active {
  background: rgba(54, 175, 80, 0.1);
}
.btn.screen-images-btn {
  background: none;
  color: #36af50;
  border: none;
  text-decoration: underline;
}
.btn.screen-images-btn:hover {
  background: rgba(54, 175, 80, 0.02);
}
.btn.screen-images-btn:focus,
.btn.screen-images-btn:active {
  background: rgba(54, 175, 80, 0.02);
}
.btn.btn-full {
  width: 100%;
}
/* section-bottom-line start */
.section-bottom-line {
  background: #36af50 url(../img/section-bottom-line.png) no-repeat 100% 50%;
  background-size: auto 100%;
  color: #ffffff;
}
.section-bottom-line .section-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  height: 10.75rem;
}
@media only screen and (max-width: 575.98px) {
  .section-bottom-line .section-text {
    height: 10rem;
  }
}
@media only screen and (max-width: 375.98px) {
  .section-bottom-line .section-text {
    flex-direction: column;
    padding-top: 2rem;
    padding-bottom: 2rem;
    height: auto;
  }
}
.section-bottom-line .section-text.section-text-center {
  justify-content: center;
}
.section-bottom-line .section-text .text {
  color: #ffffff;
  font-weight: 700;
  font-size: 2.25rem;
  margin: 0rem 2.8125rem 0rem 0rem;
}
@media only screen and (max-width: 575.98px) {
  .section-bottom-line .section-text .text {
    font-size: 1.875rem;
  }
}
@media only screen and (max-width: 375.98px) {
  .section-bottom-line .section-text .text {
    margin-bottom: 2rem;
  }
}
.section-bottom-line .section-text .btn-wrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media only screen and (max-width: 575.98px) {
  .section-bottom-line .section-text .btn-wrap {
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 375.98px) {
  .section-bottom-line .section-text .btn-wrap {
    width: 100%;
    justify-content: space-between;
    margin-top: 1rem;
  }
}
.section-bottom-line .section-text .btn-wrap .btn {
  min-width: 15.625rem;
  margin-left: 2.5rem;
}
.section-bottom-line .section-text .btn-wrap .btn:first-child {
  margin-left: 0;
}
@media only screen and (min-width: 767.99px) and (max-width: 1199.98px), only screen and (max-width: 767.98px) {
  .section-bottom-line .section-text .btn-wrap .btn {
    min-width: auto;
  }
}
@media only screen and (max-width: 600px) {
  .section-bottom-line .section-text .btn-wrap .btn {
    min-width: auto;
    margin-bottom: 1rem;
    margin-left: 0;
    font-size: 0.75rem;
    line-height: 2.5rem;
  }
}
/* section-bottom-line start */
/* page-title start */
.page-title {
  height: 30rem;
  overflow: hidden;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0% 100%;
}
@media only screen and (min-width: 767.99px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .page-title {
    height: 23rem;
  }
}
@media only screen and (max-width: 575.98px) {
  .page-title {
    height: 17rem;
  }
}
.page-title:before {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(68, 40, 108, 0.4);
}
.page-title.bg-center {
  background-position: 100% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.page-title .text {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  color: #ffffff;
  padding: 5rem 0rem 0rem 0rem;
  font-size: 1.5625rem;
  line-height: 2.1875rem;
  z-index: 5;
}
@media only screen and (max-width: 575.98px) {
  .page-title .text {
    font-size: 1.125rem;
    line-height: 1.5625rem;
  }
}
.page-title .text .header-text-wrap {
  display: flex;
  justify-content: flex-start;
}
@media only screen and (min-width: 767.99px) and (max-width: 1199.98px), only screen and (max-width: 767.98px) {
  .page-title .text .header-text-wrap {
    justify-content: center;
  }
}
.page-title .text h1 {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 3.75rem;
  line-height: 4rem;
  letter-spacing: 0.02em;
  margin: 0rem 0rem 1.25rem 0rem;
}
@media only screen and (max-width: 575.98px) {
  .page-title .text h1 {
    font-size: 2.5rem;
    line-height: 2.75rem;
  }
}
.page-title .text p {
  margin: 0;
}
/* page-title end */
/* form-holder start */
.form-holder .form-group {
  margin: 0rem 0rem 2.5rem 0rem;
  position: relative;
}
.form-holder .form-control,
.form-holder .custom-select {
  background: none;
  border: 0;
  border-bottom: 1px solid #d1d1d1;
  border-radius: 0;
  padding: 0;
  height: 1.875rem;
  color: #0e0e0e;
  font-size: 1rem;
}
.form-holder .form-control::-moz-placeholder,
.form-holder .custom-select::-moz-placeholder {
  color: #949494;
  opacity: 1;
}
.form-holder .form-control:-moz-placeholder,
.form-holder .custom-select:-moz-placeholder {
  color: #949494;
}
.form-holder .form-control:-ms-input-placeholder,
.form-holder .custom-select:-ms-input-placeholder {
  color: #949494;
}
.form-holder .form-control::-webkit-input-placeholder,
.form-holder .custom-select::-webkit-input-placeholder {
  color: #949494;
}
.form-holder .form-control:focus,
.form-holder .custom-select:focus {
  outline: none;
  box-shadow: none;
  border-bottom-color: #44286c;
}
.form-holder textarea.form-control {
  height: 6.875rem;
  resize: none;
}
.form-holder .custom-select {
  background: #ffffff url(../img/arrow-down.png) no-repeat right center;
  background-size: 0.75rem 0.5rem;
}
.form-holder .form-check {
  padding: 0;
}
.form-holder .form-check .form-check-input-checkbox {
  width: 1.25rem;
  height: 1.25rem;
  position: absolute;
  z-index: 3;
  opacity: 0;
  left: 0;
  top: 0;
  display: inline-block;
  vertical-align: top;
}
.form-holder .form-check .form-check-input-checkbox + .form-check-label {
  display: inline-block;
  vertical-align: top;
  padding: 0rem 0rem 0rem 2.375rem;
  position: relative;
  font-size: 1rem;
  line-height: 1.25rem;
  min-height: 1rem;
  color: #0e0e0e;
  white-space: nowrap;
}
.form-holder .form-check .form-check-input-checkbox + .form-check-label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 1.25rem;
  height: 1.25rem;
  border: 0.063rem solid #d1d1d1;
  border-radius: 0.188rem;
}
.form-holder .form-check .form-check-input-checkbox + .form-check-label:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 1.25rem;
  height: 1.25rem;
  background: url(../img/check.png) no-repeat;
  background-size: contain;
  opacity: 0;
}
.form-holder .form-check .form-check-input-checkbox:checked + .form-check-label:before {
  background: #36af50;
  border-color: #36af50;
}
.form-holder .form-check .form-check-input-checkbox:checked + .form-check-label:after {
  opacity: 1;
}
.form-holder .form-check .form-check-input-checkbox:focus + .form-check-label:before {
  border-color: #36af50;
}
.form-holder .form-check .form-check-input-radio {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  z-index: 3;
  opacity: 0;
  left: 0;
  top: 0;
  display: inline-block;
  vertical-align: top;
}
.form-holder .form-check .form-check-input-radio + .form-check-label {
  display: inline-block;
  vertical-align: top;
  padding: 0rem 0rem 0rem 2.6875rem;
  position: relative;
  font-size: 0.9375rem;
  line-height: 1.5rem;
  min-height: 1rem;
  color: #0e0e0e;
  white-space: nowrap;
}
.form-holder .form-check .form-check-input-radio + .form-check-label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
  background: #ffffff;
  width: 1.5rem;
  height: 1.5rem;
  border: 0.063rem solid #dfe2e5;
}
.form-holder .form-check .form-check-input-radio + .form-check-label:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 1.5rem;
  height: 1.5rem;
  opacity: 0;
  background: url(../img/check-dark.png) no-repeat;
  background-size: contain;
}
.form-holder .form-check .form-check-input-radio:checked + .form-check-label:before {
  background: #c4d3e4;
  border-color: #c4d3e4;
}
.form-holder .form-check .form-check-input-radio:checked + .form-check-label:after {
  opacity: 1;
}
.form-holder .form-check .form-check-input-radio:focus + .form-check-label:before {
  border-color: #c4d3e4;
}
/* form-holder end */
.section-page {
  background: url(../img/top-decoration.png) no-repeat 50% 0;
  background-size: 100% auto;
}
.section-page .section-inner-page {
  background-size: 100% auto;
  padding: 8.125rem 0rem 6.5625rem 0rem;
  position: relative;
  font-size: 1.0625rem;
  line-height: 2.125rem;
  color: #0e0e0e;
}
@media only screen and (max-width: 767.98px) {
  .section-page .section-inner-page {
    padding: 3.5rem 0rem 3.4375rem 0rem;
  }
}
.section-page h2 {
  color: #44286c;
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 2.875rem;
  margin: 0rem 0rem 0.9375rem 0rem;
}
.section-page p {
  margin: 0rem 0rem 2rem 0rem;
}
/* loader start */
.loader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.3);
}
.ball-grid-pulse {
  width: 3.5625rem;
}
.ball-grid-pulse > div:nth-child(1) {
  -webkit-animation-delay: 0.22s;
  animation-delay: 0.22s;
  -webkit-animation-duration: 0.9s;
  animation-duration: 0.9s;
}
.ball-grid-pulse > div:nth-child(2) {
  -webkit-animation-delay: 0.64s;
  animation-delay: 0.64s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}
.ball-grid-pulse > div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
  animation-delay: -0.15s;
  -webkit-animation-duration: 0.63s;
  animation-duration: 0.63s;
}
.ball-grid-pulse > div:nth-child(4) {
  -webkit-animation-delay: -0.03s;
  animation-delay: -0.03s;
  -webkit-animation-duration: 1.24s;
  animation-duration: 1.24s;
}
.ball-grid-pulse > div:nth-child(5) {
  -webkit-animation-delay: 0.08s;
  animation-delay: 0.08s;
  -webkit-animation-duration: 1.37s;
  animation-duration: 1.37s;
}
.ball-grid-pulse > div:nth-child(6) {
  -webkit-animation-delay: 0.43s;
  animation-delay: 0.43s;
  -webkit-animation-duration: 1.55s;
  animation-duration: 1.55s;
}
.ball-grid-pulse > div:nth-child(7) {
  -webkit-animation-delay: 0.05s;
  animation-delay: 0.05s;
  -webkit-animation-duration: 0.7s;
  animation-duration: 0.7s;
}
.ball-grid-pulse > div:nth-child(8) {
  -webkit-animation-delay: 0.05s;
  animation-delay: 0.05s;
  -webkit-animation-duration: 0.97s;
  animation-duration: 0.97s;
}
.ball-grid-pulse > div:nth-child(9) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
  -webkit-animation-duration: 0.63s;
  animation-duration: 0.63s;
}
.ball-grid-pulse > div {
  background-color: #44286c;
  width: 0.9375rem;
  height: 0.9375rem;
  border-radius: 100%;
  margin: 0.125rem 0.125rem 0.125rem 0.125rem;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  display: inline-block;
  float: left;
  -webkit-animation-name: ball-grid-pulse;
  animation-name: ball-grid-pulse;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-delay: 0;
  animation-delay: 0;
}
/* loader end */
@media only screen and (min-width: 767.99px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .wow {
    visibility: visible !important;
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
  }
}
.form-group {
  margin-bottom: 2.5rem;
  min-height: 2.5rem;
  position: relative;
}
.form-group .form-control,
.form-group .custom-select {
  height: 2.5rem;
  background: #ffffff;
  border: 0;
  border-bottom: 2px solid #e6e6e6;
  border-radius: 0;
  padding: 0  1rem 0 0;
  color: #0e0e0e;
  font-size: 1rem;
  transition: all 0.3s linear;
  -webkit-appearance: none;
}
@media only screen and (max-width: 767.98px) {
  .form-group .form-control,
  .form-group .custom-select {
    font-size: 1rem;
  }
}
.form-group .form-control:focus,
.form-group .custom-select:focus {
  border: none;
  border-bottom: 2px solid #44286c;
  box-shadow: none;
  color: #252b33;
}
.form-group .form-control:active,
.form-group .custom-select:active {
  border: none;
  border-bottom: 2px solid #44286c;
  box-shadow: none;
  color: #252b33;
}
.form-group .form-control::placeholder,
.form-group .custom-select::placeholder {
  font-size: 1rem;
  color: #949494;
  font-family: 'Lato', sans-serif;
  transition: 0.3s ease linear;
}
.form-group .form-control.form-control-s,
.form-group .custom-select.form-control-s {
  font-size: 1rem;
}
.form-group .view-pass {
  position: absolute;
  bottom: 0.875rem;
  right: 0.8rem;
  color: #5c5c5c;
  line-height: 1rem;
}
.form-group .view-pass:hover {
  cursor: pointer;
  color: #44286c;
}
.form-group .view-pass i {
  font-size: 1rem;
}
.form-group .view-pass .fa-eye-slash {
  display: block;
}
.form-group .view-pass .fa-eye {
  display: none;
}
.form-group .view-pass.active .fa-eye-slash {
  display: none;
}
.form-group .view-pass.active .fa-eye {
  display: block;
}
.form-group .custom-select {
  background: #ffffff url(../img/svg/chevron-down-solid.svg) no-repeat right 0.75rem center;
  background-size: 0.75rem 0.5rem;
}
@media only screen and (min-width: 1199.99px) and (max-width: 1366px) {
  .form-group .custom-select {
    background-position: right 0.5rem center;
    padding-right: 0.5rem;
  }
}
.form-group textarea.form-control {
  padding-top: 1rem;
  resize: none;
  height: 11.5625rem;
  position: relative;
  padding-bottom: 1.5rem;
}
.form-group .floating-label {
  position: absolute;
  opacity: 0;
  transition: all 0.3s ease;
  top: -0.5rem;
  left: 0;
  color: #949494;
  font-family: 'Lato', sans-serif;
  font-size: 0.75rem;
}
.form-group input:not(:placeholder-shown) + .floating-label {
  transform: translateY(-10px);
  opacity: 1;
}
.form-group textarea:not(:placeholder-shown) + .floating-label {
  transform: translateY(-10px);
  opacity: 1;
}
.form-check {
  padding: 0;
  display: inline-block;
  vertical-align: top;
  margin-right: 1rem;
  position: relative;
}
@media only screen and (min-width: 767.99px) and (max-width: 1199.98px), only screen and (max-width: 767.98px) {
  .form-check {
    display: block;
  }
}
.form-check .form-check-input-checkbox {
  width: 1.375rem;
  height: 1.375rem;
  margin: 0 -1.5rem 0 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
  opacity: 0;
}
.form-check .form-check-input-checkbox + .form-check-label {
  display: inline-block;
  vertical-align: top;
  padding: 0rem 0rem 0rem 3rem;
  position: relative;
  font-size: 1rem;
  line-height: 1.375rem;
  min-height: 1rem;
  color: #000;
  font-family: 'Lato', sans-serif;
}
.form-check .form-check-input-checkbox + .form-check-label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 1.375rem;
  height: 1.375rem;
  border: 2px solid #e6e6e6;
  background: #fff;
  border-radius: 0;
}
.form-check .form-check-input-checkbox + .form-check-label:after {
  content: '\f00c';
  position: absolute;
  top: 0;
  left: 0;
  width: 1.375rem;
  height: 1.375rem;
  line-height: 1.375rem;
  text-align: center;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  opacity: 0;
  color: #ffffff;
}
.form-check .form-check-input-checkbox:checked + .form-check-label:before {
  background: #36af50;
  border: 2px solid #36af50;
}
.form-check .form-check-input-checkbox:checked + .form-check-label::after {
  opacity: 1;
}
.form-check .form-check-input-radio {
  width: 1.375rem;
  height: 1.375rem;
  margin: 0 -1rem 0 0;
  position: absolute;
  z-index: 3;
  opacity: 0;
  display: inline-block;
  vertical-align: top;
}
.form-check .form-check-input-radio + .form-check-label {
  display: inline-block;
  vertical-align: top;
  padding: 0 0 0 2rem;
  position: relative;
  font-size: 1rem;
  color: #252b33;
  font-family: "Open Sans";
  min-height: 1rem;
  white-space: nowrap;
}
.form-check .form-check-input-radio + .form-check-label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 1.375rem;
  height: 1.375rem;
  border-radius: 50%;
  border: 1px solid #d0d0d0;
  background-color: #ffffff;
}
.form-check .form-check-input-radio + .form-check-label:after {
  content: '\f00c';
  position: absolute;
  top: 0;
  left: 0;
  width: 1.375rem;
  height: 1.375rem;
  line-height: 1.375rem;
  text-align: center;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  opacity: 0;
  font-size: 0.875rem;
  color: #ffffff;
}
.form-check .form-check-input-radio:checked + .form-check-label:before {
  background: #44286c;
  border-color: #44286c;
}
.form-check .form-check-input-radio:checked + .form-check-label:after {
  opacity: 1;
}
.lbl {
  color: #191919;
  font-family: 'Lato', sans-serif;
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 0.2rem;
  line-height: 1.5rem;
}
.lbl:focus {
  outline: none;
}
h2 {
  font-family: 'Lato', sans-serif;
  font-size: 2.25rem;
  line-height: 3.125rem;
  font-weight: 700;
  color: #44286c;
  margin-bottom: 2.5rem;
}
h2:focus {
  outline: none;
}
p {
  font-size: 1.125rem;
  font-family: 'Lato', sans-serif;
  line-height: 1.875rem;
  color: #0e0e0e;
}
p:focus {
  outline: none;
}
@keyframes pulse-round {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1);
  }
  90% {
    transform: scale(1.075);
  }
  100% {
    transform: scale(1);
  }
}
.pulse-round {
  animation: pulse-round 4.5s infinite ease-in-out;
}
@keyframes circle-rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
h2.logo {
  text-align: center;
  margin-bottom: 2rem;
  margin-top: -2rem;
}
@media only screen and (min-width: 767.99px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  h2.logo {
    margin-top: 0;
  }
}
h2.logo img {
  text-align: center;
  height: 4rem;
  width: auto;
}
@keyframes pulse-icon {
  0%,
  100% {
    transform: rotate(-10deg);
  }
  50% {
    transform: scale(1.1);
  }
}
@keyframes pulse-icon-enlarge {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.15);
  }
}
a:focus,
p:focus,
div:focus,
h1:focus,
h2:focus,
h3:focus,
h4:focus,
h5:focus {
  outline: none;
}
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #44286c;
  background-color: transparent;
  border-color: #44286c;
}
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: #ffffff;
  background-color: #36af50;
  border-color: #36af50;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #36af50;
  background-color: transparent;
  border-color: #36af50;
}
/* **************** PLUGINS ****************** */
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
/* **************** PAGE COMPONENTS ****************** */
.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1000;
  background: #ffffff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 3.125rem 0rem 3.125rem;
  height: 5rem;
  box-shadow: 0 0 0.375rem rgba(0, 0, 0, 0.33);
}
@media only screen and (min-width: 767.99px) and (max-width: 1199.98px) {
  .header {
    padding: 0 2rem;
  }
}
@media only screen and (max-width: 767.98px) {
  .header {
    padding: 0 1rem 0 1.5rem;
  }
}
.header .logo {
  width: 13.5rem;
  flex-shrink: 0;
}
@media only screen and (min-width: 767.99px) and (max-width: 1199.98px), only screen and (max-width: 767.98px) {
  .header .logo {
    margin-left: 2rem;
  }
}
@media only screen and (max-width: 575.98px) {
  .header .logo {
    margin-left: 1.5rem;
    width: 11.25rem;
  }
}
.header .logo img {
  max-width: 100%;
  height: auto;
}
.header .header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
@media only screen and (min-width: 767.99px) and (max-width: 1199.98px), only screen and (max-width: 767.98px) {
  .header .header-inner {
    justify-content: flex-end;
  }
}
.header .nav-holder {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
@media only screen and (min-width: 767.99px) and (max-width: 1199.98px), only screen and (max-width: 767.98px) {
  .header .nav-holder {
    position: fixed;
    left: -100%;
    top: 0;
    height: 100%;
    margin: 0;
    width: 30rem;
    padding: 5rem 0rem 0rem 0rem;
    background: #44286c;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  .header .nav-holder.show {
    left: 0;
    box-shadow: 5px 0 10px rgba(0, 0, 0, 0.3);
  }
}
@media only screen and (max-width: 767.98px) {
  .header .nav-holder {
    width: 100%;
  }
}
.header .nav-holder .nav-logo {
  display: none;
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 9rem;
}
@media only screen and (min-width: 767.99px) and (max-width: 1199.98px), only screen and (max-width: 767.98px) {
  .header .nav-holder .nav-logo {
    display: block;
  }
}
.header .nav-holder > ul {
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
  margin: 0;
}
@media only screen and (min-width: 767.99px) and (max-width: 1199.98px), only screen and (max-width: 767.98px) {
  .header .nav-holder > ul {
    height: 100%;
    width: 100%;
    overflow: auto;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    display: block;
  }
}
.header .nav-holder > ul > li {
  margin: 0rem 1.875rem 0rem 1.875rem;
  position: relative;
}
@media only screen and (min-width: 767.99px) and (max-width: 1199.98px), only screen and (max-width: 767.98px) {
  .header .nav-holder > ul > li {
    margin: 0;
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
  .header .nav-holder > ul > li:last-child {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
}
.header .nav-holder > ul > li:hover .dropdown {
  display: block;
}
@media only screen and (min-width: 767.99px) and (max-width: 1199.98px), only screen and (max-width: 767.98px) {
  .header .nav-holder > ul > li.img-link {
    margin: 0;
  }
}
.header .nav-holder > ul > li.img-link .nav-img {
  display: none;
}
@media only screen and (min-width: 767.99px) and (max-width: 1199.98px), only screen and (max-width: 767.98px) {
  .header .nav-holder > ul > li.img-link .nav-img {
    display: block;
  }
}
.header .nav-holder > ul > li.img-link .reg-img {
  display: block;
}
@media only screen and (min-width: 767.99px) and (max-width: 1199.98px), only screen and (max-width: 767.98px) {
  .header .nav-holder > ul > li.img-link .reg-img {
    display: none;
  }
}
.header .nav-holder > ul > li > a {
  display: block;
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #000000;
  font-weight: 700;
}
@media only screen and (min-width: 767.99px) and (max-width: 1199.98px), only screen and (max-width: 767.98px) {
  .header .nav-holder > ul > li > a {
    display: block;
    padding: 1.5rem 2rem;
    text-align: left;
    color: #ffffff;
  }
}
.header .nav-holder > ul > li > a:hover {
  text-decoration: none;
  color: #4e3373;
}
.header .nav-holder > ul > li > a i {
  margin: 0rem 0rem 0rem 0.1875rem;
}
.header .nav-holder > ul > li > a img {
  width: 5.9375rem;
  height: auto;
}
.header .nav-holder > ul > li.active a {
  text-decoration: none;
  color: #36af50;
}
.header .nav-holder > ul > li .dropdown {
  position: absolute;
  width: 15.625rem;
  left: -2rem;
  top: 1.125rem;
  padding: 2rem 0rem 0rem 0rem;
  display: none;
}
@media only screen and (min-width: 767.99px) and (max-width: 1199.98px), only screen and (max-width: 767.98px) {
  .header .nav-holder > ul > li .dropdown {
    display: block;
    position: static;
    padding: 0;
    width: auto;
  }
}
.header .nav-holder > ul > li .dropdown > ul {
  list-style: none;
  margin: 0;
  background: #36af50 url(../img/shadow.png) repeat-x;
  background-size: auto 0.375rem;
  padding: 0rem 0rem 0rem 0rem;
  border-radius: 0 0 0.313rem 0.375rem;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
}
@media only screen and (min-width: 767.99px) and (max-width: 1199.98px), only screen and (max-width: 767.98px) {
  .header .nav-holder > ul > li .dropdown > ul {
    background: none;
    border-radius: 0;
    padding: 0 0 0 2rem;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
}
.header .nav-holder > ul > li .dropdown > ul > li {
  margin: 0;
  padding: 0.875rem 1rem 0.625rem 2rem;
}
@media only screen and (min-width: 767.99px) and (max-width: 1199.98px), only screen and (max-width: 767.98px) {
  .header .nav-holder > ul > li .dropdown > ul > li {
    margin: 0;
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .header .nav-holder > ul > li .dropdown > ul > li:last-child {
    border-bottom: 0;
  }
}
.header .nav-holder > ul > li .dropdown > ul > li:last-child {
  border-radius: 0 0 0.313rem 0.375rem;
}
.header .nav-holder > ul > li .dropdown > ul > li:hover {
  background-color: #44286c;
  cursor: pointer;
}
.header .nav-holder > ul > li .dropdown > ul > li:hover > a {
  color: #ffffff;
}
.header .nav-holder > ul > li .dropdown > ul > li.active {
  background-color: #44286c;
}
.header .nav-holder > ul > li .dropdown > ul > li.active > a {
  color: #ffffff;
}
.header .nav-holder > ul > li .dropdown > ul > li > a {
  color: #ffffff;
  display: inline-block;
  vertical-align: top;
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 0.3125rem 0rem 0.3125rem 0rem;
  font-weight: 700;
}
.header .nav-holder > ul > li .dropdown > ul > li > a:hover {
  text-decoration: none;
}
@media only screen and (min-width: 767.99px) and (max-width: 1199.98px), only screen and (max-width: 767.98px) {
  .header .nav-holder > ul > li .dropdown > ul > li > a {
    display: block;
    padding: 1.5rem 1rem;
    text-align: left;
    color: #ffffff;
  }
}
.header .nav-holder > ul > li .dropdown > ul > li > ul {
  list-style: none;
  margin: 0;
  padding: 0rem 0rem 0rem 1rem;
}
@media only screen and (min-width: 767.99px) and (max-width: 1199.98px), only screen and (max-width: 767.98px) {
  .header .nav-holder > ul > li .dropdown > ul > li > ul {
    padding: 0 0 0 2rem;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
}
.header .nav-holder > ul > li .dropdown > ul > li > ul > li {
  margin: 0;
}
@media only screen and (min-width: 767.99px) and (max-width: 1199.98px), only screen and (max-width: 767.98px) {
  .header .nav-holder > ul > li .dropdown > ul > li > ul > li {
    margin: 0;
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
  .header .nav-holder > ul > li .dropdown > ul > li > ul > li:first-child {
    border-top: 0;
  }
}
.header .nav-holder > ul > li .dropdown > ul > li > ul > li.active > a {
  color: #36af50;
}
.header .nav-holder > ul > li .dropdown > ul > li > ul > li > a {
  color: #0e0e0e;
  display: inline-block;
  vertical-align: top;
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 0.3125rem 0rem 0.3125rem 0rem;
}
.header .nav-holder > ul > li .dropdown > ul > li > ul > li > a:hover {
  text-decoration: none;
  color: #36af50;
}
@media only screen and (min-width: 767.99px) and (max-width: 1199.98px), only screen and (max-width: 767.98px) {
  .header .nav-holder > ul > li .dropdown > ul > li > ul > li > a {
    display: block;
    padding: 1.5rem 1rem;
    text-align: left;
    color: #ffffff;
  }
}
.header .sep {
  width: 1px;
  height: 2.25rem;
  background-color: #ececec;
  margin: 0rem 1.4375rem 0rem 1.4375rem;
}
@media only screen and (max-width: 575.98px) {
  .header .sep {
    display: none;
  }
}
.header .phone {
  font-size: 1rem;
  color: #000000;
  white-space: nowrap;
}
.header .phone:hover {
  text-decoration: none;
  color: #36af50;
}
@media only screen and (max-width: 575.98px) {
  .header .phone {
    border-radius: 50%;
    background: #36af50;
    color: #ffffff;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.header .phone i {
  color: #36af50;
  font-size: 1rem;
  margin: 0rem 0.625rem 0rem 0rem;
}
@media only screen and (max-width: 575.98px) {
  .header .phone i {
    margin: 0;
    color: #ffffff;
  }
}
.header .phone span {
  font-weight: 700;
}
@media only screen and (max-width: 575.98px) {
  .header .phone span {
    display: none;
  }
}
.header .buttons {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0rem 0rem 0rem 1.1875rem;
}
@media only screen and (max-width: 575.98px) {
  .header .buttons {
    margin: 0;
    padding: 1rem;
    justify-content: space-between;
    width: 100%;
  }
}
.header .buttons .btn {
  margin: 0rem 0rem 0rem 0.9375rem;
  line-height: 2.625rem;
}
@media only screen and (max-width: 575.98px) {
  .header .buttons .btn {
    width: 48%;
    margin: 0;
  }
}
/*hamburger btn*/
.btn-lines,
.c-hamburger span::before,
.c-hamburger span::after {
  position: absolute;
  display: block;
  left: 0;
  width: 100%;
  height: 0.125rem;
  background-color: #44286c;
  content: "";
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.c-hamburger {
  display: none;
  position: relative;
  z-index: 10;
  margin: 0;
  padding: 0;
  width: 1.625rem;
  height: 1.25rem;
  flex-shrink: 0;
  font-size: 0;
  text-indent: -9999px;
  appearance: none;
  border: none;
  cursor: pointer;
  -webkit-transition: all 0.3s ease 0.3s;
  transition: all 0.3s ease 0.3s;
}
.c-hamburger:focus {
  outline: none;
}
@media only screen and (min-width: 767.99px) and (max-width: 1199.98px), only screen and (max-width: 767.98px) {
  .c-hamburger {
    display: block;
  }
}
.c-hamburger span {
  display: block;
  position: absolute;
  top: 0.5625rem;
  left: 0;
  right: 0;
  height: 0.125rem;
  background: #44286c;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.c-hamburger span::before {
  top: -0.4375rem;
}
.c-hamburger span::after {
  bottom: -0.4375rem;
}
.c-hamburger--htx {
  background-color: transparent;
}
.c-hamburger--htx.active:before {
  display: none;
}
.c-hamburger--htx.active span {
  background: none;
}
.c-hamburger--htx.active span::before {
  top: 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  background: #ffffff;
}
.c-hamburger--htx.active span::after {
  bottom: 0;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  background: #ffffff;
}
/*hamburger btn*/
.footer {
  background: #44286c;
  color: #ffffff;
  font-size: 0.9375rem;
  line-height: 1.5625rem;
}
.footer a {
  color: #ffffff;
}
.footer p {
  margin: 0rem 0rem 0.625rem 0rem;
  color: #ffffff;
}
.footer .social {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  z-index: 5;
}
@media only screen and (max-width: 767.98px) {
  .footer .social {
    justify-content: center;
    margin: 0rem 0rem 2.5rem 0rem;
  }
}
.footer .social a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 50%;
  color: #44286c;
  background: #ffffff;
  margin: 0rem 0.75rem 0rem 0rem;
}
.footer .social a:hover {
  text-decoration: none;
  color: #ffffff;
  background: #36af50;
}
.footer .social a i {
  font-size: 0.97625rem;
}
.footer .logo {
  margin: 0rem 0rem 2.1875rem 0rem;
}
@media only screen and (max-width: 767.98px) {
  .footer .logo {
    text-align: center;
  }
}
.footer .logo img {
  width: 12rem;
  height: auto;
  display: block;
  margin: 0rem 0rem 1.25rem 0rem;
}
@media only screen and (max-width: 767.98px) {
  .footer .logo img {
    margin-left: auto;
    margin-right: auto;
  }
}
.footer .logo p {
  padding-right: 5rem;
}
@media only screen and (min-width: 767.99px) and (max-width: 1199.98px), only screen and (max-width: 767.98px) {
  .footer .logo p {
    padding: 0;
  }
}
.footer .logo strong {
  display: block;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 0.8125rem;
  line-height: 0.9375rem;
  font-weight: 400;
  letter-spacing: 0.1em;
}
.footer h5 {
  text-transform: uppercase;
  font-size: 0.9375rem;
  font-weight: 700;
  margin: 0.5rem 0rem 0.9375rem 0rem;
}
.footer .top-footer {
  padding: 1.875rem 0rem 0.625rem 0rem;
  position: relative;
  z-index: 0;
  overflow: hidden;
}
.footer .top-footer .sub-logo-img {
  position: absolute;
  top: -3rem;
  right: 16.5rem;
  width: 25rem;
  height: auto;
  z-index: 0;
}
@media only screen and (max-width: 1679px) {
  .footer .top-footer .sub-logo-img {
    right: 2rem;
  }
}
@media only screen and (min-width: 767.99px) and (max-width: 1199.98px) {
  .footer .top-footer .sub-logo-img {
    right: 0;
  }
}
@media only screen and (max-width: 767.98px) {
  .footer .top-footer .sub-logo-img {
    width: 15.625rem;
    top: unset;
    right: -3rem;
    bottom: -3rem;
  }
}
.footer .bottom-footer {
  background: #36af50;
  padding: 0.625rem 0rem 0.625rem 0rem;
  font-size: 0.875rem;
  color: #44286c;
}
@media only screen and (max-width: 575.98px) {
  .footer .bottom-footer {
    text-align: center;
  }
}
.footer .menu {
  list-style: none;
  margin: 0;
}
.footer .menu li a:hover {
  text-decoration: none;
  color: #36af50;
}
.footer .sub-menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  list-style: none;
  margin: 0;
}
@media only screen and (max-width: 575.98px) {
  .footer .sub-menu {
    justify-content: center;
    padding: 0 0 0.5rem;
  }
}
.footer .sub-menu li {
  padding: 0rem 0rem 0rem 1.875rem;
}
@media only screen and (max-width: 575.98px) {
  .footer .sub-menu li {
    padding: 0rem 0.9375rem 0rem 0.9375rem;
  }
}
.footer .sub-menu li a {
  color: #44286c;
}
.footer .sub-menu li a:hover {
  text-decoration: none;
  color: #ffffff;
}
.cookie-block {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.9);
  padding: 1rem 0rem 1rem 0rem;
  z-index: 999;
}
.cookie-block .inner {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 575.98px) {
  .cookie-block .inner {
    flex-direction: column;
  }
}
.cookie-block .inner .text {
  text-align: center;
  color: #ffffff;
  font-size: 1rem;
  line-height: 2rem;
  padding: 0rem 2.5rem 0rem 0rem;
}
@media only screen and (max-width: 575.98px) {
  .cookie-block .inner .text {
    font-size: 0.9375rem;
    line-height: 1.25rem;
    padding: 0rem 0rem 1rem 0rem;
  }
}
.cookie-block .inner .text h6 {
  font-size: 1.125rem;
  margin: 0rem 0rem 0.3125rem 0rem;
  font-weight: 700;
}
.cookie-block .inner .text p {
  margin: 0;
  color: #ffffff;
}
/* banner start */
.banner {
  position: relative;
  overflow: hidden;
  margin: 5rem 0rem 0rem 0rem;
}
.banner .video-holder {
  position: relative;
  width: 100%;
  height: 100%;
}
.banner .video-holder:before {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(68, 40, 108, 0.4);
  z-index: 5;
}
.banner .video-holder video {
  display: block;
  width: 100%;
  height: auto;
}
@media only screen and (max-width: 575.98px) {
  .banner .video-holder video {
    height: 20rem;
    width: auto;
  }
}
.banner .text {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 10;
}
.banner .text .line-1 {
  display: block;
  font-size: 3.75rem;
  line-height: 4.375rem;
  font-weight: 700;
  margin: 0rem 0rem 1.875rem 0rem;
}
@media only screen and (min-width: 767.99px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .banner .text .line-1 {
    font-size: 2.5rem;
    line-height: 3.125rem;
    margin: 0rem 0rem 1.25rem 0rem;
  }
}
@media only screen and (max-width: 575.98px) {
  .banner .text .line-1 {
    font-size: 1.875rem;
    line-height: 2.5rem;
    margin: 0rem 0rem 0.9375rem 0rem;
  }
}
.banner .text .line-2 {
  display: block;
  font-size: 1.5625rem;
  line-height: 1.875rem;
  font-weight: 700;
  margin: 0rem 0rem 3.125rem 0rem;
}
@media only screen and (min-width: 767.99px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .banner .text .line-2 {
    font-size: 1.25rem;
    line-height: 1.5625rem;
    margin: 0rem 0rem 1.875rem 0rem;
  }
}
@media only screen and (max-width: 575.98px) {
  .banner .text .line-2 {
    font-size: 1rem;
    line-height: 1.25rem;
    margin: 0rem 0rem 1.25rem 0rem;
  }
}
.banner .text .btn {
  padding: 0rem 2.5rem 0rem 2.5rem;
}
.banner .container {
  max-width: 51.125rem;
}
/* banner end */
/* section-testim start */
.section-testim {
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: 50% 0;
  padding: 5.625rem 0rem 6.25rem 0rem;
  position: relative;
  font-size: 1.125rem;
  line-height: 2.125rem;
  color: #ffffff;
  text-align: center;
}
@media only screen and (min-width: 767.99px) and (max-width: 1199.98px), only screen and (max-width: 767.98px) {
  .section-testim {
    background-attachment: scroll;
  }
}
@media only screen and (max-width: 575.98px) {
  .section-testim {
    padding: 4rem 0;
  }
}
.section-testim .container {
  position: relative;
  z-index: 3;
}
.section-testim h2 {
  color: #44286c;
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 2.875rem;
  margin: 0rem 0rem 0.9375rem 0rem;
}
.section-testim .view-all {
  text-transform: uppercase;
  display: inline-block;
  vertical-align: top;
  color: #36af50;
  font-size: 1rem;
  line-height: 1.625rem;
  font-weight: 700;
  border-bottom: 2px solid #36af50;
}
.section-testim .view-all:hover {
  color: #ffffff;
  text-decoration: none;
  border-color: #ffffff;
}
.section-testim .testim-slider {
  margin: 2.5rem 0rem 1.25rem 0rem;
}
@media only screen and (max-width: 575.98px) {
  .section-testim .testim-slider {
    margin: 1rem 0;
  }
}
.section-testim .testim-slider .item-inner {
  margin: 2.0625rem 2.8125rem 3.125rem 2.5rem;
  position: relative;
  background: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 1rem 1rem 2rem rgba(0, 0, 0, 0.33);
  font-size: 1.125rem;
  line-height: 1.875rem;
  color: #0e0e0e;
  padding: 2.5rem 1.875rem 1.375rem 3.75rem;
  text-align: left;
}
@media only screen and (max-width: 575.98px) {
  .section-testim .testim-slider .item-inner {
    box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.33);
    margin: 1.5rem;
    padding: 2.5rem 1.5rem 1.5rem 2.5rem;
  }
}
.section-testim .testim-slider .item-inner .quote {
  position: absolute;
  width: 5.4375rem;
  height: auto;
  left: -2.5rem;
  top: -2.0625rem;
}
@media only screen and (max-width: 575.98px) {
  .section-testim .testim-slider .item-inner .quote {
    top: -1.5rem;
    left: -1.5rem;
    width: 4.5rem;
  }
}
.section-testim .testim-slider .item-inner .text-scroll {
  height: 6.875rem;
  margin: 0rem 0rem 0rem 0rem;
}
@media only screen and (max-width: 767.98px) {
  .section-testim .testim-slider .item-inner .text-scroll {
    height: auto;
  }
}
.section-testim .testim-slider .item-inner .text-scroll p {
  margin: 0;
}
.section-testim .testim-slider .item-inner .author {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #0e0e0e;
  line-height: 1.375rem;
  min-height: 3.5rem;
}
.section-testim .testim-slider .item-inner .author img {
  width: auto;
  max-width: 5.625rem;
  height: auto;
  max-height: 5rem;
  flex-shrink: 0;
  margin: 0rem 0rem 0rem 0.625rem;
}
.section-testim .testim-slider .slick-prev,
.section-testim .testim-slider .slick-next {
  position: absolute;
  left: -2.1875rem;
  top: 8.125rem;
  font-size: 2.5rem;
  color: #44286c;
  cursor: pointer;
}
@media only screen and (min-width: 767.99px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .section-testim .testim-slider .slick-prev,
  .section-testim .testim-slider .slick-next {
    left: 0rem;
  }
}
@media only screen and (max-width: 575.98px) {
  .section-testim .testim-slider .slick-prev,
  .section-testim .testim-slider .slick-next {
    left: -0.5rem;
    font-size: 1.875rem;
  }
}
.section-testim .testim-slider .slick-prev:hover,
.section-testim .testim-slider .slick-next:hover {
  color: #36af50;
}
.section-testim .testim-slider .slick-next {
  left: auto;
  right: -2.1875rem;
}
@media only screen and (min-width: 767.99px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .section-testim .testim-slider .slick-next {
    right: 0rem;
  }
}
@media only screen and (max-width: 575.98px) {
  .section-testim .testim-slider .slick-next {
    right: -0.5rem;
  }
}
/* section-testim end */
.section-schemes {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-attachment: fixed;
  padding: 5.625rem 0rem 7.5rem 0rem;
  position: relative;
  font-size: 1.125rem;
  line-height: 2.125rem;
  color: #ffffff;
  text-align: center;
}
@media only screen and (min-width: 767.99px) and (max-width: 1199.98px), only screen and (max-width: 767.98px) {
  .section-schemes {
    background-attachment: scroll;
  }
}
@media only screen and (max-width: 575.98px) {
  .section-schemes {
    padding: 4rem 0;
  }
}
.section-schemes:before {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(68, 40, 108, 0.85);
  z-index: 2;
}
.section-schemes .container {
  position: relative;
  z-index: 3;
}
.section-schemes h2 {
  font-family: 'Lato', sans-serif;
  font-size: 2.25rem;
  line-height: 3.125rem;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 2.5rem;
}
.section-schemes p {
  max-width: 55.625rem;
  text-align: center;
  margin: 0 auto;
  font-family: 'Lato', sans-serif;
  color: #ffffff;
  font-size: 1.125rem;
  line-height: 2.375rem;
}
.section-schemes .items-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 50rem;
  margin: 0 auto;
  margin-top: 3.75rem;
}
@media only screen and (min-width: 767.99px) and (max-width: 1199.98px), only screen and (max-width: 767.98px) {
  .section-schemes .items-wrap {
    max-width: 100%;
    flex-wrap: wrap;
  }
}
.section-schemes .items-wrap .item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 767.98px) {
  .section-schemes .items-wrap .item {
    width: 33%;
    margin-bottom: 2rem;
  }
}
.section-schemes .items-wrap .item:hover {
  text-decoration: none;
}
.section-schemes .items-wrap .item:hover .icon {
  background-color: #ffffff;
  background-position: 50% 100%;
}
.section-schemes .items-wrap .text p {
  font-family: 'Lato', sans-serif;
  color: #ffffff;
  font-size: 1.125rem;
  margin-top: 1rem;
  line-height: 1.25rem;
  font-weight: 700;
  text-transform: uppercase;
}
.section-schemes .items-wrap .icon {
  width: 4.375rem;
  height: 4.375rem;
  border-radius: 50%;
  background-color: #36af50;
  background-repeat: no-repeat;
  background-position: 50% 0%;
  background-size: auto 8.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.section-schemes .items-wrap .icon:hover {
  background-color: #ffffff;
  background-position: 50% 100%;
}
.section-map {
  min-height: 55rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  padding: 6rem 0rem 3.5rem 0rem;
}
.section-map h2 {
  text-align: center;
}
.section-map p {
  max-width: 50rem;
  margin: 0 auto;
  text-align: center;
}
.section-map .btn-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2.3125rem 0rem 3.9375rem 0rem;
}
.section-map .btn-wrap .btn {
  margin: 0rem 0.875rem 0rem 0.875rem;
  width: 15.625rem;
}
.section-map .training-cources-wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
.section-map .training-cources-wrap .item {
  border-radius: 0.25rem;
  background-color: #ffffff;
  box-shadow: -1.113px 7.922px 10px 0px rgba(0, 0, 0, 0.11);
  max-width: 35.25rem;
  width: 47.5%;
  min-height: 3rem;
  margin-bottom: 0.625rem;
}
@media only screen and (min-width: 767.99px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .section-map .training-cources-wrap .item {
    width: 100%;
    max-width: 100%;
  }
}
.section-map .training-cources-wrap .item .item-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.section-map .training-cources-wrap .item .item-inner .cource-name {
  padding: 1rem 0.625rem 1rem 4.0625rem;
  position: relative;
  font-size: 1rem;
  font-family: 'Lato', sans-serif;
  color: #44286c;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.25;
}
@media only screen and (max-width: 767.98px) {
  .section-map .training-cources-wrap .item .item-inner .cource-name {
    max-width: 70%;
  }
}
.section-map .training-cources-wrap .item .item-inner .cource-name:before {
  content: "";
  left: 0.5rem;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 2.125rem;
  height: 2.125rem;
  background-color: #36af50;
  border-radius: 50%;
}
.section-map .training-cources-wrap .item .item-inner .cource-name::after {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  background-image: url(../img/icon-27.png);
  width: 2.125rem;
  height: 2.125rem;
  left: 0.5rem;
  background-position: center;
  background-size: contain;
}
.section-map .training-cources-wrap .item .item-inner .cource-name:hover {
  text-decoration: none;
}
.section-map .training-cources-wrap .item .item-inner .cource-name:hover:before {
  background-color: #44286c;
}
.section-map .training-cources-wrap .item .accordion-toggle {
  color: #36af50;
  font-family: 'Lato', sans-serif;
  font-size: 0.9375rem;
  font-weight: 700;
}
.section-map .training-cources-wrap .item .accordion-toggle:hover {
  text-decoration: none;
  cursor: pointer;
}
.section-map .training-cources-wrap .item .accordion-toggle.open .fa-chevron-right {
  display: inline-block;
  transition: all 0.3s ease;
  margin-right: 1rem;
  margin-left: 1.6rem;
}
@media only screen and (max-width: 767.98px) {
  .section-map .training-cources-wrap .item .accordion-toggle.open .fa-chevron-right {
    margin-left: 1rem;
  }
}
.section-map .training-cources-wrap .item .accordion-toggle.open .fa-chevron-down {
  display: none;
}
.section-map .training-cources-wrap .item .accordion-toggle.open.collapsed .fa-chevron-right {
  display: none;
}
.section-map .training-cources-wrap .item .accordion-toggle.open.collapsed .fa-chevron-down {
  transition: all 0.3s ease;
  display: inline-block;
  margin-right: 0.8rem;
  margin-left: 1.6rem;
}
@media only screen and (max-width: 767.98px) {
  .section-map .training-cources-wrap .item .accordion-toggle.open.collapsed .fa-chevron-down {
    margin-left: 1rem;
  }
}
.section-map .training-cources-wrap .item .accordion-toggle i {
  color: #44286c;
  font-size: 1.125rem;
  vertical-align: middle;
}
.section-map .training-cources-wrap .item .accordion-body {
  padding: 0rem 0.625rem 1.5625rem 4.0625rem;
}
@media only screen and (max-width: 767.98px) {
  .section-map .training-cources-wrap .item .accordion-body {
    padding: 0rem 0.625rem 1.5625rem 0.9375rem;
  }
}
.section-map .training-cources-wrap .item .accordion-body h4 {
  font-size: 1rem;
  font-family: 'Lato', sans-serif;
  line-height: 1.5625rem;
  margin-bottom: 1rem;
  color: #0e0e0e;
  font-weight: 700;
}
.section-map .training-cources-wrap .item .accordion-body .courses-info-wrap {
  max-height: 11.625rem;
}
@media only screen and (max-width: 767.98px) {
  .section-map .training-cources-wrap .item .accordion-body .courses-info-wrap {
    max-height: unset;
  }
}
.section-map .training-cources-wrap .item .accordion-body .courses-info-wrap .courses-list {
  list-style: none;
}
.section-map .training-cources-wrap .item .accordion-body .courses-info-wrap .courses-list .courses-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.section-map .training-cources-wrap .item .accordion-body .courses-info-wrap .courses-list .courses-item .city {
  padding-left: 1.6rem;
  width: 40%;
  font-size: 0.9375rem;
  font-family: 'Lato', sans-serif;
  line-height: 1.5625rem;
  margin: 0;
  color: #0e0e0e;
  font-weight: 400;
  position: relative;
}
.section-map .training-cources-wrap .item .accordion-body .courses-info-wrap .courses-list .courses-item .city::before {
  content: "\f3c5";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  color: #36af50;
  width: 0.8rem;
  height: auto;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
}
.section-map .training-cources-wrap .item .accordion-body .courses-info-wrap .courses-list .courses-item .date {
  padding-left: 1.6rem;
  width: 60%;
  font-size: 0.9375rem;
  font-family: 'Lato', sans-serif;
  line-height: 1.5625rem;
  margin: 0;
  color: #0e0e0e;
  font-weight: 400;
  position: relative;
}
.section-map .training-cources-wrap .item .accordion-body .courses-info-wrap .courses-list .courses-item .date::before {
  content: "\f073";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  left: 0;
  color: #36af50;
}
.section-screens {
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
  background-attachment: fixed;
}
.section-screens .section-inner {
  padding: 6.25rem 0rem 6.25rem 0rem;
  position: relative;
  font-size: 1.0625rem;
  line-height: 1.875rem;
  color: #ffffff;
}
.section-screens h2 {
  margin-bottom: 1.5rem;
}
.section-screens h2 img {
  width: 20.4375rem;
  height: auto;
  margin-left: -4px;
}
.section-screens p {
  margin: 0rem 0rem 2rem 0rem;
  font-size: 1.0625rem;
  line-height: 1.875rem;
  color: #ffffff;
}
.section-screens .images {
  position: absolute;
  top: 21%;
  right: 2%;
  width: 44%;
}
@media only screen and (min-width: 767.99px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .section-screens .images {
    width: 90%;
    margin: 0 auto;
    top: auto;
    right: auto;
    position: relative;
  }
}
.section-screens .images img {
  width: 100%;
  height: auto;
}
.section-screens .images img.screen {
  position: absolute;
  left: 0;
  top: 0;
}
.section-screens .screen-images-btn {
  position: absolute;
  top: 75%;
  right: 7%;
  z-index: 30;
}
@media only screen and (min-width: 767.99px) and (max-width: 1199.98px) {
  .section-screens .screen-images-btn {
    top: 70%;
  }
}
@media only screen and (min-width: 767.99px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .section-screens .screen-images-btn {
    position: relative;
    margin-left: 3rem;
    top: unset;
    right: unset;
    margin-bottom: 3rem;
  }
}
.section-sertification-process {
  padding: 10.375rem 0rem 3.625rem 0rem;
  background-position: 50% 0;
  background-size: 100% auto;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 767.98px) {
  .section-sertification-process {
    padding: 2.875rem 0rem 2.5rem 0rem;
  }
}
.section-sertification-process .text-side {
  padding: 3.0625rem 0rem 0rem 0rem;
}
.section-sertification-process .text-side h1 {
  font-family: 'Lato', sans-serif;
  font-size: 2.25rem;
  line-height: 3.75rem;
  color: #44286c;
  margin-bottom: 1.5rem;
}
.section-sertification-process .text-side .ref-link {
  color: #36af50;
  font-weight: 700;
}
.section-sertification-process .main-image-block {
  position: relative;
  width: 25.9372rem;
  height: 25.9372rem;
  margin-left: auto;
}
@media only screen and (max-width: 767.98px) {
  .section-sertification-process .main-image-block {
    margin: 0 auto;
    margin-top: 3rem;
  }
}
@media only screen and (max-width: 375px) {
  .section-sertification-process .main-image-block {
    width: 21.4rem;
    height: 21.4rem;
  }
}
.section-sertification-process .main-image-block .semi {
  top: 1.5rem;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 22.5rem;
  height: auto;
}
@media only screen and (max-width: 380px) {
  .section-sertification-process .main-image-block .semi {
    top: 0;
    width: 20.5rem;
  }
}
.section-sertification-process .main-image-block .semi img {
  width: 100%;
  height: auto;
}
.section-sertification-process .main-image-block .icons {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  height: auto;
  position: relative;
}
.section-sertification-process .main-image-block .icons .icon-right {
  position: absolute;
  right: -3.1rem;
  top: -0.6rem;
  width: 9.3125rem;
}
@media only screen and (max-width: 380px) {
  .section-sertification-process .main-image-block .icons .icon-right {
    width: 6.25rem;
  }
}
.section-sertification-process .main-image-block .icons .icon-left {
  position: absolute;
  left: -3.1rem;
  width: 8.75rem;
  top: -0.6rem;
}
@media only screen and (max-width: 380px) {
  .section-sertification-process .main-image-block .icons .icon-left {
    width: 6.25rem;
  }
}
.section-sertification-process .main-image-block .circle {
  width: 10rem;
  height: 8rem;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: transparent;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  z-index: 10;
}
.section-sertification-process .main-image-block .circle p {
  color: #ffffff;
  text-align: center;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.875rem;
  margin: 0;
}
.section-sertification-process .main-image-block .circle p.first-line {
  color: #36af50;
  margin-bottom: 1rem;
}
.section-sertification-process .main-image-block .circle p.second-line {
  text-transform: uppercase;
}
.section-sertification-process .main-image-block .round-group {
  width: 100%;
  height: 100%;
}
.section-sertification-process .main-image-block .round-group .round {
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.section-sertification-process .main-image-block .round-group .round.round-1 {
  width: 6.875rem;
  height: 6.875rem;
  background-color: #44286c;
  opacity: 1;
  z-index: 4;
  margin: -3.4375rem 0 0 -3.4375rem;
}
@media only screen and (max-width: 375px) {
  .section-sertification-process .main-image-block .round-group .round.round-1 {
    width: 8.45rem;
    height: 8.45rem;
    margin: -4.225rem 0 0 -4.225rem;
  }
}
.section-sertification-process .main-image-block .round-group .round.round-2 {
  width: 10.625rem;
  height: 10.625rem;
  background-color: #44286c;
  opacity: 0.7;
  z-index: 3;
  margin: -5.3125rem 0 0 -5.3125rem;
}
@media only screen and (max-width: 375px) {
  .section-sertification-process .main-image-block .round-group .round.round-2 {
    width: 12.45rem;
    height: 12.45rem;
    margin: -6.225rem 0 0 -6.225rem;
  }
}
.section-sertification-process .main-image-block .round-group .round.round-3 {
  width: 14.3125rem;
  height: 14.3125rem;
  background-color: #44286c;
  opacity: 0.7;
  z-index: 2;
  margin: -7.15625rem 0 0 -7.15625rem;
  box-shadow: 14px 30px 49px 0px rgba(0, 0, 0, 0.33);
}
@media only screen and (max-width: 375px) {
  .section-sertification-process .main-image-block .round-group .round.round-3 {
    width: 15.35rem;
    height: 15.35rem;
    margin: -7.675rem 0 0 -7.675rem;
  }
}
.section-sertification-process .main-image-block .round-group .round-arrow-1 {
  width: 17.25rem;
  height: 17.25rem;
  border-radius: 50%;
  margin: -8.625rem 0 0 -8.625rem;
  border: 0.125rem dashed #44286c;
  animation: circle-rotate 50s infinite linear;
  z-index: 2;
}
.section-sertification-process .main-image-block .round-group .round-arrow-1:before {
  content: '';
  width: 1rem;
  height: 1.438rem;
  background: url(../img/navigation.png) no-repeat;
  background-size: contain;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: -0.563rem;
}
.section-consultation-services {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 60% 0;
  background-color: #44286c;
  box-sizing: unset;
  padding: 5.375rem 0rem 8rem 0rem;
  position: relative;
  font-size: 1.125rem;
  line-height: 2.125rem;
}
@media only screen and (max-width: 767.98px) {
  .section-consultation-services {
    background-position: 0% 0%;
    padding: 4.125rem 0rem 5.5rem 0rem;
  }
}
.section-consultation-services h2 {
  text-align: center;
  color: #ffffff;
  margin-bottom: 5.125rem;
}
.section-consultation-services .services-list {
  list-style: none;
  margin-bottom: 2.5rem;
}
.section-consultation-services .services-list li {
  color: #ffffff;
  font-size: 1.125rem;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  line-height: 2.5rem;
  padding: 0rem 0rem 0rem 3.1875rem;
  position: relative;
}
.section-consultation-services .services-list li::before {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  background-image: url(../img/svg/icon-3.svg);
  background-repeat: no-repeat;
  background-size: contain;
  height: 2.125rem;
  width: 1.3125rem;
  left: 0;
  color: #36af50;
}
.section-consultation-services .services-list li a {
  color: #ffffff;
  font-size: 1.125rem;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  line-height: 2.5rem;
}
.visually-hidden {
  clip: rect(1px 1px 1px 1px);
  /* IE 6/7 */
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  margin: -1px;
}
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.visible-when-focused:focus {
  clip: auto;
  height: auto;
  overflow: visible;
  position: static;
  white-space: normal;
  width: auto;
  margin: auto;
}
.bypass-block-link:focus {
  position: absolute;
  text-decoration: none;
  background: #ffffcc;
  padding: 0.2em;
  z-index: 10;
}
.bypass-block-link:focus .bypass-block-target:focus {
  outline: none;
}
.bypass-block-link:focus .bypass-block-target:focus:target,
.bypass-block-link:focus a[href]:not([tabindex="-1"]):focus,
.bypass-block-link:focus area:not([tabindex="-1"]):focus,
.bypass-block-link:focus [role="button"]:not([tabindex="-1"]):focus,
.bypass-block-link:focus [role="link"]:not([tabindex="-1"]):focus,
.bypass-block-link:focus iframe:not([tabindex="-1"]):focus,
.bypass-block-link:focus [contentEditable=true]:not([tabindex="-1"]):focus,
.bypass-block-link:focus :enabled:not([tabindex="-1"]):focus,
.bypass-block-link:focus [tabindex]:not([tabindex="-1"]):focus {
  outline: solid 2px #44286c;
}
.bypass-block-link:focus a[href]:not([tabindex="-1"]):hover:focus,
.bypass-block-link:focus area:not([tabindex="-1"]):hover:focus,
.bypass-block-link:focus [role="button"]:not([tabindex="-1"]):hover:focus,
.bypass-block-link:focus [role="link"]:not([tabindex="-1"]):hover:focus,
.bypass-block-link:focus iframe:not([tabindex="-1"]):hover:focus,
.bypass-block-link:focus [contentEditable=true]:not([tabindex="-1"]):hover:focus,
.bypass-block-link:focus :enabled:not([tabindex="-1"]):hover:focus,
.bypass-block-link:focus [tabindex]:not([tabindex="-1"]):hover:focus {
  outline: solid 2px #44286c;
}
.bypass-block-link:focus a[href]:not([tabindex="-1"]):focus:active,
.bypass-block-link:focus area:not([tabindex="-1"]):focus:active,
.bypass-block-link:focus [role="button"]:not([tabindex="-1"]):focus:active,
.bypass-block-link:focus [role="link"]:not([tabindex="-1"]):focus:active,
.bypass-block-link:focus iframe:not([tabindex="-1"]):focus:active,
.bypass-block-link:focus [contentEditable=true]:not([tabindex="-1"]):focus:active,
.bypass-block-link:focus :enabled:not([tabindex="-1"]):focus:active,
.bypass-block-link:focus [tabindex]:not([tabindex="-1"]):focus:active {
  outline: none;
}
.bypass-block-link:focus :focus {
  outline: solid 2px #44286c;
}
.bypass-block-link:focus .placeholder-removed {
  opacity: 0.999;
}
.bypass-block-link:focus [placeholder] {
  background-color: #fff;
  border: solid 1px #777;
}
.bypass-block-link:focus ::-webkit-input-placeholder {
  color: #777;
  opacity: 1;
}
.bypass-block-link:focus :-moz-placeholder {
  /* Firefox 18- */
  color: #777;
  opacity: 1;
}
.bypass-block-link:focus ::-moz-placeholder {
  /* Firefox 19+ */
  color: #777;
  opacity: 1;
}
.bypass-block-link:focus :-ms-input-placeholder {
  color: #777;
  opacity: 1;
}
.block-toggle-ttl {
  padding: 0.75rem 0rem 0.75rem 0rem;
  background: none;
  border: 0;
  width: 100%;
  color: #000000;
  font-family: 'Lato', sans-serif;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  position: relative;
}
@media only screen and (max-width: 767.98px) {
  .block-toggle-ttl {
    font-size: 1rem;
  }
}
.block-toggle-ttl.wt-pad {
  padding: 0.75rem 0rem 0rem 0rem;
}
.block-toggle-ttl strong {
  max-width: 90%;
  font-weight: 700;
  text-align: left;
}
.block-toggle-ttl:focus {
  outline: none;
}
.block-toggle-ttl .under-ttl {
  position: absolute;
  left: 0;
  font-family: 'Lato', sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 30px;
  bottom: 0;
}
.block-toggle-ttl .plus-minus {
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  cursor: pointer;
}
.block-toggle-ttl .plus-minus:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0.688rem;
  height: 0.125rem;
  width: 100%;
  background: #36af50;
}
.block-toggle-ttl .plus-minus:after {
  content: '';
  position: absolute;
  left: 0.688rem;
  top: 0;
  width: 0.125rem;
  height: 100%;
  opacity: 0;
  background: #36af50;
}
.block-toggle-ttl.collapsed .plus-minus:after {
  opacity: 1;
}
.contact-block {
  padding: 1rem 0rem 5rem 0rem;
  background: url(../img/top-decoration.png) no-repeat 50% 0;
  background-size: 100% auto;
}
.form-block {
  padding: 3.75rem 5.625rem 4.375rem 5.625rem;
  margin: 0 auto;
  margin-top: -3.9375rem;
  max-width: 60rem;
  box-shadow: 2px 9px 21px 0px rgba(0, 0, 0, 0.11);
  background-color: #ffffff;
  position: relative;
  z-index: 153;
}
@media only screen and (max-width: 767.98px) {
  .form-block {
    margin-top: 1rem;
    max-width: 95%;
    padding: 2rem 2rem 2rem 2rem;
  }
}
.form-block .requared {
  position: absolute;
  right: 1rem;
  top: 1rem;
  color: #949494;
  font-size: 0.875rem;
}
.form-block .ttl {
  font-weight: 700;
  font-size: 1.875rem;
  color: #44286c;
  text-align: center;
  font-family: 'Lato', sans-serif;
}
.form-block p {
  font-size: 1.0625rem;
  text-align: center;
  line-height: 1.875rem;
  color: #0e0e0e;
}
.block-with-pic-wrap {
  margin: 6.5625rem 0rem 0rem 0rem;
}
@media only screen and (min-width: 767.99px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .block-with-pic-wrap {
    margin: 4.0625rem 0rem 0rem 0rem;
  }
}
.block-with-pic-wrap.block-with-pic-wrap-reverse {
  margin: 2.1875rem 0rem 0rem 0rem;
}
@media only screen and (min-width: 767.99px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .block-with-pic-wrap.block-with-pic-wrap-reverse {
    margin: 0.9375rem 0rem 1.5625rem 0rem;
  }
}
.block-with-pic-wrap.block-with-pic-wrap-reverse .image-wrap-holder {
  margin-left: 8.125rem;
}
@media only screen and (max-width: 1679px) {
  .block-with-pic-wrap.block-with-pic-wrap-reverse .image-wrap-holder {
    margin-left: 1rem;
  }
}
@media only screen and (min-width: 1199.99px) and (max-width: 1366px) {
  .block-with-pic-wrap.block-with-pic-wrap-reverse .image-wrap-holder {
    width: 38.07rem;
    height: 27.1875rem;
    margin-left: 3.5rem;
  }
}
@media only screen and (min-width: 767.99px) and (max-width: 1199.98px), only screen and (max-width: 767.98px) {
  .block-with-pic-wrap.block-with-pic-wrap-reverse .image-wrap-holder {
    width: 100%;
    height: auto;
    margin: 0 auto;
  }
}
.block-with-pic-wrap.block-with-pic-wrap-reverse .text-side {
  text-align: left;
}
@media only screen and (min-width: 767.99px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .block-with-pic-wrap.block-with-pic-wrap-reverse .text-side {
    margin-top: 0;
    margin-bottom: 3rem;
  }
}
.block-with-pic-wrap .image-wrap-holder {
  width: 43.75rem;
  height: 31.25rem;
  box-shadow: 2px 9px 21px rgba(0, 0, 0, 0.11);
  background-color: #ffffff;
  position: relative;
  margin-left: -14.9375rem;
}
@media only screen and (max-width: 1679px) {
  .block-with-pic-wrap .image-wrap-holder {
    margin-left: -9rem;
  }
}
@media only screen and (min-width: 1199.99px) and (max-width: 1366px) {
  .block-with-pic-wrap .image-wrap-holder {
    width: auto;
    height: auto;
    margin-left: -9rem;
  }
}
@media only screen and (min-width: 767.99px) and (max-width: 1199.98px), only screen and (max-width: 767.98px) {
  .block-with-pic-wrap .image-wrap-holder {
    width: 100%;
    height: auto;
    margin: 0 auto;
  }
}
.block-with-pic-wrap .image-wrap-holder img {
  width: 100%;
}
.block-with-pic-wrap .image-wrap-holder .imac-pic {
  position: absolute;
  left: -13%;
  width: 33.125rem;
  height: 29.375rem;
  bottom: 1rem;
}
@media only screen and (max-width: 1679px) {
  .block-with-pic-wrap .image-wrap-holder .imac-pic {
    width: 29.125rem;
    height: auto;
    left: -6%;
  }
}
@media only screen and (min-width:1199.99px) and (max-width: 1440px) {
  .block-with-pic-wrap .image-wrap-holder .imac-pic {
    width: 29.125rem;
    height: auto;
    left: -6%;
  }
}
@media only screen and (min-width: 767.99px) and (max-width: 1199.98px) {
  .block-with-pic-wrap .image-wrap-holder .imac-pic {
    width: 20.56rem;
    height: auto;
    left: -3%;
  }
}
@media only screen and (min-width: 767.99px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .block-with-pic-wrap .image-wrap-holder .imac-pic {
    width: 26.56rem;
    height: auto;
    left: -8%;
  }
}
@media only screen and (max-width: 575.98px) {
  .block-with-pic-wrap .image-wrap-holder .imac-pic {
    width: 14.56rem;
    height: auto;
    left: -8%;
  }
}
.block-with-pic-wrap .image-wrap-holder .circle-pic {
  position: absolute;
  right: 6%;
  width: 12.5rem;
  height: 12.5rem;
  bottom: 2.75rem;
}
@media only screen and (min-width: 767.99px) and (max-width: 1199.98px) {
  .block-with-pic-wrap .image-wrap-holder .circle-pic {
    width: 7.5rem;
    height: 7.5rem;
    bottom: 1.75rem;
  }
}
@media only screen and (max-width: 767.98px) {
  .block-with-pic-wrap .image-wrap-holder .circle-pic {
    width: 5.5rem;
    height: 5.5rem;
    bottom: 1.75rem;
  }
}
.block-with-pic-wrap h2 {
  color: #44286c;
  font-family: 'Lato', sans-serif;
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 0.72px;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
}
.block-with-pic-wrap .text-side {
  text-align: left;
}
@media only screen and (min-width: 767.99px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .block-with-pic-wrap .text-side {
    margin-top: 3rem;
  }
}
.purpose_list li {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 2.75rem;
  position: relative;
  outline: none;
}
@media only screen and (min-width: 767.99px) and (max-width: 1199.98px), only screen and (max-width: 767.98px) {
  .purpose_list li {
    align-items: flex-start;
  }
}
.purpose_list li:last-child::before {
  display: none;
}
.purpose_list li::before {
  content: "";
  position: absolute;
  left: 1.25rem;
  top: 0;
  height: 100%;
  background-color: #36af50;
  width: 1px;
  flex-shrink: 0;
}
.purpose_list .circle {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  border: 0.25rem solid #ffffff;
  background-color: #36af50;
  color: #ffffff;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  margin-right: 1.5rem;
  flex-shrink: 0;
}
.purpose_list .circle::before {
  content: "";
  width: 2.5625rem;
  height: 2.5625rem;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  flex-shrink: 0;
  background-color: transparent;
  border: 1px solid #36af50;
}
.purpose_list .text a {
  color: #44286c;
  font-family: 'Lato', sans-serif;
  font-size: 1.25rem;
  font-weight: 700;
  letter-spacing: 0.4px;
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.purpose_list .text a i {
  font-size: 0.6875rem;
}
.purpose_list .text .content p {
  margin: 0;
}
.section-benefits-gray {
  background-color: #f7f7f7;
}
.section-benefits-gray .section-inner {
  padding: 5rem 0rem 7.375rem 0rem;
  position: relative;
  font-size: 1.125rem;
  line-height: 2.125rem;
  color: #0e0e0e;
  text-align: center;
}
@media only screen and (max-width: 575.98px) {
  .section-benefits-gray .section-inner {
    padding: 4rem 0;
  }
}
.section-benefits-gray h2 {
  color: #44286c;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 2.25rem;
  line-height: 2.875rem;
  margin: 0rem 0rem 0.9375rem 0rem;
}
.section-benefits-gray ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 2.5rem 1.5625rem 0rem 1.5625rem;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
@media only screen and (min-width: 767.99px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .section-benefits-gray ul {
    padding: 0;
  }
}
@media only screen and (max-width: 575.98px) {
  .section-benefits-gray ul {
    flex-direction: column;
  }
}
.section-benefits-gray ul li {
  width: 44%;
  position: relative;
  padding: 0rem 0rem 0.75rem 3.75rem;
  font-size: 1.125rem;
  color: #44286c;
  font-weight: 700;
  text-align: left;
}
@media only screen and (max-width: 575.98px) {
  .section-benefits-gray ul li {
    width: 100%;
  }
}
.section-benefits-gray ul li:before {
  content: '';
  position: absolute;
  left: 0;
  width: 2.0625rem;
  height: 2.25rem;
  background: url(../img/icon-1.png) no-repeat;
  background-size: contain;
}
.section-software-togle {
  background: url(../img/top-decoration.png) no-repeat 50% 0;
  background-size: 100% auto;
  padding: 9.5625rem 0rem 5.75rem 0rem;
}
@media only screen and (max-width: 767.98px) {
  .section-software-togle {
    padding: 3.3125rem 0rem 2.625rem 0rem;
  }
}
.section-software-togle .btn-wrap {
  padding-top: 3.125rem;
  margin: 0 auto;
  text-align: center;
}
.section-software-togle .btn-wrap .btn {
  width: 15.625rem;
}
.section-software-togle .toggle-block-software-page {
  text-align: left;
}
.section-software-togle .toggle-block-software-page .line-wrap {
  border-bottom: 1px solid #d9d9d9;
}
.section-software-togle .toggle-block-software-page .block-toggle-ttl {
  padding: 2.8125rem 0rem 1.75rem 0rem;
  background: none;
  border: 0;
  width: 100%;
  font-size: 2.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  color: #44286c;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  letter-spacing: 0.72px;
  text-transform: uppercase;
}
@media only screen and (max-width: 767.98px) {
  .section-software-togle .toggle-block-software-page .block-toggle-ttl {
    font-size: 1rem;
  }
}
.section-software-togle .toggle-block-software-page .block-toggle-ttl strong {
  max-width: 90%;
  font-weight: 700;
  text-align: left;
}
.section-software-togle .toggle-block-software-page .block-toggle-ttl:focus {
  outline: none;
}
.section-software-togle .toggle-block-software-page .block-toggle-ttl .plus-minus {
  width: 1.875rem;
  height: 1.875rem;
  position: relative;
  cursor: pointer;
}
.section-software-togle .toggle-block-software-page .block-toggle-ttl .plus-minus:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0.875rem;
  height: 0.125rem;
  width: 100%;
  background: #36af50;
}
.section-software-togle .toggle-block-software-page .block-toggle-ttl .plus-minus:after {
  content: '';
  position: absolute;
  left: 0.8758rem;
  top: 0;
  width: 0.125rem;
  height: 100%;
  opacity: 0;
  background: #36af50;
}
.section-software-togle .toggle-block-software-page .block-toggle-ttl.collapsed .plus-minus:after {
  opacity: 1;
}
.section-software-togle .toggle-block-software-page .block-toggle-content {
  font-size: 1.0625rem;
}
.section-software-togle .toggle-block-software-page .block-toggle-content p {
  margin-bottom: 2.825rem;
}
.resouces-wrap {
  padding: 5.625rem 0rem 2rem 0rem;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
}
.resouces-wrap .item {
  border-radius: 0.25rem;
  background-color: #f5f5f5;
  width: 33.75rem;
  height: 28.75rem;
  padding: 0rem 1.875rem 0rem 1.875rem;
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 767.99px) and (max-width: 991.98px) {
  .resouces-wrap .item {
    width: 100%;
    margin-bottom: 5rem;
    height: unset;
    min-height: 25rem;
    padding: 0rem 1.875rem 1.875rem 1.875rem;
  }
}
@media only screen and (max-width: 767.98px) {
  .resouces-wrap .item {
    width: 100%;
    margin-bottom: 5rem;
    height: unset;
    min-height: 25rem;
    padding: 0rem 1.875rem 1.875rem 1.875rem;
  }
  .resouces-wrap .item:last-child {
    margin-bottom: 1rem;
  }
}
.resouces-wrap .item .pic {
  position: relative;
  margin: 0 auto;
  margin: -1.4375rem 0rem 2.25rem 0rem;
  border-radius: 0.5rem;
  background-color: #44286c;
  box-shadow: 2.783px 19.805px 27px 0px rgba(0, 0, 0, 0.17);
  width: 30.0625rem;
  height: 10.0625rem;
  background-size: cover;
  background-position: center;
}
@media only screen and (min-width: 767.99px) and (max-width: 991.98px) {
  .resouces-wrap .item .pic {
    width: 100%;
    height: 13.8125rem;
  }
}
@media only screen and (max-width: 767.98px) {
  .resouces-wrap .item .pic {
    width: 100%;
  }
}
.resouces-wrap .item .pic span {
  position: absolute;
  left: 2.875rem;
  bottom: 1.5rem;
  color: #ffffff;
  font-size: 2.25rem;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
}
.resouces-wrap .item .resources-text {
  max-height: 15.18rem;
}
@media only screen and (min-width: 767.99px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .resouces-wrap .item .resources-text {
    height: unset;
  }
}
.resouces-wrap .item .resources-list {
  list-style: none;
}
.resouces-wrap .item .resources-list li {
  position: relative;
  padding: 0rem 0rem 0rem 3rem;
  font-size: 1rem;
  line-height: 3.1875rem;
  color: #44286c;
  font-weight: 400;
  text-align: left;
}
.resouces-wrap .item .resources-list li:before {
  content: '';
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  width: 1.1875rem;
  height: 1.8125rem;
  background: url(../img/icon-1.png) no-repeat;
  background-size: contain;
}
.consulting-wrap {
  padding: 5.625rem 0rem 2rem 0rem;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
}
.consulting-wrap .item {
  border-radius: 0.25rem;
  background-color: #f5f5f5;
  width: 31%;
  min-height: 29rem;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 4.6875rem;
}
@media only screen and (min-width: 767.99px) and (max-width: 991.98px) {
  .consulting-wrap .item {
    width: 100%;
    margin-bottom: 4rem;
    padding-bottom: 4rem;
    height: unset;
    min-height: unset;
  }
}
@media only screen and (max-width: 767.98px) {
  .consulting-wrap .item {
    width: 100%;
    margin-bottom: 5rem;
    height: unset;
    min-height: unset;
    padding-bottom: 4rem;
  }
  .consulting-wrap .item:last-child {
    margin-bottom: 1rem;
  }
}
.consulting-wrap .item:nth-child(2n) {
  margin-top: 4.6875rem;
  margin-bottom: 0;
}
@media only screen and (min-width: 767.99px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .consulting-wrap .item:nth-child(2n) {
    margin-top: 0;
    margin-bottom: 4rem;
  }
}
.consulting-wrap .item .image-hold {
  margin: -1.25rem 1.5rem 1.125rem 1.5rem;
  position: relative;
  height: 11.25rem;
  border-radius: 0.5rem;
  box-shadow: 2.783px 19.805px 27px 0px rgba(0, 0, 0, 0.17);
  box-sizing: unset;
}
.consulting-wrap .item .image-hold .image {
  height: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 0.5rem;
}
.consulting-wrap .item .image-hold .image .img {
  border-radius: 0.5rem;
  height: 100%;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  transition: all 0.3s ease;
}
.consulting-wrap .item:hover .image-hold {
  border-radius: 0.5rem;
}
.consulting-wrap .item:hover .image-hold .img {
  border-radius: 0.5rem;
  transform: scale(1.1);
}
@media only screen and (min-width: 767.99px) and (max-width: 1199.98px), only screen and (max-width: 767.98px) {
  .consulting-wrap .item:hover .image-hold .img {
    transform: scale(1);
  }
}
.consulting-wrap .item .main-text {
  padding: 0rem 3.125rem 0rem 2.9375rem;
}
.consulting-wrap .item .main-text h3 {
  font-family: 'Lato', sans-serif;
  font-size: 1.5rem;
  color: #44286c;
  font-weight: 700;
  line-height: 2.125rem;
  margin-bottom: 1.3rem;
}
.consulting-wrap .item .main-text p {
  font-family: 'Lato', sans-serif;
  font-size: 1rem;
  color: #0e0e0e;
  font-weight: 400;
  line-height: 1.625rem;
}
.consulting-wrap .item .btn-wrap {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -1.4rem;
}
.consulting-wrap .item .btn-wrap .btn:hover {
  background-color: #44286c;
  border-color: #44286c;
}
.training-page .section-map {
  min-height: 55rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  padding: 2rem 0rem 3.5rem 0rem;
}
.training-page .section-map h2 {
  text-align: center;
}
.training-page .section-map p {
  max-width: 50rem;
  margin: 0 auto;
  text-align: center;
}
.training-page .section-map .btn-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2.3125rem 0rem 3.9375rem 0rem;
}
.training-page .section-map .btn-wrap .btn {
  margin: 0rem 0.875rem 0rem 0.875rem;
  width: 15.625rem;
}
.training-page .cource-fee {
  font-size: 1.125rem;
  font-family: 'Lato', sans-serif;
  color: #44286c;
  font-weight: 400;
  margin-bottom: 2.75rem;
}
@media only screen and (max-width: 575.98px) {
  .training-page .cource-fee {
    font-size: 1.0625rem;
  }
}
.training-page .cource-fee span {
  font-size: 1.5rem;
  color: #36af50;
}
@media only screen and (max-width: 575.98px) {
  .training-page .cource-fee span {
    font-size: 1.3125rem;
  }
}
.training-page .btn-wrap {
  margin: 3.5625rem 0rem 0rem 4.6875rem;
}
.training-page .btn-wrap .btn {
  width: 11.25rem;
}
.training-page .training-services-wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
.training-page .training-services-wrap .item {
  border-radius: 0.25rem;
  background-color: #ffffff;
  box-shadow: -1.113px 7.922px 10px 0px rgba(0, 0, 0, 0.11);
  width: 100%;
  min-height: 3rem;
  margin-bottom: 1.1rem;
  overflow: hidden;
}
@media only screen and (min-width: 767.99px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .training-page .training-services-wrap .item {
    width: 100%;
    max-width: 100%;
  }
}
.training-page .training-services-wrap .item .item-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 2.275rem 2.375rem 1.6rem 3.0625rem;
  z-index: 3;
}
@media only screen and (max-width: 767.98px) {
  .training-page .training-services-wrap .item .item-inner {
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 767.98px) {
  .training-page .training-services-wrap .item .item-inner {
    padding: 1.8125rem 1.125rem 1.75rem 1.8125rem;
  }
}
.training-page .training-services-wrap .item .item-inner .cource-name {
  position: relative;
  font-size: 1.5rem;
  font-family: 'Lato', sans-serif;
  color: #44286c;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.5;
}
@media only screen and (max-width: 767.98px) {
  .training-page .training-services-wrap .item .item-inner .cource-name {
    font-size: 1.3125rem;
  }
}
@media only screen and (max-width: 575.98px) {
  .training-page .training-services-wrap .item .item-inner .cource-name {
    font-size: 1rem;
  }
}
.training-page .training-services-wrap .item .item-inner .cource-fee {
  font-size: 1.125rem;
  font-family: 'Lato', sans-serif;
  color: #44286c;
  font-weight: 400;
  margin: 0;
}
@media only screen and (max-width: 575.98px) {
  .training-page .training-services-wrap .item .item-inner .cource-fee {
    font-size: 1.0625rem;
  }
}
.training-page .training-services-wrap .item .item-inner .cource-fee span {
  font-size: 1.5rem;
  color: #36af50;
}
@media only screen and (max-width: 575.98px) {
  .training-page .training-services-wrap .item .item-inner .cource-fee span {
    font-size: 1.3125rem;
  }
}
.training-page .training-services-wrap .item .item-inner .accordion-toggle {
  color: #36af50;
  font-family: 'Lato', sans-serif;
  font-size: 0.9375rem;
  font-weight: 700;
}
@media only screen and (max-width: 575.98px) {
  .training-page .training-services-wrap .item .item-inner .accordion-toggle {
    width: 100%;
    margin-bottom: 1rem;
  }
}
.training-page .training-services-wrap .item .item-inner .accordion-toggle:hover {
  text-decoration: none;
  cursor: pointer;
}
.training-page .training-services-wrap .item .item-inner .accordion-toggle.open .fa-chevron-right {
  display: inline-block;
  transition: all 0.3s ease;
}
@media only screen and (max-width: 767.98px) {
  .training-page .training-services-wrap .item .item-inner .accordion-toggle.open .fa-chevron-right {
    margin-left: 1rem;
  }
}
.training-page .training-services-wrap .item .item-inner .accordion-toggle.open .fa-chevron-down {
  display: none;
}
.training-page .training-services-wrap .item .item-inner .accordion-toggle.open .item-inner .cource-name:before {
  background-color: #44286c;
}
.training-page .training-services-wrap .item .item-inner .accordion-toggle.open.collapsed .fa-chevron-right {
  display: none;
}
.training-page .training-services-wrap .item .item-inner .accordion-toggle.open.collapsed .fa-chevron-down {
  transition: all 0.3s ease;
  display: inline-block;
}
@media only screen and (max-width: 767.98px) {
  .training-page .training-services-wrap .item .item-inner .accordion-toggle.open.collapsed .fa-chevron-down {
    margin-left: 1rem;
  }
}
.training-page .training-services-wrap .item .item-inner .accordion-toggle i {
  color: #44286c;
  font-size: 1.125rem;
  vertical-align: middle;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}
@media only screen and (max-width: 575.98px) {
  .training-page .training-services-wrap .item .item-inner .accordion-toggle i {
    font-size: 0.9375rem;
  }
}
.training-page .training-services-wrap .item .accordion-body {
  position: relative;
  padding: 0rem 0.625rem 3.0625rem 3rem;
  margin-top: -1rem;
}
@media only screen and (max-width: 767.98px) {
  .training-page .training-services-wrap .item .accordion-body {
    padding: 0rem 0.625rem 1.5625rem 0.9375rem;
  }
}
.training-page .training-services-wrap .item .accordion-body .sub-logo-img {
  position: absolute;
  right: -1rem;
  bottom: -5rem;
  width: 18.125rem;
  z-index: 0;
}
.training-page .training-services-wrap .item .accordion-body h4 {
  font-size: 1rem;
  font-family: 'Lato', sans-serif;
  line-height: 1.5625rem;
  margin-bottom: 1rem;
  color: #0e0e0e;
  font-weight: 700;
}
.training-page .training-services-wrap .item .accordion-body .courses-info-wrap {
  max-height: unset;
  margin-top: 2rem;
}
@media only screen and (max-width: 767.98px) {
  .training-page .training-services-wrap .item .accordion-body .courses-info-wrap {
    max-height: unset;
  }
}
.training-page .training-services-wrap .item .accordion-body .courses-info-wrap .courses-list {
  list-style: none;
  margin: 0;
}
.training-page .training-services-wrap .item .accordion-body .courses-info-wrap .courses-list .courses-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.training-page .training-services-wrap .item .accordion-body .courses-info-wrap .courses-list .courses-item .city {
  padding-left: 1.6rem;
  width: 40%;
  font-size: 0.9375rem;
  font-family: 'Lato', sans-serif;
  line-height: 1.5625rem;
  margin: 0;
  color: #0e0e0e;
  font-weight: 400;
  position: relative;
}
.training-page .training-services-wrap .item .accordion-body .courses-info-wrap .courses-list .courses-item .city::before {
  content: "\f3c5";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  color: #36af50;
  width: 0.8rem;
  height: auto;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
}
.training-page .training-services-wrap .item .accordion-body .courses-info-wrap .courses-list .courses-item .date {
  padding-left: 1.6rem;
  width: 60%;
  font-size: 0.9375rem;
  font-family: 'Lato', sans-serif;
  line-height: 1.5625rem;
  margin: 0;
  color: #0e0e0e;
  font-weight: 400;
  position: relative;
}
.training-page .training-services-wrap .item .accordion-body .courses-info-wrap .courses-list .courses-item .date::before {
  content: "\f073";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  left: 0;
  color: #36af50;
}
.training-page .training-services-wrap .item .accordion-body .courses-info-wrap .all_ref {
  color: #36af50;
  font-size: 1rem;
  font-weight: 700;
  padding-left: 1.6rem;
}
.training-page .training-services-wrap .item .accordion-body .course-overall-wrap {
  border-radius: 0.25rem;
  background-color: #f5f5f5;
  width: 32.875rem;
  min-height: 13.75rem;
  position: relative;
  margin-top: 2rem;
}
@media only screen and (min-width: 767.99px) and (max-width: 1199.98px), only screen and (max-width: 767.98px) {
  .training-page .training-services-wrap .item .accordion-body .course-overall-wrap {
    width: 100%;
    margin-bottom: 2rem;
  }
}
.training-page .training-services-wrap .item .accordion-body .course-overall-wrap .subj {
  position: absolute;
  border: solid 0.25rem #f5f5f5;
  background-color: #36af50;
  border-radius: 1rem;
  top: -1rem;
  color: #ffffff;
  left: 1rem;
  padding: 0.25rem 0.9375rem 0.25rem 0.9375rem;
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 700;
  z-index: 10;
}
.training-page .training-services-wrap .item .accordion-body .course-overall-wrap .overall-list {
  padding: 1.9375rem 0.625rem 1.25rem 2.1875rem;
}
.training-page .training-services-wrap .item .accordion-body .course-overall-wrap .overall-list .ref_link {
  color: #36af50;
  font-size: 1rem;
  font-weight: 700;
  padding-left: 2.4375rem;
}
.training-page .training-services-wrap .item .accordion-body .course-overall-wrap .overall-list ul {
  list-style: none;
  margin: 0;
}
.training-page .training-services-wrap .item .accordion-body .course-overall-wrap .overall-list ul li {
  line-height: 1.875rem;
  position: relative;
  padding: 0rem 0rem 0.625rem 2.4375rem;
  font-size: 1rem;
  color: #0e0e0e;
  font-weight: 400;
  text-align: left;
}
@media only screen and (max-width: 575.98px) {
  .training-page .training-services-wrap .item .accordion-body .course-overall-wrap .overall-list ul li {
    width: 100%;
  }
}
.training-page .training-services-wrap .item .accordion-body .course-overall-wrap .overall-list ul li:before {
  content: '';
  top: 0;
  position: absolute;
  left: 0;
  width: 1.4375rem;
  height: 1.75rem;
  background: url(../img/icon-1.png) no-repeat;
  background-size: contain;
}
.consulting-inner-wrap {
  display: flex;
  justify-content: space-between;
}
@media only screen and (min-width: 767.99px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .consulting-inner-wrap {
    flex-wrap: wrap;
  }
}
.consulting-inner-wrap h3 {
  font-size: 1.5rem;
  line-height: 2.125rem;
  font-weight: 700;
  font-family: 'Lato', sans-serif;
  color: #44286c;
  margin-bottom: 2.1875rem;
}
.consulting-inner-wrap .ref-link {
  color: #36af50;
  font-weight: 700;
}
.consulting-inner-wrap .left-side {
  width: 64%;
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 767.99px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .consulting-inner-wrap .left-side {
    width: 100%;
    margin-bottom: 2rem;
  }
}
.consulting-inner-wrap .left-side h3 {
  font-size: 1.5rem;
  line-height: 2.125rem;
  font-weight: 700;
  font-family: 'Lato', sans-serif;
  color: #44286c;
  margin-bottom: 2.1875rem;
}
.consulting-inner-wrap .left-side p {
  font-size: 1.125rem;
  font-family: 'Lato', sans-serif;
  line-height: 2.125rem;
  color: #0e0e0e;
  margin-bottom: 2rem;
}
.consulting-inner-wrap .left-side ul {
  list-style: none;
  margin: 0;
  margin-top: 0.625rem;
}
.consulting-inner-wrap .left-side ul li {
  line-height: 1.875rem;
  position: relative;
  padding: 0rem 0rem 1.25rem 2.6875rem;
  font-size: 1.125rem;
  color: #0e0e0e;
  font-weight: 400;
  text-align: left;
}
@media only screen and (max-width: 575.98px) {
  .consulting-inner-wrap .left-side ul li {
    width: 100%;
  }
}
.consulting-inner-wrap .left-side ul li:before {
  content: '';
  top: 0;
  position: absolute;
  left: 0;
  width: 1.4375rem;
  height: 1.75rem;
  background: url(../img/icon-1.png) no-repeat;
  background-size: contain;
}
.consulting-inner-wrap .left-side ul li a {
  color: #0e0e0e;
  cursor: pointer;
}
.consulting-inner-wrap .left-side ul li a:hover {
  text-decoration: none;
  color: #44286c;
}
.consulting-inner-wrap .left-side img {
  margin: 0 auto;
  text-align: center;
  width: 29.75rem;
  height: auto;
  border-radius: 0.5rem;
  box-shadow: 2.783px 19.805px 27px 0px rgba(0, 0, 0, 0.17);
  margin-bottom: 4rem;
  margin-top: 2rem;
}
@media only screen and (min-width: 767.99px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .consulting-inner-wrap .left-side img {
    width: 100%;
    max-width: 30rem;
  }
}
.consulting-inner-wrap .right-side {
  width: 36%;
}
@media only screen and (min-width: 767.99px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .consulting-inner-wrap .right-side {
    width: 100%;
  }
}
.consulting-inner-wrap .right-side .sidebar {
  position: relative;
  margin-top: -2rem;
  border-radius: 0.25rem;
  background-color: #f5f5f5;
  margin-left: 7.3125rem;
  min-height: 39.5rem;
  padding: 2.125rem 2.8125rem 2rem 0rem;
}
@media only screen and (min-width: 767.99px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .consulting-inner-wrap .right-side .sidebar {
    margin: 0;
    min-height: auto;
    padding: 2.125rem 0.9375rem 2rem 1rem;
  }
}
.consulting-inner-wrap .right-side .sidebar h3 {
  margin-bottom: 2rem;
  padding-left: 2rem;
}
@media only screen and (min-width: 767.99px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .consulting-inner-wrap .right-side .sidebar h3 {
    padding-left: 1rem;
  }
}
.consulting-inner-wrap .right-side .sidebar .related-services-list {
  list-style: none;
}
.consulting-inner-wrap .right-side .sidebar .related-services-list li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1.6rem;
}
.consulting-inner-wrap .right-side .sidebar .related-services-list li .icon {
  border-radius: 0.5rem;
  box-shadow: 2.783px 19.805px 27px 0px rgba(0, 0, 0, 0.17);
  width: 6.3125rem;
  height: 5.6875rem;
  margin-right: 1.6rem;
  margin-left: -1.5rem;
  position: relative;
  flex-shrink: 0;
  overflow: hidden;
}
@media only screen and (min-width: 767.99px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .consulting-inner-wrap .right-side .sidebar .related-services-list li .icon {
    margin-left: 0;
  }
}
.consulting-inner-wrap .right-side .sidebar .related-services-list li .icon img {
  width: 100%;
  height: auto;
}
.consulting-inner-wrap .right-side .sidebar .related-services-list li .text {
  font-size: 1rem;
  line-height: 1.625rem;
  font-weight: 700;
  font-family: 'Lato', sans-serif;
}
.consulting-inner-wrap .right-side .sidebar .related-services-list li .text a {
  color: #44286c;
}
.consulting-inner-wrap .right-side .sidebar .add-more {
  margin: 0 auto;
  text-align: center;
}
.consulting-inner-wrap .right-side .sidebar .add-more a {
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 700;
  font-family: 'Lato', sans-serif;
  color: #36af50;
}
.schemes-page .customise-tabs {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
@media only screen and (max-width: 767.98px) {
  .schemes-page .customise-tabs {
    padding: 0rem 0.625rem 0rem 0.625rem;
  }
}
.schemes-page .customise-tabs .nav-tabs-wrap {
  width: 21.6%;
  background-color: #f5f5f5;
  position: relative;
  padding: 1.25rem 0rem 7.75rem 0rem;
  overflow: hidden;
}
@media only screen and (max-width: 767.98px) {
  .schemes-page .customise-tabs .nav-tabs-wrap {
    width: 100%;
  }
}
.schemes-page .customise-tabs .nav-tabs-wrap h3 {
  padding-left: 2.25rem;
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 700;
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;
  margin-bottom: 0.625rem;
}
.schemes-page .customise-tabs .nav-tabs-wrap .sub-logo-img {
  position: absolute;
  height: 17.5rem;
  right: -5rem;
  bottom: -9rem;
}
.schemes-page .customise-tabs .nav-tabs-wrap .nav-tabs {
  border: 0;
  flex-direction: column;
}
.schemes-page .customise-tabs .nav-tabs-wrap .nav-tabs .nav-link {
  border: 0;
  border-radius: 0;
  padding: 0.25rem 0.625rem 0.25rem 2.25rem;
  line-height: 2.125rem;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  color: #44286c;
  font-size: 1rem;
  position: relative;
}
.schemes-page .customise-tabs .nav-tabs-wrap .nav-tabs .nav-link.active {
  font-weight: 700;
}
.schemes-page .customise-tabs .nav-tabs-wrap .nav-tabs .nav-link.active::before {
  content: "";
  position: absolute;
  left: 0;
  width: 0.25rem;
  height: 100%;
  background-color: #36af50;
  top: 0;
}
.schemes-page .customise-tabs .training-inner-content {
  width: 78.4%;
  padding: 2.8125rem 1rem 0rem 1rem;
}
@media only screen and (max-width: 767.98px) {
  .schemes-page .customise-tabs .training-inner-content {
    width: 100%;
    padding: 2.8125rem 0rem 0rem 0rem;
  }
}
.schemes-page .customise-tabs .training-inner-content h3 {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  color: #44286c;
  font-size: 1.5rem;
  line-height: 2.125rem;
  margin-bottom: 2.1rem;
}
.schemes-page .customise-tabs .training-inner-content h4 {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  color: #44286c;
  font-size: 1.25rem;
  line-height: 2.125rem;
  margin-bottom: 2.1rem;
}
.schemes-page .customise-tabs .training-inner-content p {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  color: #0e0e0e;
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.schemes-page .customise-tabs .training-inner-content ul {
  list-style: none;
  margin: 0;
  margin-top: 0.625rem;
  margin-bottom: 1.5rem;
}
.schemes-page .customise-tabs .training-inner-content ul li {
  line-height: 1.875rem;
  position: relative;
  padding: 0rem 0rem 1.25rem 2.6875rem;
  font-size: 1.125rem;
  color: #0e0e0e;
  font-weight: 400;
  text-align: left;
}
@media only screen and (max-width: 575.98px) {
  .schemes-page .customise-tabs .training-inner-content ul li {
    width: 100%;
  }
}
.schemes-page .customise-tabs .training-inner-content ul li:before {
  content: '';
  top: 0;
  position: absolute;
  left: 0;
  width: 1.4375rem;
  height: 1.75rem;
  background: url(../img/icon-1.png) no-repeat;
  background-size: contain;
}
.schemes-page .customise-tabs .training-inner-content .box-content {
  margin: 0 auto;
  max-width: 47.5rem;
}
@media only screen and (max-width: 767.98px) {
  .schemes-page .customise-tabs .training-inner-content .box-content {
    max-width: 100%;
  }
}
.schemes-page .customise-tabs .training-inner-content .box-content.box-content-2 {
  margin: 0;
  margin-left: auto;
  max-width: 52.75rem;
}
.schemes-page .customise-tabs .training-inner-content img {
  margin: 0 auto;
  text-align: center;
  width: 29.75rem;
  height: auto;
  border-radius: 0.5rem;
  box-shadow: 2.783px 19.805px 27px 0px rgba(0, 0, 0, 0.17);
  margin-bottom: 2rem;
  margin-top: 2rem;
}
@media only screen and (min-width: 767.99px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .schemes-page .customise-tabs .training-inner-content img {
    width: 100%;
    max-width: 30rem;
  }
}
.schemes-page .customise-tabs .training-inner-content .quantity-wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}
@media only screen and (max-width: 575.98px) {
  .schemes-page .customise-tabs .training-inner-content .quantity-wrap {
    flex-wrap: wrap;
  }
}
.schemes-page .customise-tabs .training-inner-content .quantity-wrap .item {
  width: 47%;
  max-width: 16rem;
  position: relative;
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 575.98px) {
  .schemes-page .customise-tabs .training-inner-content .quantity-wrap .item {
    width: 100%;
    max-width: 100%;
    margin-bottom: 2rem;
  }
}
.schemes-page .customise-tabs .training-inner-content .quantity-wrap .item .circle {
  width: 4.375rem;
  height: 4.375rem;
  border-radius: 50%;
  background-color: #36af50;
  background-size: 2.3rem;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-position: center;
  z-index: 5;
}
.schemes-page .customise-tabs .training-inner-content .quantity-wrap .item .circle.circle-1 {
  background-color: #44286c;
}
.schemes-page .customise-tabs .training-inner-content .quantity-wrap .item .line {
  position: absolute;
  width: 11.875rem;
  width: 100%;
  height: 1px;
  background-color: #36af50;
  left: 0;
  top: 2.1875rem;
  z-index: 0;
}
@media only screen and (max-width: 575.98px) {
  .schemes-page .customise-tabs .training-inner-content .quantity-wrap .item .line {
    left: 0;
  }
}
.schemes-page .customise-tabs .training-inner-content .quantity-wrap .item .line.line-1 {
  background-color: #44286c;
}
.schemes-page .customise-tabs .training-inner-content .quantity-wrap .item .numbers {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.schemes-page .customise-tabs .training-inner-content .quantity-wrap .item .numbers .name {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  color: #0e0e0e;
  font-size: 1.125rem;
  line-height: 2.125rem;
  text-transform: uppercase;
}
.schemes-page .customise-tabs .training-inner-content .quantity-wrap .item .numbers .num {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  color: #0e0e0e;
  font-size: 2.875rem;
  line-height: 3.875rem;
}
.company-page h5 {
  text-align: center;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  color: #44286c;
  font-size: 1.125rem;
  line-height: 2.125rem;
  margin-bottom: 2rem;
}
.company-page h4 {
  text-align: center;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  color: #36af50;
  font-size: 1.875rem;
  line-height: 2.5rem;
  margin-bottom: 0rem;
}
.company-page .description-sect {
  padding: 7.375rem 0rem 7rem 0rem;
}
.company-page .description-sect p {
  padding-right: 5rem;
}
@media only screen and (min-width: 767.99px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .company-page .description-sect p {
    padding-right: 1rem;
  }
}
.company-page .description-sect img {
  margin: 0 auto;
  text-align: center;
  width: 37.8125rem;
  height: auto;
  border-radius: 0.5rem;
  box-shadow: 2.783px 19.805px 27px 0px rgba(0, 0, 0, 0.17);
}
@media only screen and (min-width: 767.99px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .company-page .description-sect img {
    width: 100%;
    max-width: 30rem;
  }
}
.company-page .section-globe {
  background-attachment: fixed;
  background-position: 100% center;
  background-size: cover;
  padding: 6.5rem 0rem 6.25rem 0rem;
}
@media only screen and (min-width: 767.99px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .company-page .section-globe {
    background-attachment: scroll;
    padding: 4.625rem 0rem 4.375rem 0rem;
  }
}
.company-page .section-globe p {
  color: #ffffff;
  font-size: 1.125rem;
  margin-bottom: 2rem;
  line-height: 2rem;
}
.company-page .section-globe .main-image-block {
  position: relative;
  width: 29.75rem;
  height: 29.75rem;
}
@media only screen and (min-width: 767.99px) and (max-width: 1199.98px) {
  .company-page .section-globe .main-image-block {
    margin: 0 auto;
    margin-bottom: 3rem;
  }
}
@media only screen and (max-width: 767.98px) {
  .company-page .section-globe .main-image-block {
    margin: 0 auto;
    margin-top: 3rem;
    margin-bottom: 3rem;
    width: 27.75rem;
    height: 27.75rem;
  }
}
@media only screen and (max-width: 375px) {
  .company-page .section-globe .main-image-block {
    width: 21.4rem;
    height: 21.4rem;
  }
}
.company-page .section-globe .main-image-block .icons {
  position: absolute;
  top: 0;
  width: 100%;
  height: auto;
  position: relative;
}
.company-page .section-globe .main-image-block .icons .icon-right {
  position: absolute;
  right: 2.2rem;
  top: 14.25rem;
  width: 4.0625rem;
  z-index: 4;
}
.company-page .section-globe .main-image-block .icons .icon-left {
  position: absolute;
  width: 4.0625rem;
  left: 10.68rem;
  top: 2.9rem;
  z-index: 4;
}
@media only screen and (max-width: 380px) {
  .company-page .section-globe .main-image-block .icons .icon-left {
    left: 4.68rem;
  }
}
.company-page .section-globe .main-image-block .circle {
  width: 12.5625rem;
  height: 12.5625rem;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transform: translate(-50%, -65%);
  background-color: transparent;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  z-index: 10;
}
@media only screen and (max-width: 400px) {
  .company-page .section-globe .main-image-block .circle {
    width: 10.0625rem;
    height: 10.0625rem;
  }
}
.company-page .section-globe .main-image-block .circle img {
  width: 12.5625rem;
  animation: pulse-icon 2.5s ease infinite;
}
@media only screen and (max-width: 400px) {
  .company-page .section-globe .main-image-block .circle img {
    width: 8.8125rem;
  }
}
.company-page .section-globe .main-image-block .circle-firm-1 {
  position: absolute;
  background-color: #36af50;
  border-radius: 50%;
  width: 4.375rem;
  height: 4.375rem;
  z-index: 2;
  top: 1.8125rem;
  left: 1.8125rem;
}
@media only screen and (max-width: 767.98px) {
  .company-page .section-globe .main-image-block .circle-firm-1 {
    left: 0;
  }
}
@media only screen and (max-width: 400px) {
  .company-page .section-globe .main-image-block .circle-firm-1 {
    width: 3.4375rem;
    height: 3.4375rem;
  }
}
.company-page .section-globe .main-image-block .circle-firm-1 img {
  width: 100%;
  animation: pulse-icon-enlarge 2.5s ease infinite;
}
.company-page .section-globe .main-image-block .circle-firm-2 {
  position: absolute;
  background-color: #36af50;
  border-radius: 50%;
  width: 3.125rem;
  height: 3.125rem;
  z-index: 2;
  top: 4.0625rem;
  right: 1.5rem;
}
@media only screen and (max-width: 767.98px) {
  .company-page .section-globe .main-image-block .circle-firm-2 {
    right: 0.5rem;
  }
}
@media only screen and (max-width: 400px) {
  .company-page .section-globe .main-image-block .circle-firm-2 {
    width: 1.875rem;
    height: 1.875rem;
  }
}
.company-page .section-globe .main-image-block .circle-firm-2 img {
  width: 100%;
  animation: pulse-icon 2.5s ease infinite;
}
.company-page .section-globe .main-image-block .circle-firm-3 {
  position: absolute;
  background-color: #36af50;
  border-radius: 50%;
  width: 4.375rem;
  height: 4.375rem;
  z-index: 2;
  right: -1rem;
  bottom: 5.9375rem;
}
@media only screen and (max-width: 767.98px) {
  .company-page .section-globe .main-image-block .circle-firm-3 {
    right: -2rem;
  }
}
@media only screen and (max-width: 400px) {
  .company-page .section-globe .main-image-block .circle-firm-3 {
    width: 3.4375rem;
    height: 3.4375rem;
  }
}
.company-page .section-globe .main-image-block .circle-firm-3 img {
  width: 100%;
  animation: pulse-icon-enlarge 2.5s ease infinite;
}
.company-page .section-globe .main-image-block .circle-firm-4 {
  position: absolute;
  background-color: #36af50;
  border-radius: 50%;
  width: 3.125rem;
  height: 3.125rem;
  z-index: 2;
  right: 7rem;
  bottom: -0.375rem;
}
@media only screen and (max-width: 767.98px) {
  .company-page .section-globe .main-image-block .circle-firm-4 {
    bottom: -1rem;
  }
}
@media only screen and (max-width: 400px) {
  .company-page .section-globe .main-image-block .circle-firm-4 {
    width: 1.875rem;
    height: 1.875rem;
  }
}
.company-page .section-globe .main-image-block .circle-firm-4 img {
  width: 100%;
  animation: pulse-icon 2.5s ease infinite;
}
.company-page .section-globe .main-image-block .circle-firm-5 {
  position: absolute;
  background-color: #36af50;
  border-radius: 50%;
  width: 4.375rem;
  height: 4.375rem;
  z-index: 2;
  left: 5.5625rem;
  bottom: 0;
}
@media only screen and (max-width: 767.98px) {
  .company-page .section-globe .main-image-block .circle-firm-5 {
    bottom: -1rem;
  }
}
@media only screen and (max-width: 400px) {
  .company-page .section-globe .main-image-block .circle-firm-5 {
    width: 3.4375rem;
    height: 3.4375rem;
  }
}
.company-page .section-globe .main-image-block .circle-firm-5 img {
  width: 100%;
  animation: pulse-icon-enlarge 2.5s ease infinite;
}
.company-page .section-globe .main-image-block .circle-firm-6 {
  position: absolute;
  background-color: #36af50;
  border-radius: 50%;
  width: 3.125rem;
  height: 3.125rem;
  z-index: 2;
  bottom: 7.1825rem;
  left: -0.625rem;
}
@media only screen and (max-width: 767.98px) {
  .company-page .section-globe .main-image-block .circle-firm-6 {
    left: -1.2rem;
  }
}
@media only screen and (max-width: 400px) {
  .company-page .section-globe .main-image-block .circle-firm-6 {
    width: 1.875rem;
    height: 1.875rem;
  }
}
.company-page .section-globe .main-image-block .circle-firm-6 img {
  width: 100%;
  animation: pulse-icon 2.5s ease infinite;
}
.company-page .section-globe .main-image-block .text {
  position: absolute;
  display: flex;
  align-items: flex-end;
  left: -5.6875rem;
  top: 10.8125rem;
  z-index: 8;
}
@media only screen and (max-width: 767.98px) {
  .company-page .section-globe .main-image-block .text {
    top: -2rem;
    left: 8.6rem;
  }
}
.company-page .section-globe .main-image-block .text img {
  width: 2.25rem;
  height: 2.25rem;
}
.company-page .section-globe .main-image-block .text p {
  color: #ffffff;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 0.8125rem;
  line-height: 1.0625rem;
  max-width: 7.25rem;
  margin: 0;
}
.company-page .section-globe .main-image-block .text p span {
  text-transform: uppercase;
}
.company-page .section-globe .main-image-block .round-group {
  width: 100%;
  height: 100%;
}
.company-page .section-globe .main-image-block .round-group .round {
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.company-page .section-globe .main-image-block .round-group .round.round-1 {
  width: 13.0625rem;
  height: 13.0625rem;
  background-color: #ffffff;
  opacity: 1;
  z-index: 4;
  margin: -6.53125rem 0 0 -6.53125rem;
}
@media only screen and (max-width: 375px) {
  .company-page .section-globe .main-image-block .round-group .round.round-1 {
    width: 8.45rem;
    height: 8.45rem;
    margin: -4.225rem 0 0 -4.225rem;
  }
}
.company-page .section-globe .main-image-block .round-group .round.round-2 {
  width: 17.75rem;
  height: 17.75rem;
  background-color: #675087;
  z-index: 3;
  margin: -8.875rem 0 0 -8.875rem;
}
@media only screen and (max-width: 375px) {
  .company-page .section-globe .main-image-block .round-group .round.round-2 {
    width: 12.45rem;
    height: 12.45rem;
    margin: -6.225rem 0 0 -6.225rem;
  }
}
.company-page .section-globe .main-image-block .round-group .round-arrow-1 {
  width: 21.375rem;
  height: 21.375rem;
  border-radius: 50%;
  margin: -10.6875rem 0 0 -10.6875rem;
  border: 0.125rem dashed #675087;
  animation: circle-rotate 50s infinite linear;
  z-index: 2;
}
@media only screen and (max-width: 400px) {
  .company-page .section-globe .main-image-block .round-group .round-arrow-1 {
    width: 15.375rem;
    height: 15.375rem;
    margin: -7.6875rem 0 0 -7.6875rem;
  }
}
.company-page .section-globe .main-image-block .round-group .round-arrow-2 {
  width: 29.75rem;
  height: 29.75rem;
  border-radius: 50%;
  border: 0.125rem solid #675087;
  z-index: 2;
}
@media only screen and (max-width: 400px) {
  .company-page .section-globe .main-image-block .round-group .round-arrow-2 {
    width: 21.375rem;
    height: 21.375rem;
  }
}
.training-page .table-holder .table tr:nth-child(odd) td {
  background: #f5f5f5;
}
.training-page .table-holder .table td,
.training-page .table-holder .table th {
  padding: 0.3rem;
  vertical-align: middle;
  border: 0;
  font-size: 1rem;
}
.training-page .table-holder .table td:last-child,
.training-page .table-holder .table th:last-child {
  padding-right: 2rem;
}
.training-page .table-holder .table td:first-child,
.training-page .table-holder .table th:first-child {
  padding-left: 2rem;
}
.training-page .table-holder .table td a,
.training-page .table-holder .table th a {
  color: #44286c;
  text-decoration: underline;
  font-weight: 700;
}
.training-page .table-holder .table td a:hover,
.training-page .table-holder .table th a:hover {
  color: #36af50;
}
.training-page .table-holder .table .city {
  padding-left: 1.6rem;
  font-size: 1rem;
  font-family: 'Lato', sans-serif;
  margin: 0;
  color: #0e0e0e;
  font-weight: 400;
  position: relative;
}
.training-page .table-holder .table .city::before {
  content: "\f3c5";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  color: #36af50;
  width: 0.8rem;
  height: auto;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
}
.training-page .table-holder .table .date {
  padding-left: 1.6rem;
  font-size: 1rem;
  font-family: 'Lato', sans-serif;
  line-height: 1.5625rem;
  margin: 0;
  color: #0e0e0e;
  font-weight: 400;
  position: relative;
}
.training-page .table-holder .table .date::before {
  content: "\f073";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  left: 0;
  color: #36af50;
}
.training-page .ref_link {
  color: #36af50;
  font-weight: 700;
}
.training-page em {
  color: #44286c;
  font-style: normal;
}
.training-page h5 {
  font-family: 'Lato', sans-serif;
  font-size: 1.25rem;
  color: #44286c;
  font-weight: 700;
  line-height: 1.875rem;
  margin-bottom: 1.3rem;
}
.padd-l-col {
  padding-left: 4.6rem;
}
@media only screen and (min-width: 767.99px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .padd-l-col {
    padding-left: 1rem;
  }
}
.privacy-page {
  text-align: left;
}
.privacy-page h3 {
  font-family: 'Lato', sans-serif;
  font-size: 1.5rem;
  color: #44286c;
  font-weight: 700;
  line-height: 2.125rem;
  margin-bottom: 1.8rem;
}
.privacy-page ul {
  list-style: none;
}
.privacy-page ul li {
  margin-bottom: 1rem;
}
.mission-section h2 {
  margin-top: 3rem;
  text-align: center;
  text-transform: uppercase;
}
.mission-section ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0rem 0rem 0rem 0rem;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
@media only screen and (min-width: 767.99px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .mission-section ul {
    padding: 0;
  }
}
@media only screen and (max-width: 575.98px) {
  .mission-section ul {
    flex-direction: column;
  }
}
.mission-section ul li {
  width: 47%;
  position: relative;
  padding: 0rem 0rem 0.75rem 3.75rem;
  font-size: 1.125rem;
  line-height: 1.875rem;
  color: #0e0e0e;
  font-weight: 400;
  text-align: left;
}
@media only screen and (max-width: 575.98px) {
  .mission-section ul li {
    width: 100%;
  }
}
.mission-section ul li:before {
  content: '';
  position: absolute;
  left: 0;
  width: 2.0625rem;
  height: 2.25rem;
  background: url(../img/icon-1.png) no-repeat;
  background-size: contain;
}
.benefits-page {
  text-align: center;
}
.benefits-page h2 {
  color: #44286c;
  font-family: 'Lato', sans-serif;
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 0.72px;
  text-transform: uppercase;
  margin-bottom: 2rem;
}
.benefits-page ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 1.5625rem 0rem 0rem 0rem;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
@media only screen and (min-width: 767.99px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .benefits-page ul {
    padding: 0;
  }
}
@media only screen and (max-width: 575.98px) {
  .benefits-page ul {
    flex-direction: column;
  }
}
.benefits-page ul li {
  width: 47%;
  position: relative;
  padding: 0rem 0rem 0.75rem 3.75rem;
  font-size: 1.125rem;
  line-height: 1.875rem;
  color: #0e0e0e;
  font-weight: 400;
  text-align: left;
}
@media only screen and (max-width: 575.98px) {
  .benefits-page ul li {
    width: 100%;
  }
}
.benefits-page ul li:before {
  content: '';
  position: absolute;
  left: 0;
  width: 2.0625rem;
  height: 2.25rem;
  background: url(../img/icon-1.png) no-repeat;
  background-size: contain;
}
.location-page .contact {
  padding: 0rem 0rem 2.5rem 0rem;
}
@media only screen and (max-width: 767.98px) {
  .location-page .contact {
    padding: 0rem 0.9375rem 2.5rem 0.9375rem;
  }
}
.location-page .contact dl {
  margin: 0rem 0rem 0.625rem 0rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  line-height: 2rem;
}
.location-page .contact dl dt {
  color: #36af50;
  width: 2.0625rem;
  font-size: 1.25rem;
}
.location-page .contact dl dd {
  margin: 0;
  color: #0e0e0e;
  font-size: 1.25rem;
}
.location-page .contact dl dd a {
  color: #0e0e0e;
}
.location-page .contact dl dd.name {
  color: #44286c;
  font-weight: 700;
}
.steps-page.steps-page-gray {
  background-color: #f7f7f7;
  padding: 6.25rem 0rem 3.125rem 0rem;
}
@media only screen and (max-width: 767.98px) {
  .steps-page.steps-page-gray {
    padding: 1.875rem 0rem 3.125rem 0rem;
  }
}
.steps-page h2 {
  color: #44286c;
  font-family: Lato, sans-serif;
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 0.72px;
  text-transform: uppercase;
  margin-bottom: 2rem;
}
.steps-page .purpose_list li {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 2.75rem;
  position: relative;
  outline: none;
}
@media only screen and (min-width: 767.99px) and (max-width: 1199.98px), only screen and (max-width: 767.98px) {
  .steps-page .purpose_list li {
    align-items: flex-start;
  }
}
.steps-page .purpose_list li:last-child::before {
  display: none;
}
.steps-page .purpose_list li::before {
  content: "";
  position: absolute;
  left: 1.25rem;
  top: 0;
  height: 100%;
  background-color: #36af50;
  width: 1px;
  flex-shrink: 0;
}
.steps-page .purpose_list .circle {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  border: 0.25rem solid #ffffff;
  background-color: #36af50;
  color: #ffffff;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  margin-right: 1.5rem;
  flex-shrink: 0;
}
.steps-page .purpose_list .circle::before {
  content: "";
  width: 2.5625rem;
  height: 2.5625rem;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  flex-shrink: 0;
  background-color: transparent;
  border: 1px solid #36af50;
}
.steps-page .purpose_list .text {
  color: #44286c;
  font-family: 'Lato', sans-serif;
  font-size: 1.25rem;
  font-weight: 700;
  letter-spacing: 0.4px;
  text-decoration: none;
  line-height: 2rem;
}
.steps-page .purpose_list .text a {
  color: #44286c;
  font-family: 'Lato', sans-serif;
  font-size: 1.25rem;
  font-weight: 700;
  letter-spacing: 0.4px;
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.steps-page .purpose_list .text a i {
  font-size: 0.6875rem;
}
.steps-page .purpose_list .text .content {
  margin-top: 2rem;
}
.steps-page .purpose_list .text .content .content-list {
  list-style: none;
  margin: 0;
  padding: 0rem 0rem 0rem 0rem;
}
@media only screen and (min-width: 767.99px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .steps-page .purpose_list .text .content .content-list {
    padding: 0;
  }
}
@media only screen and (max-width: 575.98px) {
  .steps-page .purpose_list .text .content .content-list {
    flex-direction: column;
  }
}
.steps-page .purpose_list .text .content .content-list li {
  position: relative;
  padding: 0rem 0rem 1.25rem 2.5rem;
  font-size: 1.25rem;
  line-height: 2rem;
  color: #44286c;
  font-weight: 400;
  text-align: left;
}
@media only screen and (max-width: 575.98px) {
  .steps-page .purpose_list .text .content .content-list li {
    width: 100%;
  }
}
.steps-page .purpose_list .text .content .content-list li:before {
  content: '';
  position: absolute;
  left: 0;
  width: 1.5625rem;
  height: 1.5625rem;
  background: url(../img/icon-1.png) no-repeat;
  background-size: contain;
}
.steps-page .purpose_list .text .content .content-list li:last-child::before {
  display: block;
}
h5 {
  font-family: 'Lato', sans-serif;
  font-size: 1.25rem;
  color: #44286c;
  font-weight: 700;
  line-height: 1.875rem;
  margin-bottom: 1.3rem;
}
.contact-page {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
@media only screen and (min-width: 767.99px) and (max-width: 1199.98px), only screen and (max-width: 767.98px) {
  .contact-page {
    margin: 0;
  }
}
@media only screen and (min-width: 767.99px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .contact-page {
    flex-direction: column;
    align-items: center;
  }
}
.contact-page .contact {
  padding: 6.125rem 0rem 0rem 0rem;
}
@media only screen and (min-width: 767.99px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .contact-page .contact {
    padding: 4rem 0 3rem;
  }
}
.contact-page .contact h2 {
  font-size: 1.875rem;
  line-height: 2.25rem;
  margin: 0rem 0rem 1.5625rem 0rem;
}
.contact-page .contact dl {
  margin: 0rem 0rem 0.625rem 0rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  line-height: 1.5625rem;
}
.contact-page .contact dl dt {
  color: #36af50;
  width: 2.0625rem;
  font-size: 0.875rem;
}
.contact-page .contact dl dd {
  margin: 0;
  color: #0e0e0e;
  font-size: 1.0625rem;
}
.contact-page .contact dl dd a {
  color: #0e0e0e;
}
.contact-page .form-holder {
  background: #ffffff;
  box-shadow: 0 0.563rem 1.313rem rgba(0, 0, 0, 0.11);
  width: 50rem;
  flex-shrink: 0;
  position: relative;
  margin-top: -4.125rem;
  padding: 3.75rem 9.375rem 4.375rem 9.375rem;
}
@media only screen and (min-width: 767.99px) and (max-width: 1199.98px) {
  .contact-page .form-holder {
    margin-top: 0;
  }
}
@media only screen and (max-width: 767.98px) {
  .contact-page .form-holder {
    width: 90%;
    margin-top: 0;
  }
}
@media only screen and (max-width: 575.98px) {
  .contact-page .form-holder {
    width: 100%;
    margin-top: 0;
    padding: 2rem 2rem;
  }
}
.contact-page .form-holder .ttl {
  text-align: center;
  font-size: 1.0625rem;
  line-height: 1.875rem;
  margin: 0rem 0rem 2.1875rem 0rem;
}
.contact-page .form-holder .ttl h3 {
  color: #44286c;
  font-size: 1.875rem;
  line-height: 2.25rem;
  margin: 0rem 0rem 1.5625rem 0rem;
  font-weight: 700;
  margin: 0rem 0rem 0.8125rem 0rem;
}
.contact-page .form-holder .city {
  padding-left: 1.6rem;
  font-family: 'Lato', sans-serif;
  margin: 0;
  color: #0e0e0e;
  font-weight: 400;
  position: relative;
}
.contact-page .form-holder .city::before {
  content: "\f3c5";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  color: #36af50;
  width: 0.8rem;
  height: auto;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
}
.contact-page .form-holder .date {
  padding-left: 1.6rem;
  font-family: 'Lato', sans-serif;
  margin: 0;
  color: #0e0e0e;
  font-weight: 400;
  position: relative;
}
.contact-page .form-holder .date::before {
  content: "\f073";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  left: 0;
  color: #36af50;
}
