.block-toggle-ttl{
    .padding(12,0,12,0);
    background: none;
    border: 0;
    width: 100%;
    color: @black;
    .Lato;
    .font-size(20);
    font-weight: 400;
    .line-height(32);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    position: relative;
    @media @mobile{
      .font-size(16);
    }
    &.wt-pad{
      .padding(12,0,0,0);
    }
    strong{
      max-width: 90%;
      font-weight: 700;
      text-align: left;
    }
    &:focus{
      outline: none;
    }
    .under-ttl{
      position: absolute;
      left: 0;
      .Lato;
      font-size: 15px;
      font-weight: 400;
      line-height: 30px;
      bottom: 0;
    }
    .plus-minus {
			.square(1.5rem);
			position: relative;
			cursor: pointer;
			&:before {
				content: '';
				position: absolute;
				left:0;
				top:0.688rem;
				height:0.125rem;
				width:100%;
				background: @secondary;
			}
			&:after {
				content: '';
				position: absolute;
				left:0.688rem;
				top:0;
				width:0.125rem;
        height:100%;
        opacity: 0;
				background: @secondary;
			}
		}
  }
  .block-toggle-ttl.collapsed {
		.plus-minus {
			&:after {
				opacity: 1;
			}
		}
  }