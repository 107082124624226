/* banner start */
.banner {
  position: relative;
  overflow: hidden;
  .margin(80,0,0,0);
  .video-holder {
    position: relative;
    width:100%;
    height: 100%;
    &:before {
      content: '';
      position: absolute;
      .full;
      background: fade(@primary, 40%);
      z-index: 5;
    }
    video {
      display: block;
      width: 100%;
      height: auto;
      @media @mobile-xs {
        height: 20rem;
        width:auto;
      }
    }
  }
  .text {
    .full;
    color:@white;
    display: flex;
    justify-content: center;
    align-items: center;
    .text-center;
    z-index: 10;
    .line-1 {
      display: block;
      .font-size(60);
      .line-height(70);
      font-weight: 700;
      .margin(0,0,30,0);
      @media @tablet-portrait, @mobile {
        .font-size(40);
        .line-height(50);
        .margin(0,0,20,0);
      }
      @media @mobile-xs {
        .font-size(30);
        .line-height(40);
        .margin(0,0,15,0);
      }
    }
    .line-2 {
      display: block;
      .font-size(25);
      .line-height(30);
      font-weight: 700;
      .margin(0,0,50,0);
      @media @tablet-portrait, @mobile {
        .font-size(20);
        .line-height(25);
        .margin(0,0,30,0);
      }
      @media @mobile-xs {
        .font-size(16);
        .line-height(20);
        .margin(0,0,20,0);
      }
    }
    .btn {
      .padding(0,40,0,40);
    }
    
  }
  .container{
    max-width: 51.125rem;
  }
}
/* banner end */



/* section-testim start */
.section-testim {
 
background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: 50% 0;
  .padding(90,0,100,0);
  position: relative;
  .font-size(18);
  .line-height(34);
  color:@white;
  .text-center;
  @media @tablet, @mobile {
    background-attachment: scroll;
  }
  @media @mobile-xs {
    padding: 4rem 0;
  }
  
  .container {
    position: relative;
    z-index: 3;
  }
  h2 {
    color:@primary;
    font-weight: 700;
    .font-size(36);
    .line-height(46);
    .margin(0,0,15,0);
  }
  .view-all {
    .ttu;
    .inline-b;
    color:@secondary;
    .font-size(16);
    .line-height(26);
    font-weight: 700;
    border-bottom: 2px solid @secondary;
    &:hover {
      color:@white;
      text-decoration: none;
      border-color: @white;
    }
  }
  .testim-slider {
    .margin(40,0,20,0);
    @media @mobile-xs {
      margin: 1rem 0;
    }
    .item-inner {
      .margin(33,45,50,40);
      position: relative;
      background: @white;
      border-radius: 0.5rem;
      box-shadow: 1rem 1rem 2rem rgba(0,0,0,0.33);
      .font-size(18);
      .line-height(30);
      color:@text;
      .padding(40,30,22,60);
      text-align: left;
      @media @mobile-xs {
        box-shadow: 0.5rem 0.5rem 1rem rgba(0,0,0,0.33);
        margin: 1.5rem;
        padding: 2.5rem 1.5rem 1.5rem 2.5rem;
      }
      .quote {
        position: absolute;
        .width(87);
        height: auto;
        .left(-40);
        .top(-33);
        @media @mobile-xs {
          top:-1.5rem;
          left:-1.5rem;
          width:4.5rem;
        }
      }
      .text-scroll {
        .height(110);
        .margin(0,0,0,0);
        @media @mobile{
          height: auto;
        }
        p{
          margin: 0;
        }
      }
      .author {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color:#0e0e0e;
        .line-height(22);
        min-height: 3.5rem;
        
        img {
          width:auto;
          max-width:5.625rem;
          height: auto;
          max-height: 5rem;
          flex-shrink: 0;
          .margin(0,0,0,10);
        }
      }
    }
    .slick-prev,
    .slick-next {
      position: absolute;
      .left(-35);
      .top(130);
      .font-size(40);
      color:@primary;
      cursor: pointer;
      @media @tablet-portrait, @mobile {
        .left(0);
      }
      @media @mobile-xs {
        left:-0.5rem;
        .font-size(30);
      }
      &:hover {
        color:@secondary;
      }
    }
    .slick-next {
      left: auto;
      .right(-35);
      @media @tablet-portrait, @mobile {
        .right(0);
      }
      @media @mobile-xs {
        right:-0.5rem;
      }
    }
  }
}
/* section-testim end */

//section chemes
.section-schemes{
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-attachment: fixed;
  .padding(90,0,120,0);
  position: relative;
  .font-size(18);
  .line-height(34);
  color:@white;
  .text-center;
  @media @tablet, @mobile {
    background-attachment: scroll;
  }
  @media @mobile-xs {
    padding: 4rem 0;
  }
  &:before {
    content: '';
    position: absolute;
    .full;
    background: fade(@primary, 85%);
    z-index: 2;
  }
  .container {
    position: relative;
    z-index: 3;
  }
  h2{
    .Lato;
    .font-size(36);
  .line-height(50);
  font-weight: 700;
  color:@white;
  margin-bottom: 2.5rem;
  }
  p{
    max-width: 55.625rem;
    text-align: center;
    margin: 0 auto;
      .Lato;
      color: @white;
      .font-size(18);
      .line-height(38);
  }
  .items-wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 50rem;
    margin: 0 auto;
    margin-top: 3.75rem;
    @media @tablet,@mobile{
      max-width: 100%;
      flex-wrap: wrap;
    }
    .item{
      display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      @media @mobile{
        width: 33%;
        
        margin-bottom: 2rem;
      }
      &:hover{
        text-decoration: none;
        .icon{
          background-color: @white;
        background-position: 50% 100%;
        }
      }
    }
    .text{
      p{
        .Lato;
        color: @white;
        .font-size(18);
        margin-top: 1rem;
        .line-height(20);
        font-weight: 700;
        .ttu;
      }
    }
    .icon{
      width: 4.375rem;
      height: 4.375rem;
      border-radius: 50%;
      background-color: @secondary;
      background-repeat: no-repeat;
      background-position: 50% 0%;
      background-size: auto 8.75rem;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover{
        background-color: @white;
        background-position: 50% 100%;
      }
    }
  }
}
.section-map{
  min-height:55rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  .padding(96,0,56,0);
  h2{
    text-align: center;
  }
  p{
    max-width: 50rem;
    margin: 0 auto;
    text-align: center;
  }
  .btn-wrap{
    display: flex;
    justify-content: center;
    align-items: center;
    .margin(37,0,63,0);
    .btn{
      .margin(0,14,0,14);
      .width(250);
    }
  }
  .training-cources-wrap{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    .item{
      border-radius: 0.25rem;
        background-color: @white;
        box-shadow: -1.113px 7.922px 10px 0px rgba(0, 0, 0, 0.11);
        max-width: 35.25rem;
        width: 47.5%;
        min-height: 3rem;
        margin-bottom: 0.625rem;
        @media @tablet-portrait,@mobile{
          width: 100%;
          max-width:100%;
        }
        
          .item-inner{
            display: flex;
            justify-content: space-between;
            align-items: center;
           
            .cource-name{
              .padding(16,10,16,65);
              position: relative;
                .font-size(16);
                .Lato;
                color: @primary;
                font-weight: 700;
                .ttu;
                line-height: 1.25;
                @media @mobile{
                  max-width: 70%;
                }
              &:before{
                content: "";
                position: absolute;
                left: 0.5rem;
                .pos-centerY;
                .width(34);
                .height(34);
                background-color: @secondary;
                border-radius: 50%;
              }
              &::after{
                content: "";
                .pos-centerY;
                background-image: url(../img/icon-27.png);
                .width(34);
                .height(34);
                left: 0.5rem;
                background-position: center;
                background-size: contain;
              }
              &:hover{
                text-decoration: none;
                &:before{
                  background-color: @primary;
                
              }
              }
            }
        }
        .accordion-toggle{
          color:@secondary;
          .Lato;
          .font-size(15);
          font-weight: 700;
          &:hover{
              text-decoration: none;
              cursor: pointer;
          } 
          &.open{
              .fa-chevron-right{
                  display: inline-block;
                  transition: all .3s ease;
                  margin-right: 1rem;
                  margin-left: 1.6rem;
                  @media @mobile{
                    margin-left: 1rem;
                  }
              }
              .fa-chevron-down{
                  display: none;
              }
              // .item-inner{
              //   .cource-name{
              //     &:before{
              //       background-color: @primary;
              //     }
              //   }
              // }
              &.collapsed{
                  .fa-chevron-right{
                      display: none;
                  }
                  .fa-chevron-down{
                      transition: all .3s ease;
                      display: inline-block;
                      margin-right: 0.8rem;
                      margin-left: 1.6rem;
                      @media @mobile{
                        margin-left: 1rem;
                      }
                  }
                  // .item-inner{
                  //   .cource-name{
                  //     &:before{
                  //       background-color: @secondary;
                  //     }
                  //   }
                  // }
              }
          } 
          i{
            color:@primary;
            .font-size(18);
              vertical-align: middle;
          }
          
      }
      .accordion-body{
        .padding(0,10,25,65);
        @media @mobile{
          .padding(0,10,25,15);
        }
        h4{
          .font-size(16);
            .Lato;
            .line-height(25);
            margin-bottom: 1rem;
            color: @text;
            font-weight: 700;
        }
        .courses-info-wrap{
          max-height: 11.625rem;
          @media @mobile{
            max-height: unset;
          }
          .courses-list{
            list-style: none;
            .courses-item{
              display: flex;
              justify-content: flex-start;
              align-items: center;
              .city{
                padding-left: 1.6rem;
                width: 40%;
                .font-size(15);
                .Lato;
                .line-height(25);
                margin:0;
                color: @text;
                font-weight: 400;
                position: relative;
              &::before{
                content:"\f3c5";
                .pos-centerY;
                left: 0;
                color: @secondary;
                width: 0.8rem;
                height: auto;
                font-family: 'Font Awesome 5 Free';
                font-weight: 900;
              }
              }
              .date{
                padding-left: 1.6rem;
                width: 60%;
                .font-size(15);
                .Lato;
                .line-height(25);
                margin:0;
                color: @text;
                font-weight: 400;
                position: relative;
                &::before{
                  content:"\f073";
                  .pos-centerY;
                  font-family: 'Font Awesome 5 Free';
                  font-weight: 900;
                  left: 0;
                  color: @secondary;
                }
              }
            }
          }
        }
      }
    }
  }
}
.section-screens{
  background-repeat: no-repeat;
  background-position:  50% 0;
  background-size: cover;
  background-attachment: fixed;
  .section-inner {
   
    .padding(100,0,100,0);
    position: relative;
    .font-size(17);
    .line-height(30);
    color:@white;
  }
  h2 {
    margin-bottom: 1.5rem;
    img{
      .width(327);
      height: auto;
      margin-left: -4px;
    }
  }
  p {
    .margin(0,0,32,0);
    .font-size(17);
    .line-height(30);
    color:@white;
  }
  .images {
    position: absolute;
    top: 21%;
    right: 2%;
    width: 44%;
    @media @tablet-portrait, @mobile {
      width:90%;
      margin: 0 auto;
      top: auto;
      right: auto;
      position: relative;
    }
    img {
      width:100%;
      height: auto;
      &.screen {
        position: absolute;
        left:0;
        top:0;
      }
    }
  }
 
  .screen-images-btn{
    position: absolute;
    top:75%;
    right: 7%;
    z-index: 30;
    @media @tablet{
      top:70%;
    }
    @media @tablet-portrait,@mobile{
      position: relative;
      margin-left: 3rem;
      top: unset;
      right: unset;
      margin-bottom: 3rem;
    }
  }
}
.section-sertification-process{
  .padding(166,0,58,0);
  background-position: 50% 0;
  background-size: 100% auto;
  background-repeat: no-repeat;
  @media @mobile{
    .padding(46,0,40,0);
  }
  .text-side{
    .padding(49,0,0,0);
    h1{
      .Lato;
      .font-size(36);
    .line-height(60);
    color: @primary;
    margin-bottom: 1.5rem;
    }
    .ref-link{
      color: @secondary;
      font-weight: 700;
    }
  }
  
  .main-image-block{
    position: relative;
    width: 25.9372rem;
    height: 25.9372rem;
    margin-left: auto;
    @media @mobile{
      margin: 0 auto;
      margin-top: 3rem;
    }
    @media only screen and (max-width: 375px){
      width:21.4rem;
      height:21.4rem;
    }
    .semi{
      position: absolute;
      top: 1.5rem;
      .pos-centerX;
      width: 22.5rem;
      height: auto;
      @media only screen and (max-width: 380px){
        top:0;
        width: 20.5rem;
      }
      img{
        width: 100%;
        height: auto;
      }
    }
    .icons{
      .pos-centerY;
      width: 100%;
      height: auto;
      position: relative;
      .icon-right{
        position: absolute;
        right: -3.1rem;
        top:-0.6rem;
        .width(149);
        @media only screen and (max-width: 380px){
          .width(100);
        }
      }
      .icon-left{
        position: absolute;
        left: -3.1rem;
        .width(140);
        top:-0.6rem;
        @media only screen and (max-width: 380px){
          .width(100);
        }
      }
    }
    .circle{
      position: absolute;
      width: 10rem;
      height: 8rem;
      border-radius: 50%;
      .pos-center;
      background-color:transparent;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      z-index: 10;
      p{
        color:@white;
        text-align: center;
        .Lato;
        font-weight: 700;
        .font-size(18);
        .line-height(30);
        margin: 0;
        &.first-line{
          color: @secondary;
          margin-bottom: 1rem;
        }
        &.second-line{
          .ttu;
        }
      }
    }
  
    .round-group{
      width: 100%;
      height: 100%;
      .round{
        border-radius: 50%;
        .pos-center;
        &.round-1{
          width:6.875rem;
          height: 6.875rem;
          background-color: #44286c;
          opacity: 1;
          z-index: 4;
          margin: -3.4375rem 0 0 -3.4375rem;
          @media only screen and (max-width: 375px){
            width:8.45rem;
            height:8.45rem;
            margin: -4.225rem 0 0 -4.225rem;
          }
        }
        &.round-2{
          width: 10.625rem;
          height: 10.625rem;
          background-color: #44286c;
          opacity: 0.7;
          z-index: 3;
          margin: -5.3125rem 0 0 -5.3125rem;
          @media only screen and (max-width: 375px){
            width: 12.45rem;
            height: 12.45rem;
            margin: -6.225rem 0 0 -6.225rem;
          }
        }
        &.round-3{
          width: 14.3125rem;
          height: 14.3125rem;
          background-color:#44286c;
          opacity: 0.7;
          z-index: 2;
          margin: -7.15625rem 0 0 -7.15625rem;
          box-shadow: 14px 30px 49px 0px rgba(0, 0, 0, 0.33);
          @media only screen and (max-width: 375px){
            width:15.35rem;
            height:15.35rem;
            margin: -7.675rem 0 0 -7.675rem;
          }
        }
        
      }
      .round-arrow-1 {
        width: 17.25rem;
        height: 17.25rem;
        border-radius: 50%;
        margin: -8.625rem 0 0 -8.625rem;
        border: 0.125rem dashed @primary;
        animation: circle-rotate 50s infinite linear;
        z-index: 2;
        &:before {
          content: '';
          width: 1rem;
          height: 1.438rem;
          background: url(../img/navigation.png) no-repeat;
          background-size: contain;
          position: absolute;
          top: 50%;
          -webkit-transform: translateY(-50%);
          -moz-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          -o-transform: translateY(-50%);
          transform: translateY(-50%);
          left: -0.563rem;
        }
    }
    }

  }
}
.section-consultation-services{
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 60% 0;
  background-color: @primary;
  box-sizing: unset;
  .padding(86,0,128,0);
  position: relative;
  .font-size(18);
  .line-height(34);
  @media @mobile{
    background-position: 0% 0%;
    .padding(66,0,88,0);
  }
  h2{
    text-align: center;
    color:@white;
    margin-bottom: 5.125rem;
  }
  .services-list{
    list-style: none;
    margin-bottom: 2.5rem;
    li{
      color: @white;
      .font-size(18);
      .Lato;
     font-weight: 700;
     .line-height(40);
     .padding(0,0,0,51);
     position: relative;
     &::before{
      content:"";
      .pos-centerY;
      background-image: url(../img/svg/icon-3.svg);
      background-repeat: no-repeat;
      background-size: contain;
      height: 2.125rem;
      width: 1.3125rem;
      left: 0;
      color: @secondary;
    }
    a{
      color: @white;
      .font-size(18);
      .Lato;
     font-weight: 700;
     .line-height(40);
    }
    }
  }
}