.contact-block{
padding: 1rem 0rem 5rem 0rem;
background: url(../img/top-decoration.png) no-repeat 50% 0;
background-size: 100% auto;
}
.form-block {
    padding: 3.75rem 5.625rem 4.375rem 5.625rem;
    margin: 0 auto;
    margin-top: -3.9375rem;
    max-width: 60rem;
    box-shadow: 2px 9px 21px 0px rgba(0, 0, 0, 0.11);
    background-color: #ffffff;
    position: relative;
    z-index: 153;
    @media @mobile{
        margin-top: 1rem;
        max-width: 95%;
        padding: 2rem 2rem 2rem 2rem;
    }
    .requared{
        position: absolute;
        right: 1rem;
        top:1rem;
        color: #949494;
        .font-size(14);
    }
    .ttl{
        font-weight: 700;
        .font-size(30);
        color:@primary;
        text-align: center;
        .Lato;
    }
    p{
        .font-size(17);
        text-align:center;
        .line-height(30);
        color:@text;
    }
}

.block-with-pic-wrap{      
    .margin(105,0,0,0);
    @media @tablet-portrait,@mobile{
        .margin(65,0,0,0); 
    }
    &.block-with-pic-wrap-reverse{
        .margin(35,0,0,0);
        @media @tablet-portrait,@mobile{
            .margin(15,0,25,0); 
        }
        .image-wrap-holder{
            margin-left: 8.125rem;
            @media @desktop-xl{
                margin-left: 1rem;
            }
            @media @desktop{
                width: 38.07rem;
                height: 27.1875rem;
                margin-left: 3.5rem;
            }
            @media @tablet,@mobile{
                width: 100%;
                height: auto;
                margin: 0 auto;
            }                
        }
        .text-side{
            text-align: left;
            @media @tablet-portrait,@mobile{
                margin-top: 0;
                margin-bottom: 3rem;
            }
        }
    }
    .image-wrap-holder{
        width: 43.75rem;
        height: 31.25rem;
        box-shadow: 2px 9px 21px rgba(0, 0, 0, 0.11);
        background-color: #ffffff;
        position: relative;
        margin-left: -14.9375rem;
        @media @desktop-xl{
            margin-left: -9rem;
        }
        @media @desktop{
            width: auto;
            height: auto;
            margin-left: -9rem;
        }
        @media @tablet,@mobile{
            width: 100%;
            height: auto;
            margin: 0 auto;
        }
        img{
            width: 100%;            
        }
        .imac-pic{
          position: absolute;
          left: -13%;
          width: 33.125rem;
          height: 29.375rem;
          bottom: 1rem;
          @media @desktop-xl{
            width: 29.125rem;
            height: auto;
            left: -6%;
          }
          @media @desktop-lg{
            width: 29.125rem;
            height: auto;
            left: -6%;
          }
          @media @tablet{
            width: 20.56rem;
            height: auto;
            left: -3%;
          }
          @media @tablet-portrait,@mobile{
            width: 26.56rem;
            height: auto;
            left: -8%;
          }
          @media @mobile-xs{
            width: 14.56rem;
            height: auto;
            left: -8%;
          }
        }
        .circle-pic{
          position: absolute;
          right: 6%;
          width: 12.5rem;
          height: 12.5rem;
          bottom: 2.75rem;
          @media @tablet{
            width: 7.5rem;
          height: 7.5rem;
          bottom: 1.75rem;
          }
          @media @mobile{
            width: 5.5rem;
          height: 5.5rem;
          bottom: 1.75rem;
          }
        }
    }
    h2{
        color: #44286c;
        .Lato;
        .font-size(32);
        font-weight: 700;
        letter-spacing: 0.72px;
        text-transform: uppercase;
        margin-bottom: 1.5rem;
    }
    .text-side{
        text-align: left;
        @media @tablet-portrait,@mobile{
            margin-top: 3rem;
        }
    }
  }
  .purpose_list{
    li{ 
      display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom:2.75rem;
    position: relative;
    outline: none;
    
    @media @tablet,@mobile{
      align-items: flex-start;
    }
    &:last-child{
      &::before{
        display: none;
      }
    }
    &::before{
      content: "";
      position: absolute;
      left: 1.25rem;
      top: 0;
      height: 100%;
      background-color: @secondary;
      width: 1px;
      flex-shrink: 0;
    }  
    }
    .circle{
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      border: 0.25rem solid #ffffff;
      background-color: @secondary;
      color: @white;
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;
     margin-right: 1.5rem;
     flex-shrink: 0;
     &::before{
      content: "";
      width: 2.5625rem;
      height: 2.5625rem;
      .pos-center;
      border-radius: 50%;
      flex-shrink: 0;
      background-color: transparent;
      border: 1px solid @secondary;
     }
    }
    .text{
      a{
        color: #44286c;
        .Lato;
        .font-size(20);
        font-weight: 700;
        letter-spacing: 0.4px;
        text-decoration: none;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        i{
          .font-size(11);
        }
      }
     .content{
       p{
         margin: 0;
       }
     }
    }
  }

  .section-benefits-gray{  
    background-color: #f7f7f7;
    .section-inner {
      .padding(80,0,118,0);
      position: relative;
      .font-size(18);
      .line-height(34);
      color:@text;
      .text-center;
      @media @mobile-xs {
        padding: 4rem 0;
      }
    }
    h2 {
      color:@primary;
      font-weight: 700;
      .ttu;
      .font-size(36);
      .line-height(46);
      .margin(0,0,15,0);
    }
    ul {
      display: flex;
      list-style: none;
      margin: 0;
      .padding(40,25,0,25);
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      @media @tablet-portrait, @mobile {
        padding: 0;
      }
      @media @mobile-xs {
        flex-direction: column;
      }
      li {
        width:44%;
        position: relative;
        .padding(0,0,12,60);
        .font-size(18);
        color:@primary;
        font-weight: 700;
        text-align: left;
        @media @mobile-xs {
          width:100%;
        }
        &:before {
          content: '';
          position: absolute;
          left:0;
          .width(33);
          .height(36);
          background: url(../img/icon-1.png) no-repeat;
          background-size: contain;
  
        }
      }
    }
    
  }

  .section-software-togle{
    background: url(../img/top-decoration.png) no-repeat 50% 0;
    background-size: 100% auto;
    .padding(153,0,92,0);
    @media @mobile{
        .padding(53,0,42,0);
    }
  .btn-wrap{
      padding-top: 3.125rem;
      margin: 0 auto;
      text-align:center;
      .btn{
          .width(250);
      }
  }
  .toggle-block-software-page{
    text-align: left;
    .line-wrap{
      border-bottom: 1px solid #d9d9d9;
    }
    .block-toggle-ttl{
      .padding(45,0,28,0);
      background: none;
      border: 0;
      width: 100%;
      .font-size(36);
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      color: #44286c;
      .Lato;
      font-weight: 700;
      letter-spacing: 0.72px;
      .ttu;
      @media @mobile{
        .font-size(16);
      }
      strong{
        max-width: 90%;
        font-weight: 700;
        text-align: left;
      }
      &:focus{
        outline: none;
      }
      .plus-minus {
        .square(1.875rem);
        position: relative;
        cursor: pointer;
        &:before {
          content: '';
          position: absolute;
          left:0;
          top:0.875rem;
          height:0.125rem;
          width:100%;
          background: @secondary;
        }
        &:after {
          content: '';
          position: absolute;
          left:0.8758rem;
          top:0;
          width:0.125rem;
          height:100%;
          opacity: 0;
          background: @secondary;
        }
      }
    }
    .block-toggle-ttl.collapsed {
      .plus-minus {
        &:after {
          opacity: 1;
        }
      }
    }
    .block-toggle-content{
      .font-size(17);
      p{
        margin-bottom: 2.825rem;
      }
    }
  }
}
.resouces-wrap{
  .padding(90,0,32,0);
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  .item{
     border-radius: 0.25rem;
     background-color: rgb(245, 245, 245);
     .width(540);
     .height(460);
     .padding(0,30,0,30);
     display: flex;
     flex-direction: column;
     @media @tablet-portrait{
       width: 100%;
       margin-bottom: 5rem;
       height: unset;
       min-height: 25rem;
       .padding(0,30,30,30);       
     }
     @media @mobile{
      width: 100%;
      margin-bottom: 5rem;
      height: unset;
      min-height: 25rem;
      .padding(0,30,30,30);

      &:last-child{
        margin-bottom: 1rem;
     }
     }
     .pic{
       position: relative;
       margin: 0 auto;
       .margin(-23,0,36,0);
        border-radius: 0.5rem;
        background-color: rgb(68, 40, 108);
        box-shadow: 2.783px 19.805px 27px 0px rgba(0, 0, 0, 0.17);
        .width(481);
        .height(161);
        background-size: cover;
        background-position: center;   
        @media @tablet-portrait{
          width: 100%;
          .height(221);
        }
        @media @mobile{
          width: 100%;
        }
        span{
          position: absolute;
          left: 2.875rem;
          bottom: 1.5rem;
          color: @white;
          .font-size(36);
          .Lato;
          font-weight: 700;
        }   
     }
     .resources-text{
       max-height:15.18rem;
       @media @tablet-portrait,@mobile{
       height: unset;
      }
     }
     .resources-list{
      list-style: none;
      li{ 
        position: relative;
        .padding(0,0,0,48);
        .font-size(16);
        .line-height(51);
        color: @primary;
        font-weight: 400;
        text-align: left;
        &:before {
          content: '';
          .pos-centerY;
          left:0;
          .width(19);
          .height(29);
          background: url(../img/icon-1.png) no-repeat;
          background-size: contain;
        }
      }
    }
  }
}


.consulting-wrap{
  .padding(90,0,32,0);
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  .item{
    border-radius: 0.25rem;
     background-color: rgb(245, 245, 245);
     width:31%;
     min-height: 29rem;
     display: flex;
     flex-direction: column;
     position: relative;
     margin-bottom: 4.6875rem;
     @media @tablet-portrait{
       width: 100%;
       margin-bottom: 4rem;
       padding-bottom: 4rem;
       height: unset;
       min-height: unset;   
     }
     @media @mobile{
      width: 100%;
      margin-bottom: 5rem;
      height: unset;
      min-height: unset;
      padding-bottom: 4rem;
      &:last-child{
        margin-bottom: 1rem;
     }
     
    }
    &:nth-child(2n) {
      margin-top: 4.6875rem;
      margin-bottom: 0;
      @media @tablet-portrait,@mobile{
        margin-top: 0;
      margin-bottom: 4rem;
      }
    }
    // &.item-second{
    //   margin-top: 4.6875rem;
    //   margin-bottom: 0;
    //   @media @tablet-portrait,@mobile{
    //     margin-top: 0;
    //   margin-bottom: 4rem;
    //   }
    // }
    .image-hold {
      //.padding(0,24,0,24);
      margin: -1.25rem 1.5rem 1.125rem 1.5rem;
      position: relative;
      height: 11.250rem;
      border-radius: 0.5rem;
      box-shadow: 2.783px 19.805px 27px 0px rgba(0, 0, 0, 0.17);
      box-sizing: unset;
      .image {
      height: 100%;
      position: relative;
      overflow: hidden;
      border-radius: 0.5rem;
        .img {
          border-radius: 0.5rem;
          height: 100%;
          background-size: cover;
          background-position: 50% 50%;
          background-repeat: no-repeat;
          transition: all 0.3s ease;
        }
      }
    }
    &:hover{
      .image-hold{
      border-radius: 0.5rem;
        .img{
          border-radius: 0.5rem;
          transform: scale(1.1);
          @media only screen and (min-width: 767.99px) and (max-width: 1199.98px), only screen and (max-width: 767.98px) {
            
            transform: scale(1);
          }
        }
      }
    }
    .main-text{
      .padding(0,50,0,47);
      h3{
        .Lato;
        .font-size(24);
        color: @primary;
        font-weight: 700;
        .line-height(34);
        margin-bottom: 1.3rem;
      }
      p{
        .Lato;
        .font-size(16);
        color: @text;
        font-weight: 400;
        .line-height(26);
      }
    }
    .btn-wrap{
      position: absolute;
      .pos-centerX;
      bottom: -1.4rem;
      .btn{
        &:hover{
          background-color:@primary;
          border-color: @primary;
        }
      }
    }
  }
} 
.training-page{
  .section-map{
    min-height:55rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    .padding(32,0,56,0);
    h2{
      text-align: center;
    }
    p{
      max-width: 50rem;
      margin: 0 auto;
      text-align: center;
    }
    .btn-wrap{
      display: flex;
      justify-content: center;
      align-items: center;
      .margin(37,0,63,0);
      .btn{
        .margin(0,14,0,14);
        .width(250);
      }
    }
  }
  .cource-fee{
    .font-size(18);
    .Lato;
    color: @primary;
    font-weight: 400;
    margin-bottom: 2.75rem;
    @media @mobile-xs{
      .font-size(17);
    }
    span{
      .font-size(24);
      color: @secondary;
      @media @mobile-xs{
        .font-size(21);
      }
    }
  }
  .btn-wrap {
    .margin(57,0,0,75);
    .btn{
      .width(180);
    }
  }
  .training-services-wrap{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    .item{
      border-radius: 0.25rem;
        background-color: @white;
        box-shadow: -1.113px 7.922px 10px 0px rgba(0, 0, 0, 0.11);
        width: 100%;
        min-height: 3rem;
        margin-bottom: 1.1rem;
        overflow: hidden;
        @media @tablet-portrait,@mobile{
          width: 100%;
          max-width:100%;
        }
        .item-inner{
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          padding: 2.275rem 2.375rem 1.6rem 3.0625rem;
          z-index: 3;
          @media @mobile{
            flex-wrap: wrap;
          }
          @media @mobile{
            .padding(29,18,28,29);
          }
          .cource-name{
            position: relative;
              .font-size(24);
              .Lato;
              color: @primary;
              font-weight: 700;
              .ttu;
              line-height: 1.5;
              @media @mobile{
                .font-size(21);
              }
              @media @mobile-xs{
                .font-size(16);
              }
          }
          .cource-fee{
            .font-size(18);
            .Lato;
            color: @primary;
            font-weight: 400;
            margin: 0;
            @media @mobile-xs{
              .font-size(17);
            }
            span{
              .font-size(24);
              color: @secondary;
              @media @mobile-xs{
                .font-size(21);
              }
            }
          }
         .accordion-toggle{
          color:@secondary;
          .Lato;
          .font-size(15);
          font-weight: 700;
          @media @mobile-xs{
            width: 100%;
            margin-bottom: 1rem;
          }
          &:hover{
              text-decoration: none;
              cursor: pointer;
          } 
          &.open{
              .fa-chevron-right{
                  display: inline-block;
                  transition: all .3s ease;
                  @media @mobile{
                    margin-left: 1rem;
                  }
              }
              .fa-chevron-down{
                  display: none;
              }
              .item-inner{
                .cource-name{
                  &:before{
                    background-color: @primary;
                  }
                }
              }
              &.collapsed{
                  .fa-chevron-right{
                      display: none;
                  }
                  .fa-chevron-down{
                      transition: all .3s ease;
                      display: inline-block;
                      @media @mobile{
                        margin-left: 1rem;
                      }
                  }
              }
          } 
          i{
            color:@primary;
            .font-size(18);
            vertical-align: middle;
            margin-right: 0.5rem;
            margin-left: 0.5rem;
            @media @mobile-xs{
              .font-size(15);
            }
          }
        }
      }
      .accordion-body{
        position: relative;
        .padding(0,10,49,48);
        margin-top: -1rem;
        @media @mobile{
          .padding(0,10,25,15);
        }
        .sub-logo-img{
          position: absolute;
          right: -1rem;
          bottom: -5rem;
          .width(290);
          z-index: 0;
        }
        h4{
          .font-size(16);
            .Lato;
            .line-height(25);
            margin-bottom: 1rem;
            color: @text;
            font-weight: 700;
        }
        .courses-info-wrap{
          max-height: unset;
          margin-top: 2rem;
          @media @mobile{
            max-height: unset;
          }
          .courses-list{
            list-style: none;
            margin: 0;
            .courses-item{
              display: flex;
              justify-content: flex-start;
              align-items: center;
              .city{
                padding-left: 1.6rem;
                width: 40%;
                .font-size(15);
                .Lato;
                .line-height(25);
                margin:0;
                color: @text;
                font-weight: 400;
                position: relative;
              &::before{
                content:"\f3c5";
                .pos-centerY;
                left: 0;
                color: @secondary;
                width: 0.8rem;
                height: auto;
                font-family: 'Font Awesome 5 Free';
                font-weight: 900;
              }
              }
              .date{
                padding-left: 1.6rem;
                width: 60%;
                .font-size(15);
                .Lato;
                .line-height(25);
                margin:0;
                color: @text;
                font-weight: 400;
                position: relative;
                &::before{
                  content:"\f073";
                  .pos-centerY;
                  font-family: 'Font Awesome 5 Free';
                  font-weight: 900;
                  left: 0;
                  color: @secondary;
                }
              }
            }
          }
          .all_ref{
            color:@secondary;
            .font-size(16);
            font-weight: 700;
            padding-left: 1.6rem;
          }
        }
        .course-overall-wrap{
          border-radius: 0.25rem;
          background-color: rgb(245, 245, 245);
          .width(526);
         min-height: 13.75rem;
          position: relative;
          margin-top: 2rem;
          @media @tablet,@mobile{
            width: 100%;
            margin-bottom: 2rem;
          }
          .subj{
              position: absolute;
              border: solid 0.25rem rgb(245, 245, 245);
              background-color: @secondary;
              border-radius: 1rem;
              top:-1rem;
              color: @white;
              left: 1rem;
              .padding(4,15,4,15);
              .font-size(16);
              .line-height(16);
              font-weight: 700;
              z-index: 10;
          }
          .overall-list{
            .padding(31,10,20,35);
            .ref_link{
              color:@secondary;
            .font-size(16);
            font-weight: 700;
            padding-left: 2.4375rem;
            }
            ul {
              
              list-style: none;
              margin: 0;
              li {
                .line-height(30);
                position: relative;
                .padding(0,0,10,39);
                .font-size(16);
                color:@text;
                font-weight: 400;
                text-align: left;
                @media @mobile-xs {
                  width:100%;
                }
                &:before {
                  content: '';
                  top: 0;
                  position: absolute;
                  left:0;
                  .width(23);
                  .height(28);
                  background: url(../img/icon-1.png) no-repeat;
                  background-size: contain;
          
                }
              }
            }
          }
        }
        
      }
    }
  }
}
.consulting-inner-wrap{
  display: flex;
  justify-content: space-between;
  @media @tablet-portrait,@mobile{
    flex-wrap: wrap;
  }
  h3{
    .font-size(24);
    .line-height(34);
    font-weight: 700;
    .Lato;
    color: @primary;
    margin-bottom: 2.1875rem;
  }
  .ref-link{
    color: @secondary;
    font-weight: 700;
  }
  .left-side{
    width: 64%;
    display: flex;
    flex-direction: column;
    @media @tablet-portrait,@mobile{
      width: 100%;
      margin-bottom: 2rem;
    }
    h3{
      .font-size(24);
      .line-height(34);
      font-weight: 700;
      .Lato;
      color: @primary;
      margin-bottom: 2.1875rem;
    }
    p {
      font-size: 1.125rem;
      .Lato;
      line-height: 2.125rem;
      color: #0e0e0e;
      margin-bottom: 2rem;
  }
    ul {
      list-style: none;
      margin: 0;
      margin-top: 0.625rem;
      li {
        .line-height(30);
        position: relative;
        .padding(0,0,20,43);
        .font-size(18);
        color:@text;
        font-weight: 400;
        text-align: left;
        @media @mobile-xs {
          width:100%;
        }
        &:before {
          content: '';
          top: 0;
          position: absolute;
          left:0;
          .width(23);
          .height(28);
          background: url(../img/icon-1.png) no-repeat;
          background-size: contain;
  
        }
        a{
          color:@text;
          cursor: pointer;
          &:hover{
            text-decoration: none;
            color: @primary;
          }
        }
      }
    }
    img{
      margin: 0 auto;
      text-align: center;
      .width(476);
      height: auto;
       border-radius: 0.5rem;
      box-shadow: 2.783px 19.805px 27px 0px rgba(0, 0, 0, 0.17);
      margin-bottom: 4rem;
      margin-top: 2rem;
      @media @tablet-portrait,@mobile{
        width: 100%;
        max-width: 30rem;
      }
    }
  }
  .right-side{
    width: 36%;
    @media @tablet-portrait,@mobile{
      width: 100%;
    }
    .sidebar{
      position: relative;
      margin-top: -2rem;
      border-radius: 0.25rem;
      background-color: rgb(245, 245, 245);
      margin-left:7.3125rem;
      min-height: 39.5rem;
      .padding(34,45,32,0);
      @media @tablet-portrait,@mobile{
        margin:0;
        min-height: auto;
        .padding(34,15,32,16);
      }
      h3{
        margin-bottom: 2rem;
        padding-left: 2rem;
        @media @tablet-portrait,@mobile{
          padding-left: 1rem;
        }
      }
      .related-services-list{
        list-style: none;
        li{
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 1.6rem;
          .icon{
              border-radius: 0.5rem;
              box-shadow: 2.783px 19.805px 27px 0px rgba(0, 0, 0, 0.17);
              .width(101);
              .height(91);
              margin-right: 1.6rem;
              margin-left: -1.5rem;
              position: relative;
              flex-shrink: 0;
              overflow: hidden;
              @media @tablet-portrait,@mobile{
                margin-left:0;
              }
              img{
                width: 100%;
                height: auto;
              }
          }
          .text{
            .font-size(16);
            .line-height(26);
            font-weight: 700;
            .Lato;
            a{
            color: @primary;
            }
          }
        }
      }
      .add-more{
        margin: 0 auto;
        text-align:center;
        a{
          .ttu;
          .font-size(16);
         .line-height(16);
         font-weight: 700;
         .Lato;
         color: @secondary;
        }
      }
    }
  }
}
.schemes-page{
  .customise-tabs{
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    @media @mobile{
      .padding(0,10,0,10);
    }
    .nav-tabs-wrap{
      width: 21.6%;
      background-color:#f5f5f5;
      position: relative;
      .padding(20,0,124,0);
      overflow: hidden;
      @media @mobile{
        width: 100%;
      }
      h3{
        padding-left: 2.25rem;
        .font-size(16);
        .line-height(16);
        font-weight: 700;
        .Lato;
        .ttu;
        margin-bottom: 0.625rem;
      }
      .sub-logo-img{
        position: absolute;
        .height(280);
        right: -5rem;
        bottom: -9rem;
      }
      .nav-tabs {
        border:0;
        flex-direction: column;
        .nav-link {
          border: 0;
          border-radius: 0;
          .padding(4,10,4,36);
          .line-height(34);
          .Lato;
          font-weight: 400;
          color: @primary;
          .font-size(16);
          position: relative;
          &.active{
            font-weight: 700;
            &::before{
              content: "";
              position: absolute;
              left: 0;
              width: 0.25rem;
              height: 100%;
              background-color: @secondary;
              top: 0;
            }
          }
      }
      }
    }
    .training-inner-content{
      width: 78.4%;
      .padding(45,16,0,16);
      @media @mobile{
        width: 100%;
        .padding(45,0,0,0);
      }
      h3{
        .Lato;
        font-weight: 700;
        color: @primary;
        .font-size(24);
        .line-height(34);
        margin-bottom: 2.1rem;
      }
      h4{
        .Lato;
        font-weight: 700;
        color: @primary;
        .font-size(20);
        .line-height(34);
        margin-bottom: 2.1rem;
      }
      p{
        .Lato;
        font-weight: 400;
        color: @text;
        .font-size(18);
        .line-height(28);
      }
      ul {
        list-style: none;
        margin: 0;
        margin-top: 0.625rem;
        margin-bottom: 1.5rem;
        li {
          .line-height(30);
          position: relative;
          .padding(0,0,20,43);
          .font-size(18);
          color:@text;
          font-weight: 400;
          text-align: left;
          @media @mobile-xs {
            width:100%;
          }
          &:before {
            content: '';
            top: 0;
            position: absolute;
            left:0;
            .width(23);
            .height(28);
            background: url(../img/icon-1.png) no-repeat;
            background-size: contain;
    
          }
        }
      }
      .box-content{
        margin: 0 auto;
        max-width: 47.5rem;
        @media @mobile{
          max-width: 100%;
        }
        &.box-content-2{
          margin: 0;
          margin-left: auto;
        max-width: 52.75rem;
        }
       
      }
      img{
        margin: 0 auto;
        text-align: center;
        .width(476);
        height: auto;
         border-radius: 0.5rem;
        box-shadow: 2.783px 19.805px 27px 0px rgba(0, 0, 0, 0.17);
        margin-bottom: 2rem;
        margin-top: 2rem;
        @media @tablet-portrait,@mobile{
          width: 100%;
          max-width: 30rem;
        }
      }
      .quantity-wrap{
        display: flex;
        justify-content: space-between;
        margin-bottom: 2rem;
        @media @mobile-xs{
          flex-wrap: wrap;
        }
        .item{
          width: 47%;
          max-width: 16rem;

          position: relative;
          display: flex;
          justify-content: space-between;
          @media @mobile-xs{
            width: 100%;
            max-width: 100%;
            margin-bottom: 2rem;
          }
          .circle{
            .width(70);
            .height(70);
            border-radius:50%;
            background-color: @secondary;
            background-size: 2.3rem;
            background-repeat: no-repeat;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            background-position: center;
            z-index: 5;
            &.circle-1{
              background-color: @primary;
            }
          }
          .line{
            position: absolute;
            .width(190);
            width: 100%;
            height: 1px;
            background-color: @secondary;
            left:0;
            top:2.1875rem;
            z-index: 0;
            @media @mobile-xs{
              left: 0;
            }
            &.line-1{
              background-color: @primary;
            }
          }
          .numbers{
            display: flex;
          //  justify-content: flex-end;
            flex-direction: column;
            align-items: flex-end;
            .name{
              .Lato;
              font-weight: 400;
              color: @text;
              .font-size(18);
              .line-height(34);
              .ttu;
            }
            .num{
              .Lato;
              font-weight: 400;
              color: @text;
              .font-size(46);
              .line-height(62);
            }
          }
        }
      }
    }
  }

}
.company-page{
  h5{
    text-align: center;
    .Lato;
    font-weight: 700;
    color: @primary;
    .font-size(18);
    .line-height(34);
    margin-bottom: 2rem;
  }
  h4{
    text-align: center;
    .Lato;
    font-weight: 400;
    color: @secondary;
    .font-size(30);
    .line-height(40);
    margin-bottom: 0rem;
  }
  .description-sect{
    .padding(118,0,112,0);
    p{
      padding-right: 5rem;
      @media @tablet-portrait,@mobile{
        padding-right: 1rem;
      }
    }
    img{
      margin: 0 auto;
      text-align: center;
      .width(605);
      height: auto;
       border-radius: 0.5rem;
      box-shadow: 2.783px 19.805px 27px 0px rgba(0, 0, 0, 0.17);
     
      @media @tablet-portrait,@mobile{
        width: 100%;
        max-width: 30rem;
      }
    }
  }
.section-globe{
  background-attachment: fixed;
  background-position: 100% center;
  background-size: cover;
  .padding(104,0,100,0);
  @media @tablet-portrait,@mobile{
    background-attachment: scroll;
    .padding(74,0,70,0);
  }
  p{
    color: @white;
    .font-size(18);
    margin-bottom: 2rem;
    .line-height(32);
  }
  .main-image-block{
    position: relative;
    width: 29.75rem;
    height: 29.75rem;
    @media @tablet{
      margin: 0 auto;
      margin-bottom: 3rem;
    }
    @media @mobile{
      margin: 0 auto;
      margin-top: 3rem;
      margin-bottom: 3rem;
      width: 27.75rem;
    height: 27.75rem;
    }
    @media only screen and (max-width: 375px){
      width:21.4rem;
      height:21.4rem;
    }
    

    .icons{
      position: absolute;
      top: 0;
      width: 100%;
      height: auto;
      position: relative;
      .icon-right{
        position: absolute;
        right:2.2rem;
        top:14.25rem;
        .width(65);
         z-index: 4;
        
      }
      .icon-left{
        position: absolute;
        .width(65);
        left: 10.68rem;
        top: 2.9rem;
        z-index: 4;
        @media only screen and (max-width: 380px){
         left:4.68rem;
        }
      }
    }
    .circle{
      position: absolute;
      .width(201);
      .height(201);
      border-radius: 50%;
      .pos-center;
      transform: translate(-50%,-65%);
      background-color:transparent;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      z-index: 10;
      @media only screen and (max-width: 400px){
        .width(161);
      .height(161);
      }
      img{
        .width(201);
       animation: pulse-icon 2.5s ease infinite;
       @media only screen and (max-width: 400px){
        .width(141);
      }
      }
    }
    
    .circle-firm-1{
      position: absolute;
      background-color: @secondary;
      border-radius: 50%;
      .width(70);
      .height(70);
      z-index: 2;
      top:1.8125rem;
      left:1.8125rem;
      @media @mobile{
       left:0;
      }
      @media only screen and (max-width: 400px){
        .width(55);
      .height(55);
      }
      img{
        width: 100%;
        animation: pulse-icon-enlarge 2.5s ease infinite;
      }
    }
    .circle-firm-2{
      position: absolute;
      background-color: @secondary;
      border-radius: 50%;
      .width(50);
      .height(50);
      z-index: 2;
      top:4.0625rem;
      right: 1.5rem;
      @media @mobile{
        right: 0.5rem;
      }
      @media only screen and (max-width: 400px){
        .width(30);
      .height(30);
      }
      img{
        width: 100%;
        animation: pulse-icon 2.5s ease infinite;
      }
    }
    .circle-firm-3{
      position: absolute;
      background-color: @secondary;
      border-radius: 50%;
      .width(70);
      .height(70);
      z-index: 2;
      right: -1rem;
      bottom: 5.9375rem;
      @media @mobile{
        right: -2rem;
      }
      @media only screen and (max-width: 400px){
        .width(55);
      .height(55);
      }
      img{
        width: 100%;
        animation: pulse-icon-enlarge 2.5s ease infinite;
      }
    }
    .circle-firm-4{
      position: absolute;
      background-color: @secondary;
      border-radius: 50%;
      .width(50);
      .height(50);
      z-index: 2;
      right: 7rem;
      bottom: -0.375rem;
      @media @mobile{
        bottom: -1rem;
      }
      @media only screen and (max-width: 400px){
        .width(30);
      .height(30);
      }
      img{
        width: 100%;
        animation: pulse-icon 2.5s ease infinite;
      }
    }
    .circle-firm-5{
      position: absolute;
      background-color: @secondary;
      border-radius: 50%;
      .width(70);
      .height(70);
      z-index: 2;
      left:5.5625rem;
      bottom: 0;
      @media @mobile{
        bottom: -1rem;
      }
      
      @media only screen and (max-width: 400px){
        .width(55);
      .height(55);
      }
      img{
        width: 100%;
        animation: pulse-icon-enlarge 2.5s ease infinite;
      }
    }
    .circle-firm-6{
      position: absolute;
      background-color: @secondary;
      border-radius: 50%;
      .width(50);
      .height(50);
      z-index: 2;
      bottom: 7.1825rem;
      left: -0.625rem;
      @media @mobile{
        left: -1.2rem;
      }
      @media only screen and (max-width: 400px){
        .width(30);
      .height(30);
      }
      img{
        width: 100%;
        animation: pulse-icon 2.5s ease infinite;
      }
    }
    .text{
      position: absolute;
      display: flex;
      align-items: flex-end;
      left: -5.6875rem;
      top: 10.8125rem;
      z-index: 8;
      @media @mobile{
        top:-2rem;
        left:8.6rem;
      }
      img{
        .width(36);
        .height(36);
      }
      p{
        color:@white;
        .Lato;
        font-weight: 400;
        .font-size(13);
        .line-height(17);
       max-width: 7.25rem;
       margin: 0;
        span{
          .ttu;
        }
      }
    }
    .round-group{
      width: 100%;
      height: 100%;
      .round{
        border-radius: 50%;
        .pos-center;
        &.round-1{
          width:13.0625rem;
          height:13.0625rem;
          background-color: @white;
          opacity: 1;
          z-index: 4;
          margin: -6.53125rem 0 0 -6.53125rem;
          @media only screen and (max-width: 375px){
            width:8.45rem;
            height:8.45rem;
            margin: -4.225rem 0 0 -4.225rem;
          }
        }
        &.round-2{
          width: 17.75rem;
          height: 17.75rem;
          background-color: #675087;
          //opacity: 0.7;
          z-index: 3;
          margin: -8.875rem 0 0 -8.875rem;
          @media only screen and (max-width: 375px){
            width: 12.45rem;
            height: 12.45rem;
            margin: -6.225rem 0 0 -6.225rem;
          }
        }
      }
      .round-arrow-1 {
        width: 21.375rem;
        height: 21.375rem;
        border-radius: 50%;
       margin: -10.6875rem 0 0 -10.6875rem;
        border: 0.125rem dashed #675087;
      animation: circle-rotate 50s infinite linear;
        z-index: 2;
        @media only screen and (max-width: 400px){
          width: 15.375rem;
          height: 15.375rem;
          margin: -7.6875rem 0 0 -7.6875rem;
        }
    }
    .round-arrow-2 {
      width: 29.75rem;
      height: 29.75rem;
      border-radius: 50%;
    //  margin: -14.875rem 0 0 -14.875rem;
      border: 0.125rem solid #675087;
   // animation: circle-rotate 50s infinite linear;
      z-index: 2;
      @media only screen and (max-width: 400px){
        width: 21.375rem;
        height: 21.375rem;
       
      }
      }
    }
  }
}

}
.training-page{
  .table-holder{
    .table{
      tr:nth-child(odd){
        td {
        background: #f5f5f5;
        }
      }
      td,th {
        padding: .3rem;
        vertical-align: middle;
        border:0;
        .font-size(16);
        &:last-child{
          padding-right: 2rem;
        }
        &:first-child{
          padding-left:2rem;
        }
        a{
          color: @primary;
          text-decoration: underline;
          font-weight: 700;
          &:hover{
            color: @secondary;
          }
        }
      }
      .city{
        padding-left: 1.6rem;
        .font-size(16);
        .Lato;
        margin:0;
        color: @text;
        font-weight: 400;
        position: relative;
      &::before{
        content:"\f3c5";
        .pos-centerY;
        left: 0;
        color: @secondary;
        width: 0.8rem;
        height: auto;
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
      }
      }
      .date{
        padding-left: 1.6rem;
        .font-size(16);
        .Lato;
        .line-height(25);
        margin:0;
        color: @text;
        font-weight: 400;
        position: relative;
        &::before{
          content:"\f073";
          .pos-centerY;
          font-family: 'Font Awesome 5 Free';
          font-weight: 900;
          left: 0;
          color: @secondary;
        }
      }
    }
  }
  .ref_link{
    color: #36af50;
      font-weight: 700;
  }
 em{
    color: @primary;
    font-style: normal;
  }
  h5{
      .Lato;
      .font-size(20);
      color: @primary;
      font-weight: 700;
      .line-height(30);
       margin-bottom: 1.3rem;
  }
}
.padd-l-col{
  padding-left: 4.6rem;
  @media @tablet-portrait,@mobile{
    padding-left: 1rem;
  }
}
.privacy-page{
  text-align: left;
  h3{
    .Lato;
        .font-size(24);
        color: @primary;
        font-weight: 700;
        .line-height(34);
        margin-bottom: 1.8rem;
  }
  ul{
    list-style: none;
    li{
      margin-bottom: 1rem;
    }
  }
}
.mission-section{
  
  h2{
    margin-top: 3rem;
    text-align: center;
    .ttu;
  }
  ul {
    display: flex;
    list-style: none;
    margin: 0;
    .padding(0,0,0,0);
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    @media @tablet-portrait, @mobile {
      padding: 0;
    }
    @media @mobile-xs {
      flex-direction: column;
    }
    li {
      width:47%;
      position: relative;
      .padding(0,0,12,60);
      .font-size(18);
      .line-height(30);
      color:@text;
      font-weight: 400;
      text-align: left;
      @media @mobile-xs {
        width:100%;
      }
      &:before {
        content: '';
        position: absolute;
        left:0;
        .width(33);
        .height(36);
        background: url(../img/icon-1.png) no-repeat;
        background-size: contain;

      }
    }
  }
}
.benefits-page{
  text-align: center;

  h2{
    color: #44286c;
    .Lato;
    .font-size(32);
    font-weight: 700;
    letter-spacing: 0.72px;
    text-transform: uppercase;
    margin-bottom: 2rem;
}
  ul {
    display: flex;
    list-style: none;
    margin: 0;
    .padding(25,0,0,0);
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    @media @tablet-portrait, @mobile {
      padding: 0;
    }
    @media @mobile-xs {
      flex-direction: column;
    }
    li {
      width:47%;
      position: relative;
      .padding(0,0,12,60);
      .font-size(18);
      .line-height(30);
      color:@text;
      font-weight: 400;
      text-align: left;
      @media @mobile-xs {
        width:100%;
      }
      &:before {
        content: '';
        position: absolute;
        left:0;
        .width(33);
        .height(36);
        background: url(../img/icon-1.png) no-repeat;
        background-size: contain;

      }
    }
  }
}
.location-page{
  .contact {
    .padding(0,0,40,0);
    @media @mobile{
      .padding(0,15,40,15);
    }
    dl {
      .margin(0,0,10,0);
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      .line-height(32);
      dt {
        color: @secondary;
        .width(33);
        .font-size(20);
      }
      dd {
        margin: 0;
        color:@text;
        .font-size(20);
        a {
          color:@text;
        }
        &.name{
          color: @primary;
          font-weight: 700;
        }
      }
    }
  }
}
.steps-page{
  
  &.steps-page-gray{
    background-color:#f7f7f7;
    .padding(100,0,50,0);
    @media @mobile{
      .padding(30,0,50,0);
    }
  }
  h2 {
    color: #44286c;
    font-family: Lato,sans-serif;
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: .72px;
    text-transform: uppercase;
    margin-bottom: 2rem;
}
  .purpose_list{
    li{ 
      display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom:2.75rem;
    position: relative;
    outline: none;
    
    @media @tablet,@mobile{
      align-items: flex-start;
    }
    &:last-child{
      &::before{
        display: none;
      }
    }
    &::before{
      content: "";
      position: absolute;
      left: 1.25rem;
      top: 0;
      height: 100%;
      background-color: @secondary;
      width: 1px;
      flex-shrink: 0;
    }  
    }
    .circle{
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      border: 0.25rem solid #ffffff;
      background-color: @secondary;
      color: @white;
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;
     margin-right: 1.5rem;
     flex-shrink: 0;
     &::before{
      content: "";
      width: 2.5625rem;
      height: 2.5625rem;
      .pos-center;
      border-radius: 50%;
      flex-shrink: 0;
      background-color: transparent;
      border: 1px solid @secondary;
     }
    }
    .text{
      color: #44286c;
        .Lato;
        .font-size(20);
        font-weight: 700;
        letter-spacing: 0.4px;
        text-decoration: none;
        .line-height(32);
      a{
        color: #44286c;
        .Lato;
        .font-size(20);
        font-weight: 700;
        letter-spacing: 0.4px;
        text-decoration: none;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        i{
          .font-size(11);
        }
      }
     .content{
       margin-top: 2rem;
       .content-list{
          list-style: none;
          margin: 0;
          .padding(0,0,0,0);
         
          @media @tablet-portrait, @mobile {
            padding: 0;
          }
          @media @mobile-xs {
            flex-direction: column;
          }
          li {
            position: relative;
            .padding(0,0,20,40);
            .font-size(20);
            .line-height(32);
            color:@primary;
            font-weight: 400;
            text-align: left;
            @media @mobile-xs {
              width:100%;
            }
            &:before {
              content: '';
              position: absolute;
              left:0;
              .width(25);
              .height(25);
              background: url(../img/icon-1.png) no-repeat;
              background-size: contain;
      
            }
            &:last-child{
              &::before{
                display: block;
              }
            }
        }
       }
     }
    }
  }
}
h5{
  .Lato;
  .font-size(20);
  color: @primary;
  font-weight: 700;
  .line-height(30);
   margin-bottom: 1.3rem;
}