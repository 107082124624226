.footer {
  background: @primary;
  color:@white;
  .font-size(15);
  .line-height(25);
  
  a {
    color:@white;
  }
  p {
    .margin(0,0,10,0);
    color: @white;
  }
  .social {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    z-index: 5;
    @media @mobile {
      justify-content: center;
      .margin(0,0,40,0);
    }
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      .width(30);
      .height(30);
      border-radius: 50%;
      color:@primary;
      background: @white;
      .margin(0,12,0,0);
      &:hover {
        text-decoration: none;
        color:@white;
        background: @secondary;
      }
      i {
        .font-size(15.62);
      }
    }
  }
  .logo {
    .margin(0,0,35,0);
    @media @mobile {
      text-align: center;
    }
    img {
      .width(192);
      height: auto;
      display: block;
      .margin(0,0,20,0);
      @media @mobile {
        margin-left: auto;
        margin-right: auto;
      }
    }
    p{
      padding-right: 5rem;
      @media @tablet,@mobile{
        padding: 0;
      }
    }
    strong {
      display: block;
      color:@white;
      .ttu;
      .font-size(13);
      .line-height(15);
      font-weight: 400;
      .letter-spacing(100);
    }
  }
  h5 {
    .ttu;
    .font-size(15);
    font-weight: 700;
    .margin(8,0,15,0);
  }
  .top-footer {
    .padding(30,0,10,0);
    position: relative;
    z-index: 0;
    overflow: hidden;
    .sub-logo-img {
      position: absolute;
      top: -3rem;
      right:16.5rem;
      .width(400);
      height: auto;
      z-index: 0;
      @media @desktop-xl{
        right: 2rem;
      }
      @media @tablet{
        right:0;
      }
      @media @mobile{
        .width(250);
        top: unset;
        right: -3rem;
        bottom: -3rem;
      }
    }
  }
  .bottom-footer {
    background: @secondary;
    .padding(10,0,10,0);
    .font-size(14);
    color:@primary;
    @media @mobile-xs {
      text-align: center;
    }
  }
  .menu {
    list-style: none;
    margin: 0;
    li {
      a {
        &:hover {
          text-decoration: none;
          color:@secondary;
        }
      }
    }
  }
  .sub-menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    list-style: none;
    margin: 0;
    @media @mobile-xs {
      justify-content: center;
      padding: 0 0 0.5rem;
    }
    li {
      .padding(0,0,0,30);
      @media @mobile-xs {
        .padding(0,15,0,15);
      }
      a {
        color:@primary;
        &:hover {
          text-decoration: none;
          color:@white;
        }
      }
    }
  }
}


.cookie-block {
  position: fixed;
  left:0;
  bottom: 0;
  width:100%;
  background: fade(@black, 90%);
  .padding(16,0,16,0);
  z-index: 999;
  .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    @media @mobile-xs {
      flex-direction: column;
    }
    .text {
      text-align: center;
      color:@white;
      .font-size(16);
      .line-height(32);
      .padding(0,40,0,0);
      @media @mobile-xs {
        .font-size(15);
        .line-height(20);
        .padding(0,0,16,0);
      }
      h6 {
        .font-size(18);
        .margin(0,0,5,0);
        font-weight: 700;
      }
      p {
        margin: 0;
        color: @white;
      }
    }
   
  }
}