.header {
	position: fixed;
	left:0;
	top:0;
	width:100%;
	z-index: 1000;
	background: @white;
	.transition(all 0.3s ease);
	display: flex;
	justify-content: space-between;
	align-items: center;
	.padding(0,50,0,50);
	.height(80);
	box-shadow:0 0 0.375rem rgba(0,0,0,0.33);
	@media @tablet {
		padding: 0 2rem;
	}
	@media @mobile {
		padding: 0 1rem 0 1.5rem;
	}
	.logo {
		.width(216);
		flex-shrink: 0;
		@media @tablet, @mobile {
			margin-left: 2rem;
		}
		@media @mobile-xs {
			margin-left: 1.5rem;
			.width(180);
		}
		img {
			max-width: 100%;
			height: auto;
		}
	}
	.header-inner {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width:100%;
		@media @tablet, @mobile {
			justify-content: flex-end;
		}
	}
	.nav-holder {
		width:100%;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 2;
		@media @tablet, @mobile {
			position: fixed;
			left:-100%;
			//left:0;
			top:0;
			height: 100%;
			margin: 0;
			width:30rem;
			.padding(80,0,0,0);
			background: @primary;
			display: flex;
			justify-content: flex-start;
			flex-direction: column;
			.transition(all 0.5s ease);
			&.show {
				left:0;
				box-shadow: 5px 0 10px rgba(0,0,0,0.3);
			}
		}
		@media @mobile {
			width:100%;
		}
		.nav-logo {
			display: none;
			position: absolute;
			top:1rem;
			right:1rem;
			width:9rem;
			@media @tablet, @mobile {
				display: block;
			}
		}
		& > ul {
			display: flex;
			justify-content: space-around;
			align-items: center;
			list-style: none;
			margin: 0;
			@media @tablet, @mobile {
				height: 100%;
				width:100%;
				overflow: auto;
				flex-direction: column;
				flex-wrap: wrap;
				justify-content: center;
				align-items: center;
				display: block;
			}
			& > li {
				.margin(0,30,0,30);
				position: relative;
				@media @tablet, @mobile {
					margin: 0;
					width:100%;
					border-top:1px solid rgba(255,255,255,0.1);
					&:last-child {
						border-bottom:1px solid rgba(255,255,255,0.1);
					}
				}
				
				&:hover {
					.dropdown {
						display: block;
					}
				}
				&.img-link{
					//.margin(0,30,0,30);
					@media @tablet, @mobile {
						margin: 0;
					}	
					.nav-img{
						display: none;
						@media @tablet,@mobile{
							
							display: block;
						}
					}
					.reg-img{
						display: block;
						@media @tablet,@mobile{
							display: none;
						}
					}
				}
				& > a {
					display: block;
					.ttu;
					.font-size(16);
					.line-height(20);
					color:@black;
					font-weight: 700;
					@media @tablet, @mobile {
						display: block;
						padding: 1.5rem 2rem;
						text-align: left;
						color:@white;
					}
					&:hover {
						text-decoration: none;
						color: @primary-dark;
					}
					i {
						.margin(0,0,0,3);
					}
					img{
						.width(95);
						height: auto;
					}
				}
				&.active {
					a {
						text-decoration: none;
						color:@secondary;
					}
				}
				.dropdown {
					position: absolute;
					.width(250);
					left:-2rem;
					.top(18);
					.padding(32,0,0,0);
					display: none;
					
					@media @tablet, @mobile {
						display: block;
						position: static;
						padding: 0;
						width:auto;
					}
					& > ul {
						list-style: none;
						margin: 0;
						background: @secondary url(../img/shadow.png) repeat-x;
						background-size: auto 0.375rem;
						.padding(0,0,0,0);
						border-radius: 0 0 0.313rem 0.375rem;
						box-shadow:0 3px 3px rgba(0,0,0,0.1);
						@media @tablet, @mobile {
							background: none;
							border-radius: 0;
							padding: 0 0 0 2rem;
							border-top:1px solid rgba(255,255,255,0.1);
						}
						& > li {
							margin: 0;
							.padding(14,16,10,32);
							@media @tablet, @mobile {
								margin: 0;
								width:100%;
								border-bottom:1px solid rgba(255,255,255,0.1);
								&:last-child {
									border-bottom:0;
								}
							}
							&:last-child{
								border-radius: 0 0 0.313rem 0.375rem;
							}
							&:hover{
								background-color: @primary;
								cursor: pointer;
								& > a {
									color: @white;
								}
							}
							&.active {
								background-color: @primary;
								& > a {
									color: @white;
								}
							}
							& > a {
								color: @white;
								.inline-b;
								.ttu;
								.font-size(16);
								.line-height(20);
								.padding(5,0,5,0);
								font-weight: 700;
								&:hover {
									text-decoration: none;
								}
								@media @tablet, @mobile {
									display: block;
									padding: 1.5rem 1rem;
									text-align: left;
									color:@white;
								}
							}
							& > ul {
								list-style: none;
								margin: 0;
								.padding(0,0,0,16);
								@media @tablet, @mobile {
									padding: 0 0 0 2rem;
									border-top:1px solid rgba(255,255,255,0.1);
								}
								& > li {
									margin: 0;
									@media @tablet, @mobile {
										margin: 0;
										width:100%;
										border-top:1px solid rgba(255,255,255,0.1);
										&:first-child {
											border-top:0;
										}
									}
									&.active {
										& > a {
											color: @secondary;
										}
									}
									& > a {
										color:@text;
										.inline-b;
										.font-size(16);
										.line-height(20);
										.padding(5,0,5,0);
										&:hover {
											text-decoration: none;
											color: @secondary;
										}
										@media @tablet, @mobile {
											display: block;
											padding: 1.5rem 1rem;
											text-align: left;
											color:@white;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.sep{
		width: 1px;
		.height(36);
		background-color: #ececec;
		.margin(0,23,0,23);
		@media @mobile-xs{
			display: none;
		}
	}
	.phone {
		.font-size(16);
		color:@black;
		white-space: nowrap;
		&:hover {
			text-decoration: none;
			color:@secondary;
		}
		@media @mobile-xs {
			border-radius: 50%;
			background: @secondary;
			color:@white;
			width:2.5rem;
			height: 2.5rem;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		i {
			color:@secondary;
			.font-size(16);
			.margin(0,10,0,0);
			@media @mobile-xs {
				margin: 0;
				color:@white;
			}
		}
		span {
			font-weight: 700;
			@media @mobile-xs {
				display: none;
			}
		}
	}
	.buttons {
		position: relative;
		display: flex;
		align-items: center;
		.margin(0,0,0,19);
		@media @mobile-xs {
			margin: 0;
			padding: 1rem;
			justify-content: space-between;
			width:100%;
		}
		.btn {
			.margin(0,0,0,15);
			.line-height(42);
			@media @mobile-xs {
				width:48%;
				margin: 0;
			}
		}
	}
}

/*hamburger btn*/
.btn-lines {
	position: absolute;
	display: block;
	left: 0;
	width: 100%;
	.height(2);
	background-color: @primary;
	content: "";
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
}
.c-hamburger {
	display: none;
	//overflow: hidden;
	position: relative;
	z-index: 10;
	//right:20px;
	//top:12px;
	margin: 0;
	padding: 0;
	.width(26);
	.height(20);
	flex-shrink: 0;
	font-size: 0;
	text-indent: -9999px;
	appearance: none;
	border: none;
	cursor: pointer;
	-webkit-transition: all 0.3s ease 0.3s;
	transition: all 0.3s ease  0.3s;
	&:focus {
		outline: none;
	}
	@media @tablet, @mobile {
		display: block;
	}
	span {
		display: block;
		position: absolute;
		.top(9);
		left: 0;
		right: 0;
		.height(2);
		background: @primary;
		-webkit-transition: all 0.3s ease;
		transition: all 0.3s ease;
	}
	span::before {
		&:extend(.btn-lines);
		.top(-7);
	}
	span::after {
		&:extend(.btn-lines);
		.bottom(-7);
	}
}
.c-hamburger--htx {
	background-color: transparent;
	&.active {
		&:before {
			display: none;
		}
		span {
			background: none;
		}
		span::before {
			top: 0;
			transform:rotate(45deg);
			-webkit-transform:rotate(45deg);
			background: @white;
		}
		span::after {
			bottom: 0;
			transform:rotate(-45deg);
			-webkit-transform:rotate(-45deg);
			background: @white;
		}
	}
}
/*hamburger btn*/
