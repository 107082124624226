.row:before, .row:after {display: none !important;}
* {
	margin:0;
	padding: 0;
}
html,
body {
	color: @text;
	min-height: 100vh;
	background: @white;
	.Lato;
	line-height: 1.1;
	@media (min-width:1441px) {
		font-size: 16px;
	}
	@media (max-width:1440px) {
		font-size: 14px;
	}
	@media (max-width:1280px) {
		font-size: 12px;
	}
	@media (max-width:1024px) {
		font-size: 13px;
	}
	
}
img {
	vertical-align: top;
}
a {
	color: @primary;
	text-decoration: none;
	&:hover {
		color: @primary;
		text-decoration: underline;
	}
}
.overlay {
	padding-top: 0;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
	z-index: 10;
	display:none;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 100;
	}
	&.show {
		display: block;
	}
}
.no-scroll {
	@media @mobile {
		position: fixed;
		width:100%;
		height: 100%;
		overflow: hidden;
	}
}
.container {
	max-width: 75rem;
	margin: 0 auto;
	padding: 0;
	@media @tablet-portrait,@mobile{
		max-width: 90%;
	}
}
.container-sm{
	max-width: 63.125rem;
	margin: 0 auto;
	@media @tablet-portrait,@mobile{
		max-width: 90%;
	}
}
.container-xs{
	max-width: 59rem;
	margin: 0 auto;
	@media @tablet-portrait,@mobile{
		max-width: 90%;
	}	
}
.container-big{
	max-width: 91rem;
	margin: 0 auto;
	@media @desktop,@tablet,@mobile{
		max-width: 90%;
	}
}
.btn {
	.inline-b;
	.line-height(48);
	.ttu;
	background: @primary;
	color:@white;
	.font-size(16);
	.Lato;
	font-weight: 700;
	border: 1px solid @primary;
	cursor: pointer;
	border-radius: 3px;
	.padding(0,30,0,30);
	.transition(all 0.1s ease);
	outline: 0;
	white-space: nowrap;
	&:hover {
		border-color:@primary;
		background: fade(@primary, 90%);
		color:@white;
		text-decoration: none;
	}
	&:focus {
		box-shadow:none;
		color:@white;
	}
	&.btn-secondary {
		background: @secondary;
		color:@white;
		border-color:@secondary;
		&:hover {
			background: fade(@secondary, 90%);
		}
		&:focus {
			background: fade(@secondary, 90%);
		}
	}
	&.btn-white {
		background: @white;
		color:@primary;
		border-color:@white;
		&:hover {
			background: fade(@white, 90%);
		}
		&:focus,
		&:active {
			background: fade(@white, 90%);
		}
	}
	&.btn-white {
		background: @white;
		color:@primary;
		border-color:@white;
		&:hover {
			background: fade(@white, 90%);
		}
		&:focus,
		&:active {
			background: fade(@white, 90%);
		}
	}
	&.btn-transparent {
		background: transparent;
		color:@text;
		border-color:transparent;
		.padding(0,0,0,10);
		.line-height(16);
		&:hover {
			color:@primary;
		}
		&:focus,
		&:active {
			color:@primary;
		}
	}
	&.btn-outline-primary {
		background: none;
		color:@primary;
		border-color:@primary;
		&:hover {
			background: fade(@primary, 10%);
		}
		&:focus,
		&:active{
			background: fade(@primary, 10%);
		}
	}
	&.btn-outline-secondary {
		background: none;
		color:@secondary;
		border-color:@secondary;
		&:hover {
			background: fade(@secondary, 10%);
		}
		&:focus,
		&:active {
			background: fade(@secondary, 10%);
		}
	}
	&.screen-images-btn{
		background: none;
		color:@secondary;
		border:none;
		text-decoration: underline;
		&:hover {
			background: fade(@secondary, 2%);
		}
		&:focus,
		&:active {
			background: fade(@secondary, 2%);
		}
	}
	&.btn-full {
		width:100%;
	}
}

/* section-bottom-line start */
.section-bottom-line {
	background: @secondary url(../img/section-bottom-line.png) no-repeat 100% 50%;
	background-size: auto 100%;
	color:@white;
	.section-text {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.text-center;
		.height(172);
		@media @mobile-xs {
			.height(160);
		}
		@media only screen and (max-width: 375.98px) {
			flex-direction: column;
			padding-top: 2rem;
			padding-bottom: 2rem;
			height: auto;

		}
		&.section-text-center{
			justify-content: center;
		}
		.text {
			color:@white;
			font-weight: 700;
			.font-size(36);
			.margin(0,45,0,0);
			@media @mobile-xs {
				.font-size(30);
			}
			@media only screen and (max-width: 375.98px) {
				
				margin-bottom: 2rem;
			}
		}
		.btn-wrap{
			display: flex;
			justify-content: flex-end;
			align-items:center;
			@media @mobile-xs{
				flex-wrap: wrap;
			}
			@media only screen and (max-width: 375.98px) {
				width: 100%;
				justify-content: space-between;
				margin-top: 1rem;
			}
			.btn{
				min-width: 15.625rem;
				margin-left: 2.5rem;
				&:first-child{
					margin-left: 0;
				}
				@media @tablet,@mobile{
					min-width:auto;
				}
				@media only screen and (max-width: 600px){
					min-width:auto;
					margin-bottom: 1rem;
					margin-left: 0;
					font-size: 0.75rem;
					.line-height(40);
				}
			}
		}
	}
}
/* section-bottom-line start */

/* page-title start */
.page-title {
	.height(480);
	overflow: hidden;
	position: relative;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0% 100%;
	@media @tablet-portrait, @mobile {
		height: 23rem;
	}
	@media @mobile-xs {
		height: 17rem;
	}
	&:before {
		content: '';
		.full;
		background: fade(@primary, 40%);
	}
	&.bg-center{
		background-position: 100% 50%;
		background-repeat: no-repeat;
		background-size: cover;
	}
	.text {
		.pos-center;
		width:100%;
		.text-center;
		color:@white;
		.padding(80,0,0,0);
		.font-size(25);
		.line-height(35);
		z-index: 5;
		
		@media @mobile-xs {
			.font-size(18);
			.line-height(25);
		}
		.header-text-wrap{
			display: flex;
			justify-content: flex-start;
			@media @tablet,@mobile{
				justify-content: center;
			}
		  }
		h1 {
			.ttu;
			font-weight: 700;
			.font-size(60);
			.line-height(64);
			.letter-spacing(20);
			.margin(0,0,20,0);
			@media @mobile-xs {
				.font-size(40);
				.line-height(44);
			}
		}
		p {
			margin: 0;
		}
	}
}
/* page-title end */

/* form-holder start */

.form-holder {
	.form-group {
		.margin(0,0,40,0);
		position: relative;
	}
	.form-control,
	.custom-select {
		background: none;
		border:0;
		border-bottom: 1px solid #d1d1d1;
		border-radius: 0;
		padding: 0;
		.height(30);
		color: @text;
		.font-size(16);
		.placeholder(#949494);
		&:focus {
			outline: none;
			box-shadow:none;
			border-bottom-color: @primary;
		}
	}
	textarea.form-control {
		.height(110);
		resize: none;
	}
	.custom-select {
		background: #ffffff url(../img/arrow-down.png) no-repeat right center;
		background-size: 0.750rem 0.5rem;
	}
	.form-check {
		padding: 0;
		.form-check-input-checkbox {
			.width(20);
			.height(20);
			position: absolute;
			z-index: 3;
			opacity: 0;
			left:0;
			top:0;
			.inline-b;
			& + .form-check-label {
				.inline-b;
				.padding(0,0,0,38);
				position: relative;
				.font-size(16);
				.line-height(20);
				min-height: 1rem;
				color:@text;
				white-space: nowrap;
				&:before {
					content: '';
					position: absolute;
					left:0;
					top:0;
					.width(20);
					.height(20);
					border:0.063rem solid #d1d1d1;
					border-radius: 0.188rem;
				}
				&:after {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					.width(20);
					.height(20);
					background: url(../img/check.png) no-repeat;
					background-size: contain;
					opacity: 0;
				}
			}
			&:checked {
				& + .form-check-label {
					&:before {
						background: @secondary;
						border-color: @secondary;
					}
					&:after {
						opacity: 1;
					}
				}
			}
			&:focus {
				& + .form-check-label {
					&:before {
						border-color:@secondary;
					}
				}
			}
		}
		.form-check-input-radio {
			.width(24);
			.height(24);
			position: absolute;
			z-index: 3;
			opacity: 0;
			left:0;
			top:0;
			.inline-b;
			& + .form-check-label {
				.inline-b;
				.padding(0,0,0,43);
				position: relative;
				.font-size(15);
				.line-height(24);
				min-height: 1rem;
				color:@text;
				white-space: nowrap;
				&:before {
					content: '';
					position: absolute;
					left:0;
					top:0;
					border-radius: 50%;
					background: @white;
					.width(24);
					.height(24);
					border:0.063rem solid #dfe2e5;
				}
				&:after {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					.width(24);
					.height(24);
					opacity: 0;
					background: url(../img/check-dark.png) no-repeat;
					background-size: contain;
				}
			}
			&:checked {
				& + .form-check-label {
					&:before {
						background: #c4d3e4;
						border-color:#c4d3e4;
					}
					&:after {
						opacity: 1;
					}
				}
			}
			&:focus {
				& + .form-check-label {
					&:before {
						border-color:#c4d3e4;
					}
				}
			}
		}
	}
}
/* form-holder end */


.section-page {
	background: url(../img/top-decoration.png) no-repeat 50% 0;
	background-size: 100% auto;
	
	.section-inner-page {
		// background: url(../img/decor-3.png) no-repeat 50% 100%;
		background-size: 100% auto;
		.padding(130,0,105,0);
		position: relative;
		.font-size(17);
		.line-height(34);
		color: @text;
		@media @mobile{
			.padding(56,0,55,0);
		}
	}
	h2 {
		color: @primary;
		font-weight: 700;
		.font-size(36);
		.line-height(46);
		.margin(0, 0, 15, 0);
	}
	p {
		.margin(0, 0, 32, 0);
	}
}

/* loader start */
.loader {
	position: fixed;
	left:0;
	top:0;
	width:100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;
	background: rgba(255,255,255,0.3);
}
.ball-grid-pulse {
	.width(57);
}
.ball-grid-pulse > div:nth-child(1) {
	-webkit-animation-delay: 0.22s;
	animation-delay: 0.22s;
	-webkit-animation-duration: 0.9s;
	animation-duration: 0.9s; }
.ball-grid-pulse > div:nth-child(2) {
	-webkit-animation-delay: 0.64s;
	animation-delay: 0.64s;
	-webkit-animation-duration: 1s;
	animation-duration: 1s; }
.ball-grid-pulse > div:nth-child(3) {
	-webkit-animation-delay: -0.15s;
	animation-delay: -0.15s;
	-webkit-animation-duration: 0.63s;
	animation-duration: 0.63s; }
.ball-grid-pulse > div:nth-child(4) {
	-webkit-animation-delay: -0.03s;
	animation-delay: -0.03s;
	-webkit-animation-duration: 1.24s;
	animation-duration: 1.24s; }
.ball-grid-pulse > div:nth-child(5) {
	-webkit-animation-delay: 0.08s;
	animation-delay: 0.08s;
	-webkit-animation-duration: 1.37s;
	animation-duration: 1.37s; }
.ball-grid-pulse > div:nth-child(6) {
	-webkit-animation-delay: 0.43s;
	animation-delay: 0.43s;
	-webkit-animation-duration: 1.55s;
	animation-duration: 1.55s; }
.ball-grid-pulse > div:nth-child(7) {
	-webkit-animation-delay: 0.05s;
	animation-delay: 0.05s;
	-webkit-animation-duration: 0.7s;
	animation-duration: 0.7s; }
.ball-grid-pulse > div:nth-child(8) {
	-webkit-animation-delay: 0.05s;
	animation-delay: 0.05s;
	-webkit-animation-duration: 0.97s;
	animation-duration: 0.97s; }
.ball-grid-pulse > div:nth-child(9) {
	-webkit-animation-delay: 0.3s;
	animation-delay: 0.3s;
	-webkit-animation-duration: 0.63s;
	animation-duration: 0.63s; }
.ball-grid-pulse > div {
	background-color: @primary;
	.width(15);
	.height(15);
	border-radius: 100%;
	.margin(2,2,2,2);
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	display: inline-block;
	float: left;
	-webkit-animation-name: ball-grid-pulse;
	animation-name: ball-grid-pulse;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-animation-delay: 0;
	animation-delay: 0; }

/* loader end */



@media @tablet-portrait, @mobile {
	.wow {
		visibility: visible !important;
		-webkit-animation: none !important;
		-moz-animation: none !important;
		-o-animation: none !important;
		-ms-animation: none !important;
		animation: none !important;
	}
}



//form 
.form-group {
	margin-bottom: 2.5rem;
	min-height: 2.5rem;
	position: relative;
	.form-control,
	.custom-select {
		height: 2.5rem;
		background: @white;
		border: 0;
		border-bottom:2px solid #e6e6e6;
		border-radius: 0;
		padding: 0  1rem 0 0;
		color:@text;
		.font-size(16);
		transition:  all .3s linear; 
		//margin-bottom: 2.5rem;
		-webkit-appearance:  none; 
		//position: relative;
		@media @mobile{
			.font-size(16);
		}
		&:focus{
			border: none;
			border-bottom:2px solid @primary;
			box-shadow: none;
			color: #252b33;
		}
		&:active{
			border: none;
			border-bottom:2px solid @primary;
			box-shadow: none;
			color: #252b33;
		}
		&::placeholder{
			.font-size(16);
			color: #949494;
			.Lato;
			transition: 0.3s ease linear;
			
		}	
		&.form-control-s{
			.font-size(16);
		}
		
	}
	.view-pass {
		position: absolute;
		bottom: 0.875rem;
		right: 0.8rem;
		color: #5c5c5c;
		.line-height(16);
		&:hover{
		  cursor: pointer;
		  color: @primary;
		}
		i{
		  font-size: 1rem;
		}
		.fa-eye-slash{
		  display: block;
		}
		.fa-eye{
		  display: none;
		}
		&.active{
		  .fa-eye-slash{
			display: none;
		  }
		  .fa-eye{
			display: block;
		  }
		}
	  }
	.custom-select {
		background: @white url(../img/svg/chevron-down-solid.svg) no-repeat right 0.75rem center;
		background-size: 0.750rem 0.5rem;
		@media @desktop{
			background-position: right 0.5rem center;
			padding-right: 0.5rem;
		}
	}
	textarea.form-control{
		padding-top: 1rem;
	  resize: none;
	  .height(185);
	  position: relative;	  
	  padding-bottom: 1.5rem;
	}	
	
	
	.floating-label{
		position:  absolute;
		left:  0; 
		opacity:  0; 
		transition:  all .3s ease; 
		top: -0.5rem;
		left: 0;
		color: #949494;
		.Lato;
		.font-size(12);
	  }
	  input:not(:placeholder-shown) + .floating-label {
		transform:  translateY(-10px); 
		opacity:  1; 
	  }
	  textarea:not(:placeholder-shown) + .floating-label{
		transform:  translateY(-10px); 
		opacity:  1; 
	  }
	   
}

.form-check {
	padding: 0;
	.inline-b;
	margin-right: 1rem;
	position: relative;
	@media @tablet, @mobile {
	  display: block;
	 
	}
	.form-check-input-checkbox {
	  width:1.375rem;
	  height: 1.375rem;
	  margin: 0 -1.5rem 0 0;
	  position: absolute;
	  left: 0;
	  top:0;
	  z-index: 3;
	  opacity: 0;
	  & + .form-check-label {
		.inline-b;
		.padding(0,0,0,48);
		position: relative;
		.font-size(16);
		.line-height(22);
		min-height: 1rem;
		color:#000;
		.Lato;  
		&:before {
		  content: '';
		  position: absolute;
		  left:0;
		  top:0;
		  width:1.375rem;
		  height:1.375rem;
		  border: 2px solid #e6e6e6;
		  background: #fff;
		  border-radius: 0;
		}      
		&:after {
		  content: '\f00c';
		  position: absolute;
		  top: 0;
		  left: 0;
		  width: 1.375rem;
		  height: 1.375rem;
		  line-height: 1.375rem;
		  text-align: center;
		  font-family: 'Font Awesome 5 Free';
		  font-weight: 900;
		  opacity: 0;
		  color: #ffffff;
		}
		   
	  }
	  &:checked {
		& + .form-check-label {
		  &:before {
			background: @secondary;
			border: 2px solid @secondary;
		  }
		  &::after{
			opacity: 1;
		  }
		}
	  }
	}
	.form-check-input-radio {
		width:1.375rem;
		height: 1.375rem;
		margin: 0 -1rem 0 0;
		position: absolute;
		z-index: 3;
		opacity: 0;
		.inline-b;
		& + .form-check-label {
			.inline-b;
			padding: 0 0 0 2rem;
			position: relative;
			.font-size(16);
			color: #252b33;
			font-family: "Open Sans";
		//	.line-height(20);
			min-height: 1rem;
			white-space: nowrap;
			&:before {
				content: '';
				position: absolute;
				left:0;
				top:0;
				width:1.375rem;
				height: 1.375rem;
				border-radius: 50%;
				border: 1px solid #d0d0d0;
				background-color: @white;
			}
			&:after {
				content: '\f00c';
				position: absolute;
				top: 0;
				left: 0;
				width: 1.375rem;
				height: 1.375rem;
				line-height: 1.375rem;
				text-align: center;
				font-family: 'Font Awesome 5 Free';
				font-weight: 900;
				opacity: 0;
				.font-size(14);
				color: #ffffff;
			}
		}		
		&:checked {
			& + .form-check-label {
				&:before {
					background: @primary;
					border-color:  @primary;
				}
				&:after {
					opacity: 1;
				}
			}
		}		
		
	}
  }

.lbl{
	color: #191919;
    .Lato;
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: .2rem;
	line-height: 1.5rem;
	&:focus{
		outline: none;
	}
}
h2{
    .Lato;
    .font-size(36);
	.line-height(50);
	font-weight: 700;
	color:@primary;
	margin-bottom: 2.5rem;
	&:focus{
		outline: none;
	}
  }
  p{
	  .font-size(18);
	  .Lato;
	  .line-height(30);
	  color: @text;
	  &:focus{
		outline: none;
	}
  }

  //animation
@keyframes pulse-round {
    0% {
        transform: scale(1);
    }

    25% {
        transform: scale(1.1);
    }

    50% {
        transform: scale(1);
    }

    90% {
        transform: scale(1.075);
    }

    100% {
        transform: scale(1);
    }
}
.pulse-round{
	animation: pulse-round 4.5s infinite ease-in-out;
}


@keyframes circle-rotate {
	0% {
	  transform: rotate(0deg);
	}
	50% {
	  transform: rotate(180deg);
	}
	100% {
	  transform: rotate(360deg);
	}
  }
 h2{
	&.logo{
		text-align: center;
		margin-bottom: 2rem;
		margin-top: -2rem;
		@media @tablet-portrait,@mobile{
			margin-top: 0;
		}
		img{
		 text-align: center;
		 .height(64);
		 width: auto;
	 }
	}
 }

 @keyframes pulse-icon {
	0%,
	100% {
	 transform: rotate(-10deg);
  //    transform: scale(1);
	}
	50% {
	  transform: scale(1.1);
	}
  }
  @keyframes pulse-icon-enlarge {
	0%,
	100% {
  		transform: scale(1);
	}
	50% {
	  transform: scale(1.15);
	}
  }

  a,p,div,h1,h2,h3,h4,h5{
	&:focus{
		outline: none;
	}
  }
  .btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show>.btn-outline-primary.dropdown-toggle {
    color: @primary;
    background-color: transparent;
    border-color: @primary;
}
.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle {
    color: @white;
    background-color: @secondary;
    border-color: @secondary;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary:not(:disabled):not(.disabled):active, .show>.btn-outline-secondary.dropdown-toggle {
    color: @secondary;
    background-color: transparent;
    border-color: @secondary;
}